import React, { useState, useEffect, useCallback } from "react";
import MissingFieldsBox from "../UniversalComponents/SubmitMessages/cantsubmitmessage";
import ErrorMessage from "../UniversalComponents/SubmitMessages/ErrorMessage";
import { useAuth0 } from "@auth0/auth0-react";
import PropTypes from "prop-types";
import HoldErrorMessage from "../UniversalComponents/SubmitMessages/HoldErrorMessage";
import RepErrorMessage from "../UniversalComponents/SubmitMessages/RepErrorMessage";
import SuccessMessage from "../UniversalComponents/SubmitMessages/SuccessMessage";
import PatioLockMessage from "../UniversalComponents/SubmitMessages/PatioCoverLock";
import SimpleBar from "simplebar-react";
import "simplebar/dist/simplebar.min.css";

const SubmitButton = ({
  selectedTrimOption,
  selectedBiHexOption,
  selectedExteriorOption,
  selectedPostStyleOption,
  selectedAttachedOption,
  selectedPermitOption,
  selectedFoundationOption,
  selectedKneeWallOption,
  selectedMountOption,
  personalInfo,
  selectedExistingFoundationOption,
  selectedNewFoundationOption,
  gradeHeightFeet,
  gradeHeightInches,
  stepDownInches,
  stepDownFeet,
  selectedWindLoadChange,
  selectedRoofLoadChange,
  widthFeet,
  widthInches,
  lengthFeet,
  lengthInches,
  selectedPostHeight,
  selectedKneeWallFeet,
  selectedKneeWallInches,
  selectedFasciaInches,
  onRedBorderCheck,
  selectedShingle,
  selectedFanBeam,
  selectedRoofDensity,
  pavstyle,
  heightAtHouse,
  frontHeight,
  roofstyle,
  selectedPavilionLayoutOption,
  selectedSkyviewChoice,
  selectedLEDLightChoice,
  selectedCleatsOrBond,
  selectedValueSeries,
  selectedRailSideOption,
  selectedRailAmountOption,
  selectedRoofPanelColorOption,
  selectedStructuralBeamOption,
  isValidEmail,
  selectedLocation,
  locations,
}) => {
  const [formattedMissingFields, setFormattedMissingFields] = useState([""]);
  const [missingFields, setMissingFields] = useState([""]);
  const [showMissingFields, setShowMissingFields] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [endMessage, setEndMessage] = useState("");
  const [showEndMessage, setShowEndMessage] = useState(false);
  const [additionalInfo, setAdditionalInfo] = useState([""]);
  const [sentCorrectly, setSentCorrectly] = useState(false);
  const [submitIsLoading, setSubmitIsLoading] = useState(false);

  const { getAccessTokenSilently } = useAuth0();

  const handleAdditionalInfoChange = (index, value) => {
    const newAdditionalInfo = [...additionalInfo];
    newAdditionalInfo[index] =
      value.length > 48 ? value.substring(0, 48) : value;
    setAdditionalInfo(newAdditionalInfo);
  };

  const openModal = (e) => {
    e.preventDefault();
    setShowMissingFields(true);
    onRedBorderCheck(missingFields);
    if (formattedMissingFields.length === 0) {
      setShowModal(true);
    }
  };

  const closeModal = (e) => {
    setShowModal(false);
  };

  const checkMissingFields = useCallback(() => {
    const newFormattedMissingFields = [];
    const missingFields = [];

    if (
      selectedRoofDensity !== '4 1/4" 0.032 1 pound' &&
      selectedRoofDensity !== '4 1/4" 0.032 2 pound' &&
      selectedSkyviewChoice !== "Skyview YES"
    ) {
      if (!selectedRoofPanelColorOption) {
        newFormattedMissingFields.push("Roof Panel Color");
        missingFields.push("RoofPanelColors");
      }
    }

    if (roofstyle !== "CATHEDRAL") {
      if (!selectedSkyviewChoice) {
        newFormattedMissingFields.push("Skyview");
        missingFields.push("Skyview YES or NO");
      }
    }
    if (selectedSkyviewChoice === "Skyview YES") {
      if (!selectedLEDLightChoice) {
        newFormattedMissingFields.push("L.E.D. Lights");
        missingFields.push("LED YES or NO");
      }
    }
    if (selectedSkyviewChoice !== "Skyview YES") {
      if (!selectedShingle) {
        newFormattedMissingFields.push("Shingle Roof");
        missingFields.push("Shingle Roof");
      }
      if (selectedShingle !== "YES") {
        if (!selectedCleatsOrBond) {
          newFormattedMissingFields.push("Cleats or Bond Tape");
          missingFields.push("Cleats or Bond Tape");
        }
      }
    }

    if (!selectedTrimOption) {
      newFormattedMissingFields.push("Trim Color");
      missingFields.push("Trim Color");
    }
    if (!selectedBiHexOption) {
      newFormattedMissingFields.push("Bi-Hex Tool");
      missingFields.push("Bi-Hex tool");
    }
    if (!selectedAttachedOption) {
      newFormattedMissingFields.push("Free-Standing or Attached");
      missingFields.push("Free-Standing or Attached");
    }
    if (selectedAttachedOption === "attached") {
      if (!selectedPavilionLayoutOption) {
        newFormattedMissingFields.push("Structure Layout");
        missingFields.push("StructureLayoutImages");
      }
      if (selectedPavilionLayoutOption === "2") {
        if (!selectedRailSideOption) {
          newFormattedMissingFields.push("Rail Side");
          missingFields.push("rail side");
          missingFields.push("StructureLayoutImages");
        }
        if (!selectedRailAmountOption.feet) {
          newFormattedMissingFields.push("Rail Amount Feet");
          missingFields.push("rail amount Feet");
          missingFields.push("StructureLayoutImages");
        }
        if (!selectedRailAmountOption.inches) {
          newFormattedMissingFields.push("Rail Amount Inches");
          missingFields.push("rail amount Inches");
          missingFields.push("StructureLayoutImages");
        }
      }
      if (selectedPavilionLayoutOption === "3") {
        if (!selectedRailAmountOption.feet) {
          newFormattedMissingFields.push("Rail Amount Feet");
          missingFields.push("rail amount Feet");
          missingFields.push("StructureLayoutImages");
        }
        if (!selectedRailAmountOption.inches) {
          newFormattedMissingFields.push("Rail Amount Inches");
          missingFields.push("rail amount Inches");
          missingFields.push("StructureLayoutImages");
        }
      }
    }
    if (!pavstyle) {
      newFormattedMissingFields.push("Pavilion Style");
      missingFields.push("Pavilion Style");
    }
    if (pavstyle === "TEMO Panel") {
      if (
        selectedTrimOption === "SANDSTONE" ||
        selectedTrimOption === "WHITE"
      ) {
        if (!selectedValueSeries) {
          newFormattedMissingFields.push("Value Series");
          missingFields.push("Value Series");
        }
      }
      if (!selectedPostStyleOption) {
        newFormattedMissingFields.push("Post Style");
        missingFields.push("Post Style");
      }
      if (
        selectedPostStyleOption === "ROMA" ||
        selectedPostStyleOption === "FLORENTINE"
      ) {
        if (!selectedPostHeight) {
          newFormattedMissingFields.push("Post Height");
          missingFields.push("post height");
        }
      }
      if (!selectedExteriorOption) {
        newFormattedMissingFields.push("Exterior Style");
        missingFields.push("Exterior Style");
      }
      if (selectedPostStyleOption !== "NO COLUMNS OR BEAMS NEEDED") {
        if (!selectedStructuralBeamOption) {
          newFormattedMissingFields.push("Structural Beam");
          missingFields.push("Structural Beam");
        }
      }
    }
    if (pavstyle === "TEMO Panel" && selectedSkyviewChoice !== "Skyview YES") {
      if (!roofstyle) {
        newFormattedMissingFields.push("Roof Style");
        missingFields.push("Roof Style");
      }
    }
    if (!lengthFeet || lengthFeet < 4) {
      newFormattedMissingFields.push("Length Feet");
      missingFields.push("length feet");
    }
    if (!widthFeet || widthFeet < 4) {
      newFormattedMissingFields.push("Width Feet");
      missingFields.push("width feet");
    }
    if (!lengthInches) {
      newFormattedMissingFields.push("Length Inches");
      missingFields.push("length inches");
    }
    if (!widthInches) {
      newFormattedMissingFields.push("Width Inches");
      missingFields.push("width inches");
    }

    if (!selectedWindLoadChange) {
      newFormattedMissingFields.push("Wind Load");
      missingFields.push("WindLoad");
    }

    if (!selectedRoofLoadChange) {
      newFormattedMissingFields.push("Roof Load");
      missingFields.push("RoofLoad");
    }

    if (!heightAtHouse.heightfeet) {
      newFormattedMissingFields.push("Height at House Feet");
      missingFields.push("Height at House Heightfeet");
    }

    if (!heightAtHouse.heightinches) {
      newFormattedMissingFields.push("Height at House Inches");
      missingFields.push("Height at House Heightinches");
    }

    if (!frontHeight.frontfeet) {
      newFormattedMissingFields.push("Front Height Feet");
      missingFields.push("Front Height Frontfeet");
    }

    if (!frontHeight.frontinches) {
      newFormattedMissingFields.push("Front Height Inches");
      missingFields.push("Front Height Frontinches");
    }
    for (const key in personalInfo) {
      if (key === "firstName" && personalInfo[key] === "") {
        continue;
      }
      if (key === "purchaseOrder" && personalInfo[key] === "") {
        continue;
      }
      if (!personalInfo[key]) {
        missingFields.push(key);
        if (key === "lastName") {
          newFormattedMissingFields.push("Last Name");
        }
        if (key === "sender") {
          newFormattedMissingFields.push("Sender");
        }
        if (key === "email") {
          newFormattedMissingFields.push("Email");
        }
        if (key === "address") {
          newFormattedMissingFields.push("Address");
        }
        if (key === "city") {
          newFormattedMissingFields.push("City");
        }
        if (key === "stateProvince") {
          newFormattedMissingFields.push("State/Province");
        }
        if (key === "zip") {
          newFormattedMissingFields.push("Zip Code");
        }
      }
    }
    if (personalInfo["email"] && !isValidEmail) {
      newFormattedMissingFields.push("Invalid Email");
      missingFields.push("email");
    }
    if (locations && Object.keys(locations).length > 0) {
      if (!selectedLocation) {
        newFormattedMissingFields.push("Location");
        missingFields.push("location");
      }
    }
    if (!selectedFanBeam) {
      newFormattedMissingFields.push("Fan Beam Option");
      missingFields.push("fan beam");
    }
    if (!selectedRoofDensity) {
      newFormattedMissingFields.push("Roof Density");
      missingFields.push("roof density");
    }
    if (!selectedPermitOption) {
      newFormattedMissingFields.push("Drawing Package");
      missingFields.push("permit package option");
    }
    if (selectedPermitOption === "YES") {
      if (!selectedFoundationOption) {
        newFormattedMissingFields.push("Foundation Option");
        missingFields.push("foundation option");
      }
      if (selectedFoundationOption === "newconcrete") {
        if (!selectedNewFoundationOption) {
          newFormattedMissingFields.push("New Concrete Option");
          missingFields.push("Pick a new concrete option");
        }
      }
      if (selectedFoundationOption === "existingconcrete") {
        if (!selectedExistingFoundationOption) {
          newFormattedMissingFields.push("Existing Concrete Option");
          missingFields.push("Pick an existing slab option");
        }
      }

      if (
        selectedFoundationOption === "existingwood" ||
        selectedFoundationOption === "nontemowood"
      ) {
        if (!gradeHeightInches) {
          newFormattedMissingFields.push("Grade Height Inches");
          missingFields.push("Please input inches grade height");
        }
        if (!gradeHeightFeet) {
          newFormattedMissingFields.push("Grade Height Feet");
          missingFields.push("Please input feet grade height");
        }
      }
      if (!selectedKneeWallOption) {
        newFormattedMissingFields.push("Knee Wall Option");
        missingFields.push("knee wall option");
      } else if (selectedKneeWallOption === "yes") {
        if (!selectedKneeWallFeet) {
          newFormattedMissingFields.push("Knee Wall Feet");
          missingFields.push("Select Knee Wall Feet");
        }
        if (!selectedKneeWallInches) {
          newFormattedMissingFields.push("Knee Wall Inches");
          missingFields.push("Select Knee Wall Inches");
        }
      }
    }
    if (
      selectedPermitOption === "YES" &&
      selectedAttachedOption === "attached"
    ) {
      if (!selectedMountOption) {
        newFormattedMissingFields.push("Mount Option");
        missingFields.push("pick a mount");
      } else if (selectedMountOption === "A") {
        if (!selectedFasciaInches) {
          newFormattedMissingFields.push("Soffit Depth");
          missingFields.push("fascia inches");
        }
      }
      if (!stepDownFeet) {
        newFormattedMissingFields.push("Step Down Feet");
        missingFields.push("stepdown feet");
      }

      if (!stepDownInches) {
        newFormattedMissingFields.push("Step Down Inches");
        missingFields.push("stepdown inches");
      }
    }

    setMissingFields(missingFields);
    setFormattedMissingFields(newFormattedMissingFields);
  }, [
    selectedTrimOption,
    selectedBiHexOption,
    selectedExteriorOption,
    selectedPostStyleOption,
    selectedAttachedOption,
    selectedPermitOption,
    selectedFoundationOption,
    selectedKneeWallOption,
    selectedMountOption,
    personalInfo,
    selectedExistingFoundationOption,
    selectedNewFoundationOption,
    gradeHeightFeet,
    gradeHeightInches,
    stepDownInches,
    stepDownFeet,
    selectedWindLoadChange,
    selectedRoofLoadChange,
    widthFeet,
    widthInches,
    lengthFeet,
    lengthInches,
    selectedPostHeight,
    selectedKneeWallFeet,
    selectedKneeWallInches,
    selectedFasciaInches,
    selectedShingle,
    selectedFanBeam,
    selectedRoofDensity,
    pavstyle,
    heightAtHouse,
    frontHeight,
    roofstyle,
    selectedPavilionLayoutOption,
    selectedSkyviewChoice,
    selectedLEDLightChoice,
    selectedCleatsOrBond,
    selectedValueSeries,
    selectedRailSideOption,
    selectedRailAmountOption,
    selectedRoofPanelColorOption,
    selectedStructuralBeamOption,
    isValidEmail,
    selectedLocation,
    locations,
  ]);

  useEffect(() => {
    checkMissingFields();
  }, [
    checkMissingFields,
    selectedTrimOption,
    selectedBiHexOption,
    selectedExteriorOption,
    selectedPostStyleOption,
    selectedAttachedOption,
    selectedPermitOption,
    selectedFoundationOption,
    selectedKneeWallOption,
    selectedMountOption,
    personalInfo,
    selectedExistingFoundationOption,
    selectedNewFoundationOption,
    gradeHeightFeet,
    gradeHeightInches,
    stepDownInches,
    stepDownFeet,
    selectedWindLoadChange,
    selectedRoofLoadChange,
    widthFeet,
    widthInches,
    lengthFeet,
    lengthInches,
    selectedPostHeight,
    selectedKneeWallFeet,
    selectedKneeWallInches,
    selectedFasciaInches,
    selectedShingle,
    selectedFanBeam,
    selectedRoofDensity,
    pavstyle,
    heightAtHouse,
    frontHeight,
    roofstyle,
    selectedPavilionLayoutOption,
    selectedSkyviewChoice,
    selectedLEDLightChoice,
    selectedCleatsOrBond,
    selectedValueSeries,
    selectedRailSideOption,
    selectedRailAmountOption,
    selectedRoofPanelColorOption,
    selectedStructuralBeamOption,
    selectedLocation,
  ]);

  const handleSubmit = async (e) => {
    setSubmitIsLoading(true);
    e.preventDefault();
    const newMissingFields = [];
    if (
      selectedRoofDensity !== '4 1/4" 0.032 1 pound' &&
      selectedRoofDensity !== '4 1/4" 0.032 2 pound' &&
      selectedSkyviewChoice !== "Skyview YES"
    ) {
      if (!selectedRoofPanelColorOption) {
        newMissingFields.push("Roof Panel Color");
      }
    }
    if (
      selectedRoofDensity === '4 1/4" 0.032 1 pound' ||
      selectedRoofDensity === '4 1/4" 0.032 2 pound'
    ) {
      selectedRoofPanelColorOption = "";
    }

    if (roofstyle !== "CATHEDRAL") {
      if (!selectedSkyviewChoice) {
        newMissingFields.push("Skyview YES or NO");
      }
    }
    if (selectedSkyviewChoice === "Skyview YES") {
      if (!selectedLEDLightChoice) {
        newMissingFields.push("LED YES or NO");
      }
      selectedCleatsOrBond = "";
      selectedShingle = "";
      roofstyle = "";
      selectedRoofPanelColorOption = "";
    }
    if (selectedSkyviewChoice !== "Skyview YES") {
      selectedLEDLightChoice = "";
      if (!selectedShingle) {
        newMissingFields.push("Shingle Roof");
      }
      if (selectedShingle !== "YES") {
        if (!selectedCleatsOrBond) {
          newMissingFields.push("Cleats or Bond Tape");
        }
      }
    }

    if (!selectedTrimOption) {
      newMissingFields.push("Trim Color");
    }
    if (!selectedBiHexOption) {
      newMissingFields.push("Bi-Hex tool");
    }
    if (!selectedAttachedOption) {
      newMissingFields.push("Free-Standing or Attached");
    }
    if (selectedAttachedOption === "attached") {
      if (!selectedPavilionLayoutOption) {
        newMissingFields.push("StructureLayoutImages");
      }
      if (selectedPavilionLayoutOption === "2") {
        if (!selectedRailSideOption) {
          newMissingFields.push("rail side");
          newMissingFields.push("StructureLayoutImages");
        }
        if (!selectedRailAmountOption.feet) {
          newMissingFields.push("rail amount Feet");
          newMissingFields.push("StructureLayoutImages");
        }
        if (!selectedRailAmountOption.inches) {
          newMissingFields.push("rail amount Inches");
          newMissingFields.push("StructureLayoutImages");
        }
      }
      if (selectedPavilionLayoutOption === "3") {
        if (!selectedRailAmountOption.feet) {
          newMissingFields.push("rail amount Feet");
          newMissingFields.push("StructureLayoutImages");
        }
        if (!selectedRailAmountOption.inches) {
          newMissingFields.push("rail amount Inches");
          newMissingFields.push("StructureLayoutImages");
        }
        if (selectedPavilionLayoutOption !== "2") {
          selectedRailSideOption = "";
        }
        if (selectedPavilionLayoutOption === "1") {
          selectedRailAmountOption.feet = "";
          selectedRailAmountOption.inches = "";
        }
      }
    }
    if (!pavstyle) {
      newMissingFields.push("Pavilion Style");
    }
    if (pavstyle === "TEMO Panel") {
      if (
        selectedTrimOption === "SANDSTONE" ||
        selectedTrimOption === "WHITE"
      ) {
        if (!selectedValueSeries) {
          newMissingFields.push("Value Series");
        }
      }
      if (!selectedPostStyleOption) {
        newMissingFields.push("Post Style");
      }
      if (
        selectedPostStyleOption === "ROMA" ||
        selectedPostStyleOption === "FLORENTINE"
      ) {
        if (!selectedPostHeight) {
          newMissingFields.push("post height");
        }
      }
      if (!selectedExteriorOption) {
        newMissingFields.push("Exterior Style");
      }
      if (selectedPostStyleOption !== "NO COLUMNS OR BEAMS NEEDED") {
        if (!selectedStructuralBeamOption) {
          newMissingFields.push("Structural Beam");
        }
      }
      if (selectedPostStyleOption === "NO COLUMNS OR BEAMS NEEDED") {
        selectedStructuralBeamOption = "";
      }
    }
    if (pavstyle === "TEMO Panel" && selectedSkyviewChoice !== "Skyview YES") {
      if (!roofstyle) {
        newMissingFields.push("Roof Style");
      }
    }
    if (pavstyle !== "TEMO Panel") {
      selectedPostStyleOption = "";
      selectedPostHeight = "";
      selectedExteriorOption = "";
      roofstyle = "";
      selectedStructuralBeamOption = "";
    }
    if (!lengthFeet || lengthFeet < 4) {
      newMissingFields.push("length feet");
    }
    if (!widthFeet || widthFeet < 4) {
      newMissingFields.push("width feet");
    }
    if (!lengthInches) {
      newMissingFields.push("length inches");
    }
    if (!widthInches) {
      newMissingFields.push("width inches");
    }

    if (!selectedWindLoadChange) {
      newMissingFields.push("WindLoad");
    }

    if (!selectedRoofLoadChange) {
      newMissingFields.push("RoofLoad");
    }

    if (!heightAtHouse.heightfeet) {
      newMissingFields.push("Height at House Heightfeet");
    }

    if (!heightAtHouse.heightinches) {
      newMissingFields.push("Height at House Heightinches");
    }

    if (!frontHeight.frontfeet) {
      newMissingFields.push("Front Height Frontfeet");
    }

    if (!frontHeight.frontinches) {
      newMissingFields.push("Front Height Frontinches");
    }
    for (const key in personalInfo) {
      if (key === "firstName" && personalInfo[key] === "") {
        continue;
      }
      if (key === "purchaseOrder" && personalInfo[key] === "") {
        continue;
      }
      if (!personalInfo[key]) {
        newMissingFields.push(key);
      }
    }
    if (personalInfo["email"] && !isValidEmail) {
      newMissingFields.push("email");
    }
    if (locations && Object.keys(locations).length > 0) {
      if (!selectedLocation) {
        newMissingFields.push("location");
      }
    }
    if (!selectedPermitOption) {
      newMissingFields.push("permit package option");
    }
    if (selectedPermitOption === "YES") {
      if (!selectedFoundationOption) {
        missingFields.push("foundation option");
      }
      if (selectedFoundationOption === "newconcrete") {
        if (!selectedNewFoundationOption) {
          newMissingFields.push("Pick a new concrete option");
        }
      }
      if (selectedFoundationOption === "existingconcrete") {
        if (!selectedExistingFoundationOption) {
          newMissingFields.push("Pick an existing slab option");
        }
      }

      if (
        selectedFoundationOption === "existingwood" ||
        selectedFoundationOption === "nontemowood"
      ) {
        if (!gradeHeightInches) {
          newMissingFields.push("Please input inches grade height");
        }
        if (!gradeHeightFeet) {
          newMissingFields.push("Please input feet grade height");
        }
      }
      if (!selectedKneeWallOption) {
        newMissingFields.push("knee wall option");
      } else if (selectedKneeWallOption === "yes") {
        if (!selectedKneeWallFeet) {
          newMissingFields.push("Select Knee Wall Feet");
        }
        if (!selectedKneeWallInches) {
          newMissingFields.push("Select Knee Wall Inches");
        }
      } else if (selectedKneeWallOption === "no") {
        selectedKneeWallInches = "";
        selectedKneeWallFeet = "";
      }
    }

    if (
      selectedPermitOption === "YES" &&
      selectedAttachedOption === "attached"
    ) {
      if (!selectedMountOption) {
        newMissingFields.push("pick a mount");
      } else if (selectedMountOption === "A") {
        if (!selectedFasciaInches) {
          newMissingFields.push("fascia inches");
        }
      }
      if (!stepDownFeet) {
        newMissingFields.push("stepdown feet");
      }

      if (!stepDownInches) {
        newMissingFields.push("stepdown inches");
      }
    }
    if (selectedAttachedOption === "FREE-STANDING") {
      selectedMountOption = "";
      selectedFasciaInches = "";
      stepDownFeet = "";
      stepDownInches = "";
      selectedPavilionLayoutOption = "";
      selectedRailSideOption = "";
      selectedRailAmountOption.feet = "";
      selectedRailAmountOption.inches = "";
    }
    if (selectedPermitOption === "NO") {
      selectedFoundationOption = "";
      selectedNewFoundationOption = "";
      selectedExistingFoundationOption = "";
      gradeHeightInches = "";
      gradeHeightFeet = "";
      selectedKneeWallOption = "";
      selectedKneeWallFeet = "";
      selectedKneeWallInches = "";
      selectedMountOption = "";
      selectedFasciaInches = "";
      stepDownFeet = "";
      stepDownInches = "";
    }
    if (!selectedFanBeam) {
      newMissingFields.push("fan beam");
    }
    if (!selectedRoofDensity) {
      newMissingFields.push("roof density");
    }

    if (newMissingFields.length === 0) {
      const data = {
        PavillionsData: {
          selectedTrimOption,
          selectedBiHexOption,
          selectedExteriorOption,
          selectedPostStyleOption,
          selectedAttachedOption,
          selectedPermitOption,
          selectedFoundationOption,
          selectedKneeWallOption,
          selectedMountOption,
          personalInfo,
          selectedExistingFoundationOption,
          selectedNewFoundationOption,
          gradeHeightFeet,
          gradeHeightInches,
          stepDownInches,
          stepDownFeet,
          selectedWindLoadChange,
          selectedRoofLoadChange,
          widthFeet,
          widthInches,
          lengthFeet,
          lengthInches,
          selectedPostHeight,
          selectedKneeWallFeet,
          selectedKneeWallInches,
          selectedFasciaInches,
          selectedShingle,
          selectedFanBeam,
          selectedRoofDensity,
          pavstyle,
          heightAtHouse,
          frontHeight,
          roofstyle,
          selectedPavilionLayoutOption,
          selectedSkyviewChoice,
          selectedLEDLightChoice,
          selectedCleatsOrBond,
          additionalInfo,
          selectedValueSeries,
          selectedRailSideOption,
          selectedRailAmountOption,
          selectedRoofPanelColorOption,
          selectedStructuralBeamOption,
          selectedLocation,
        },
      };

      const token = await getAccessTokenSilently({
        authorizationParams: {
          audience: "TEMOAuth",
        },
      });
      const jsonData = JSON.stringify(data);

      fetch("/api/OrderingPavilions/SubmitPavilion", {
        method: "POST",
        body: jsonData,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
        .then((response) => {
          if (response.ok) {
            setEndMessage(<SuccessMessage />);
            setSubmitIsLoading(false);
            setSentCorrectly(true);
          } else if (response.status === 999) {
            setSubmitIsLoading(false);
            setEndMessage(<HoldErrorMessage />);
          } else if (response.status === 998) {
            setSubmitIsLoading(false);
            setEndMessage(<RepErrorMessage />);
          } else if (response.status === 997) {
            setSubmitIsLoading(false);
            setEndMessage(<PatioLockMessage />);
          } else {
            setSubmitIsLoading(false);
            setEndMessage(<ErrorMessage />);
          }
          setShowEndMessage(true);
        })
        .catch((error) => {
          console.error("Error sending data:", error);
          setSubmitIsLoading(false);
          setEndMessage(<ErrorMessage />);
          setShowEndMessage(true);
        });
      closeModal();
    } else {
      setFormattedMissingFields(formattedMissingFields);
      closeModal();
    }
  };

  return (
    <div className="popupwindow submit-stuff">
      {showEndMessage && <div>{endMessage}</div>}
      {showMissingFields && formattedMissingFields.length > 0 && (
        <MissingFieldsBox missingFields={formattedMissingFields} />
      )}
      {submitIsLoading && <div>Loading...</div>}
      <form id="submitbutton">
        {sentCorrectly !== true && submitIsLoading !== true && (
          <>
            <span className="realredasterisk">
              <div className="fontsizeforinputsrow" style={{ fontSize: "2vh" }}>
                Any&nbsp;Area&nbsp;With&nbsp;This&nbsp;&#10088;
                <b>
                  <span className="redasterisk">&#10033;</span>
                </b>
                &#10089;&nbsp;Is
              </div>
              <div
                className="fontsizeforinputsrow"
                style={{ textAlign: "center", fontSize: "2vh" }}
              >
                <u>
                  <b>REQUIRED</b>
                </u>
                &nbsp;Before&nbsp;Submitting
              </div>
            </span>
            <input
              id="submit"
              type="submit"
              className="showpdfbuttons submitbutton"
              onClick={openModal}
              value="Send to TEMO"
            />
          </>
        )}
      </form>
      {showModal && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            position: "fixed",
            height: "100%",
            width: "100%",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            backgroundColor: "rgba(34, 43, 43, .9)",
          }}
        >
          <div
            className="popupwindow"
            style={{
              height: "86vh",
              width: "45vw",
              display: "flex",
              flexDirection: "column",
              backgroundImage: "linear-gradient(to bottom, #09b9e148, #09b8e1)",
            }}
          >
            <SimpleBar style={{ maxHeight: "85vh" }}>
              <div
                style={{
                  height: "10%",
                  backgroundColor: "#9ecb6a",
                  top: "0",
                  marginTop: "0",
                  border: "1px solid black",
                }}
              >
                <h5>Are you sure you want to submit?</h5>
                <h5>These are your selections:</h5>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  backgroundColor: "white",
                  overflow: "hidden",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    textAlign: "left",
                    margin: "0",
                    width: "50%",
                    height: "100%",
                  }}
                >
                  {personalInfo.lastName && (
                    <div className="modalinfoleft">
                      <p className="modalrowleft">Last Name:</p>
                      <p className="modalrowright">{personalInfo.lastName}</p>
                    </div>
                  )}
                  {personalInfo.firstName && (
                    <div className="modalinfoleft">
                      <p className="modalrowleft">First Name:</p>
                      <p className="modalrowright">{personalInfo.firstName}</p>
                    </div>
                  )}
                  {personalInfo.address && (
                    <div className="modalinfoleft">
                      <p className="modalrowleft">Address:</p>
                      <p className="modalrowright">{personalInfo.address}</p>
                    </div>
                  )}
                  {personalInfo.city && (
                    <div className="modalinfoleft">
                      <p className="modalrowleft">City:</p>
                      <p className="modalrowright">{personalInfo.city}</p>
                    </div>
                  )}
                  {personalInfo.stateProvince && (
                    <div className="modalinfoleft">
                      <p className="modalrowleft">State/Province:</p>
                      <p className="modalrowright">
                        {personalInfo.stateProvince}
                      </p>
                    </div>
                  )}
                  {personalInfo.zip && (
                    <div className="modalinfoleft">
                      <p className="modalrowleft">Zip:</p>
                      <p className="modalrowright">{personalInfo.zip}</p>
                    </div>
                  )}
                  {personalInfo.purchaseOrder && (
                    <div className="modalinfoleft">
                      <p className="modalrowleft">Purchase Order:</p>
                      <p className="modalrowright">
                        {personalInfo.purchaseOrder}
                      </p>
                    </div>
                  )}
                  {selectedAttachedOption && (
                    <div className="modalinfoleft">
                      <p
                        className="modalrowleft"
                        style={{ whiteSpace: "nowrap" }}
                      >
                        Attached or Free-Standing:
                      </p>
                      <p className="modalrowright">{selectedAttachedOption}</p>
                    </div>
                  )}
                  {selectedTrimOption && (
                    <div className="modalinfoleft">
                      <p className="modalrowleft">Frame Color:</p>
                      <p className="modalrowright">{selectedTrimOption}</p>
                    </div>
                  )}
                  {selectedPostStyleOption && (
                    <div className="modalinfoleft">
                      <p className="modalrowleft">Post Style:</p>
                      <p className="modalrowright">{selectedPostStyleOption}</p>
                    </div>
                  )}
                  {selectedPostHeight && (
                    <div className="modalinfoleft">
                      <p className="modalrowleft">Height of Post:</p>
                      <p className="modalrowright">{selectedPostHeight}</p>
                    </div>
                  )}
                  {selectedExteriorOption && (
                    <div className="modalinfoleft">
                      <p className="modalrowleft">Exterior Style:</p>
                      <p className="modalrowright">{selectedExteriorOption}</p>
                    </div>
                  )}
                  {lengthFeet && (
                    <div className="modalinfoleft">
                      <p className="modalrowleft">Feet of Length:</p>
                      <p className="modalrowright">{lengthFeet}</p>
                    </div>
                  )}
                  {lengthInches && (
                    <div className="modalinfoleft">
                      <p className="modalrowleft">Inches of Length:</p>
                      <p className="modalrowright">{lengthInches}</p>
                    </div>
                  )}
                  {widthFeet && (
                    <div className="modalinfoleft">
                      <p className="modalrowleft">Feet of Width:</p>
                      <p className="modalrowright">{widthFeet}</p>
                    </div>
                  )}
                  {widthInches && (
                    <div className="modalinfoleft">
                      <p className="modalrowleft">Inches of Width:</p>
                      <p className="modalrowright">{widthInches}</p>
                    </div>
                  )}
                  {selectedBiHexOption && (
                    <div className="modalinfoleft">
                      <p className="modalrowleft">Bi-Hex Tool Option:</p>
                      <p className="modalrowright">{selectedBiHexOption}</p>
                    </div>
                  )}
                  {selectedShingle && (
                    <div className="modalinfoleft">
                      <p className="modalrowleft">Shingle:</p>
                      <p className="modalrowright">{selectedShingle}</p>
                    </div>
                  )}
                  {selectedFanBeam && (
                    <div className="modalinfoleft">
                      <p className="modalrowleft">Fan Beam:</p>
                      <p className="modalrowright">{selectedFanBeam}</p>
                    </div>
                  )}
                  {selectedRoofDensity && (
                    <div className="modalinfoleft">
                      <p className="modalrowleft">Roof Density:</p>
                      <p className="modalrowright">{selectedRoofDensity}</p>
                    </div>
                  )}
                  {selectedPavilionLayoutOption && (
                    <div className="modalinfoleft">
                      <p className="modalrowleft">Pavilion Layout:</p>
                      <p className="modalrowright">
                        {selectedPavilionLayoutOption}
                      </p>
                    </div>
                  )}
                  {selectedSkyviewChoice && (
                    <div className="modalinfoleft">
                      <p className="modalrowleft">Skyview:</p>
                      <p className="modalrowright">{selectedSkyviewChoice}</p>
                    </div>
                  )}
                  {selectedLEDLightChoice && (
                    <div className="modalinfoleft">
                      <p className="modalrowleft">LED Lights:</p>
                      <p className="modalrowright">{selectedLEDLightChoice}</p>
                    </div>
                  )}
                  {selectedRailAmountOption.feet && (
                    <div className="modalinfoleft">
                      <p className="modalrowleft">Rail Amount Feet:</p>
                      <p className="modalrowright">
                        {selectedRailAmountOption.feet}
                      </p>
                    </div>
                  )}
                  {selectedRailAmountOption.inches && (
                    <div className="modalinfoleft">
                      <p className="modalrowleft">Rail Amount Inches:</p>
                      <p className="modalrowright">
                        {selectedRailAmountOption.inches}
                      </p>
                    </div>
                  )}
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    textAlign: "right",
                    margin: "0",
                    width: "50%",
                  }}
                >
                  {personalInfo.email && (
                    <div className="modalinforight">
                      <p className="modalrowleft">Email:</p>
                      <p className="modalrowright">{personalInfo.email}</p>
                    </div>
                  )}
                  {personalInfo.sender && (
                    <div className="modalinforight">
                      <p className="modalrowleft">Sender:</p>
                      <p className="modalrowright">{personalInfo.sender}</p>
                    </div>
                  )}
                  {selectedLocation && (
                    <div className="modalinforight">
                      <p className="modalrowleft">Location:</p>
                      <p className="modalrowright">{selectedLocation}</p>
                    </div>
                  )}
                  {selectedPermitOption && (
                    <div className="modalinforight">
                      <p className="modalrowleft">Drawing Package:</p>
                      <p className="modalrowright">{selectedPermitOption}</p>
                    </div>
                  )}
                  {selectedFoundationOption && (
                    <div className="modalinforight">
                      <p className="modalrowleft">Foundation:</p>
                      <p className="modalrowright">
                        {selectedFoundationOption}
                      </p>
                    </div>
                  )}
                  {selectedExistingFoundationOption && (
                    <div className="modalinforight">
                      <p className="modalrowleft">Existing Concrete:</p>
                      <p className="modalrowright">
                        {selectedExistingFoundationOption}
                      </p>
                    </div>
                  )}
                  {selectedNewFoundationOption && (
                    <div className="modalinforight">
                      <p className="modalrowleft">New Concrete:</p>
                      <p className="modalrowright">
                        {selectedNewFoundationOption}
                      </p>
                    </div>
                  )}
                  {selectedRoofLoadChange && (
                    <div className="modalinforight">
                      <p className="modalrowleft">Roof Load:</p>
                      <p className="modalrowright">{selectedRoofLoadChange}</p>
                    </div>
                  )}
                  {selectedWindLoadChange && (
                    <div className="modalinforight">
                      <p className="modalrowleft">Wind Load:</p>
                      <p className="modalrowright">{selectedWindLoadChange}</p>
                    </div>
                  )}
                  {selectedMountOption && (
                    <div className="modalinforight">
                      <p className="modalrowleft">Mount Option:</p>
                      <p className="modalrowright">{selectedMountOption}</p>
                    </div>
                  )}
                  {selectedFasciaInches && (
                    <div className="modalinforight">
                      <p className="modalrowleft">Soffit Depth:</p>
                      <p className="modalrowright">{selectedFasciaInches}</p>
                    </div>
                  )}
                  {stepDownInches && (
                    <div className="modalinforight">
                      <p className="modalrowleft">Inches of Step Down:</p>
                      <p className="modalrowright">{stepDownInches}</p>
                    </div>
                  )}
                  {stepDownFeet && (
                    <div className="modalinforight">
                      <p className="modalrowleft">Feet of Step Down:</p>
                      <p className="modalrowright">{stepDownFeet}</p>
                    </div>
                  )}
                  {gradeHeightInches && (
                    <div className="modalinforight">
                      <p className="modalrowleft">Inches of Grade Height:</p>
                      <p className="modalrowright">{gradeHeightInches}</p>
                    </div>
                  )}
                  {gradeHeightFeet && (
                    <div className="modalinforight">
                      <p className="modalrowleft">Feet of Grade Height:</p>
                      <p className="modalrowright">{gradeHeightFeet}</p>
                    </div>
                  )}
                  {selectedKneeWallOption && (
                    <div className="modalinforight">
                      <p className="modalrowleft">Knee Wall Option:</p>
                      <p className="modalrowright">{selectedKneeWallOption}</p>
                    </div>
                  )}
                  {selectedKneeWallFeet && (
                    <div className="modalinforight">
                      <p className="modalrowleft">Feet of Knee Wall:</p>
                      <p className="modalrowright">{selectedKneeWallFeet}</p>
                    </div>
                  )}
                  {selectedKneeWallInches && (
                    <div className="modalinforight">
                      <p className="modalrowleft">Inches of Knee Wall:</p>
                      <p className="modalrowright">{selectedKneeWallInches}</p>
                    </div>
                  )}
                  {pavstyle && (
                    <div className="modalinforight">
                      <p className="modalrowleft">Pavilion Style:</p>
                      <p className="modalrowright">{pavstyle}</p>
                    </div>
                  )}
                  {heightAtHouse.heightfeet && (
                    <div className="modalinforight">
                      <p className="modalrowleft">Height at House Feet:</p>
                      <p className="modalrowright">
                        {heightAtHouse.heightfeet}
                      </p>
                    </div>
                  )}
                  {heightAtHouse.heightinches && (
                    <div className="modalinforight">
                      <p className="modalrowleft">Height at House Inches:</p>
                      <p className="modalrowright">
                        {heightAtHouse.heightinches}
                      </p>
                    </div>
                  )}
                  {frontHeight.frontfeet && (
                    <div className="modalinforight">
                      <p className="modalrowleft">Front Height Feet:</p>
                      <p className="modalrowright">{frontHeight.frontfeet}</p>
                    </div>
                  )}
                  {frontHeight.frontinches && (
                    <div className="modalinforight">
                      <p className="modalrowleft">Front Height Inches:</p>
                      <p className="modalrowright">{frontHeight.frontinches}</p>
                    </div>
                  )}
                  {roofstyle && (
                    <div className="modalinforight">
                      <p className="modalrowleft">Roof Style:</p>
                      <p className="modalrowright">{roofstyle}</p>
                    </div>
                  )}
                  {selectedValueSeries && (
                    <div className="modalinforight">
                      <p className="modalrowleft">Value Series:</p>
                      <p className="modalrowright">{selectedValueSeries}</p>
                    </div>
                  )}
                  {selectedRailSideOption && (
                    <div className="modalinforight">
                      <p className="modalrowleft">Rail Side:</p>
                      <p className="modalrowright">{selectedRailSideOption}</p>
                    </div>
                  )}
                  {selectedCleatsOrBond && (
                    <div className="modalinforight">
                      <p className="modalrowleft">Cleats or Bond Tape:</p>
                      <p className="modalrowright">{selectedCleatsOrBond}</p>
                    </div>
                  )}
                  {selectedStructuralBeamOption && (
                    <div className="modalinforight">
                      <p className="modalrowleft">Structural Beam:</p>
                      <p className="modalrowright">
                        {selectedStructuralBeamOption}
                      </p>
                    </div>
                  )}
                  {selectedRoofPanelColorOption !== null &&
                    selectedRoofPanelColorOption !== undefined &&
                    selectedSkyviewChoice !== "Skyview YES" && (
                      <div className="modalinforight">
                        <p className="modalrowleft">Roof Panel Color:</p>
                        <p
                          className="modalrowright"
                          style={{ fontSize: "1.3vh" }}
                        >
                          {selectedRoofPanelColorOption === ""
                            ? "White Top, White Bottom"
                            : selectedRoofPanelColorOption}
                        </p>
                      </div>
                    )}{" "}
                </div>
              </div>
              <div
                style={{
                  height: "fit-content",
                  marginTop: "1%",
                  marginBottom: "5%",
                }}
              >
                <div>
                  {Array.from({ length: 5 }).map((_, index) => (
                    <textarea
                      key={index}
                      value={additionalInfo[index] || ""}
                      onChange={(e) =>
                        handleAdditionalInfoChange(index, e.target.value)
                      }
                      placeholder={`Special Instructions ${index + 1}`}
                      rows="1"
                      maxLength="48"
                      style={{ width: "94%", margin: "-.4%" }}
                    />
                  ))}
                </div>
                <div>
                  <b>Please Email Attachments To: </b>
                  <br></br>
                  <b>Steve Salter:&nbsp;</b>
                  <a href="mailto:ssalter@temoinc.com">ssalter@temoinc.com</a>
                  <br></br>
                  <b>Phil Taravella:&nbsp;</b>
                  <a href="mailto:ptaravella@temoinc.com">
                    ptaravella@temoinc.com
                  </a>
                </div>
                <button
                  className="showpdfbuttons"
                  style={{
                    width: "fit-content",
                    height: "auto",
                    float: "inline-start",
                    marginLeft: "20%",
                  }}
                  onClick={() => {
                    setSubmitIsLoading(false);
                    closeModal();
                  }}
                >
                  No
                </button>
                {formattedMissingFields.length === 0 && (
                  <button
                    className="showpdfbuttons"
                    style={{
                      width: "fit-content",
                      height: "auto",
                      float: "inline-end",
                      marginRight: "20%",
                    }}
                    onClick={handleSubmit}
                  >
                    Yes
                  </button>
                )}
              </div>
            </SimpleBar>
          </div>
        </div>
      )}
    </div>
  );
};

SubmitButton.propTypes = {
  openModal: PropTypes.func,
  closeModal: PropTypes.func,
  showMissingFields: PropTypes.bool,
  formattedMissingFields: PropTypes.array,
  setFormattedMissingFields: PropTypes.func,
  setMissingFields: PropTypes.func,
  selectedTrimOption: PropTypes.string,
  selectedBiHexOption: PropTypes.string,
  selectedExteriorOption: PropTypes.string,
  selectedPostStyleOption: PropTypes.string,
  selectedAttachedOption: PropTypes.string,
  selectedPermitOption: PropTypes.string,
  selectedFoundationOption: PropTypes.string,
  selectedKneeWallOption: PropTypes.string,
  selectedMountOption: PropTypes.string,
  personalInfo: PropTypes.object,
  selectedExistingFoundationOption: PropTypes.string,
  selectedNewFoundationOption: PropTypes.string,
  gradeHeightFeet: PropTypes.string,
  gradeHeightInches: PropTypes.string,
  stepDownInches: PropTypes.string,
  stepDownFeet: PropTypes.string,
  selectedWindLoadChange: PropTypes.string,
  selectedRoofLoadChange: PropTypes.string,
  widthFeet: PropTypes.string,
  widthInches: PropTypes.string,
  lengthFeet: PropTypes.string,
  lengthInches: PropTypes.string,
  selectedPostHeight: PropTypes.string,
  selectedKneeWallFeet: PropTypes.string,
  selectedKneeWallInches: PropTypes.string,
  selectedFasciaInches: PropTypes.string,
  selectedShingle: PropTypes.string,
  selectedFanBeam: PropTypes.string,
  selectedRoofDensity: PropTypes.string,
  pavstyle: PropTypes.string,
  heightAtHouse: PropTypes.object,
  frontHeight: PropTypes.object,
  roofstyle: PropTypes.string,
  selectedPavilionLayoutOption: PropTypes.string,
  selectedSkyviewChoice: PropTypes.string,
  selectedLEDLightChoice: PropTypes.string,
  selectedCleatsOrBond: PropTypes.string,
  additionalInfo: PropTypes.array,
  selectedValueSeries: PropTypes.string,
  selectedRailSideOption: PropTypes.string,
  selectedRailAmountOption: PropTypes.object,
  selectedRoofPanelColorOption: PropTypes.string,
  selectedStructuralBeamOption: PropTypes.string,
  onRedBorderCheck: PropTypes.func,
  isValidEmail: PropTypes.bool,
  selectedLocation: PropTypes.string,
  locations: PropTypes.object,
};

export default SubmitButton;
