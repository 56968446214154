import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import angled1 from "./AngledLayoutImages/angled1.jpg";
import angled2 from "./AngledLayoutImages/angled2.jpg";
import angled3 from "./AngledLayoutImages/angled3.jpg";
import angled4 from "./AngledLayoutImages/angled4.jpg";
import angled5 from "./AngledLayoutImages/angled5.jpg";
import angled6 from "./AngledLayoutImages/angled6.jpg";
import angled7 from "./AngledLayoutImages/angled7.jpg";
import angled8 from "./AngledLayoutImages/angled8.jpg";
import angled9 from "./AngledLayoutImages/angled9.jpg";
import angled10 from "./AngledLayoutImages/angled10.jpg";
import LeftAngle from "./LeftAngle";
import RightAngle from "./RightAngle";
import StyledDiv from "../ExistingRoof/LinconLogImages";
import SingleWallSelection from "../ExistingRoof/SingleWallSelection";
import AngledDoorHardware from "./AngledDoorHardware";

const AngledWalls = ({
  redBorderFields,
  selectedAngleOption,
  setFieldsWithRedBorder,
  rightSideMissingWallLength,
  setRightMissingWallLength,
  setRightSideMissingWallDiv,
  leftSideMissingWallLength,
  setLeftMissingWallLength,
  setLeftSideMissingWallDiv,
  leftSideMissingWallDiv,
  rightSideMissingWallDiv,
  handleCodeSelect,
  selectedStraightCodes,
  setSelectedStraightCodes,
  selectedStraightButtonIndex,
  setSelectedStraightButtonIndex,
  selectedStraightImages,
  setSelectedStraightImages,
  selectedStraightPatioDoorOrientation,
  selectedStraightSwingDoorOrientation,
  setSelectedStraightSwingDoorOrientation,
  setSelectedStraightPatioDoorOrientation,
  selectedSunRoomType,
  lastWallLengthUpdate,
  setLastWallLengthUpdate,
  handleDoorWidthChange,
  setDoorStraightWidth,
  doorStraightWidth,
  handleDoorOptionChange,
  doorStraightOption,
  setDoorStraightOption,
  handleWallLengthInchesChange,
  setWallStraightLengthsFeet,
  setWallStraightLengthsInches,
  handleWallLengthFeetChange,
  wallStraightLengthsFeet,
  wallStraightLengthsInches,
  selectedDoorHardware,
  setSelectedDoorHardware,
  SSHinges,
  handleSSHingesOptionChange,
  rightAngleFeet,
  setRightAngleFeet,
  rightAngleInches,
  setRightAngleInches,
  leftAngleFeet,
  setLeftAngleFeet,
  leftAngleInches,
  setLeftAngleInches,
  isScreenRoom,
}) => {
  const [isOptionsModalVisible, setIsOptionsModalVisible] = useState(false);
  const [openSingleDrawing, setOpenSingleDrawing] = useState(false);
  const [currentWallType, setCurrentWallType] = useState(null);
  const [isFront, setIsFront] = useState(false);
  const [isAngled, setIsAngled] = useState(false);
  const [index, setIndex] = useState(null);
  const [newIndex, setNewIndex] = useState(null);
  const [dimension, setDimension] = useState(
    Math.min(window.innerHeight, window.innerWidth),
  );

  const [showDoorHardware, setShowDoorHardware] = useState(false);

  const handleShowDoorHardwareClick = () => {
    setShowDoorHardware(true);
  };

  useEffect(() => {
    const handleResize = () => {
      setDimension(Math.min(window.innerHeight, window.innerWidth));
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const renderDiv = (divs) => {
    const styledDiv = divs.map((div, index) => (
      <StyledDiv
        id={div.id}
        key={`styled-${index}`}
        gridColumn={div.gridColumn}
        gridRow={div.gridRow}
        backgroundImage={div.backgroundImage}
        onClick={div.cursorPointer ? div.onClick : undefined}
        cursorPointer={div.cursorPointer}
        width={div.width}
        height={div.height}
      />
    ));
    return styledDiv;
  };

  useEffect(() => {
    if (rightAngleFeet !== "" && rightAngleInches !== "") {
      var rightAngletotalinches =
        Number(rightAngleFeet) * 12 + Number(rightAngleInches);
      let rightAngleHypo = Math.sqrt(
        rightAngletotalinches * rightAngletotalinches +
          rightAngletotalinches * rightAngletotalinches,
      );
      // Convert rightAngleHypo to feet and inches
      let feet = Math.floor(rightAngleHypo / 12);
      let inches = (rightAngleHypo % 12).toFixed(3);
      wallStraightLengthsFeet[4] = feet.toString();
      wallStraightLengthsInches[4] = inches.toString();
    }

    if (leftAngleFeet !== "" && leftAngleInches !== "") {
      var leftAngletotalinches =
        Number(leftAngleFeet) * 12 + Number(leftAngleInches);
      let leftAngleHypo = Math.sqrt(
        leftAngletotalinches * leftAngletotalinches +
          leftAngletotalinches * leftAngletotalinches,
      );

      let feet = Math.floor(leftAngleHypo / 12);
      let inches = (leftAngleHypo % 12).toFixed(3);

      wallStraightLengthsFeet[3] = feet.toString();
      wallStraightLengthsInches[3] = inches.toString();
    }
  }, [
    leftAngleFeet,
    leftAngleInches,
    rightAngleFeet,
    rightAngleInches,
    wallStraightLengthsFeet,
    wallStraightLengthsInches,
  ]);

  const handleRightAngleFeetChange = (event) => {
    let input = event.target.value;
    input = input.replace(/\D/g, "").slice(0, 3);
    setRightAngleFeet(input);
    const updatedRedBorderFields = redBorderFields.includes("length feet")
      ? input
        ? redBorderFields.filter((field) => field !== "length feet")
        : redBorderFields
      : input
        ? redBorderFields
        : [...redBorderFields, "length feet"];
    setFieldsWithRedBorder(updatedRedBorderFields);
  };

  const handleRightAngleInchesChange = (event) => {
    let input = event.target.value;
    input = input.replace(/\D/g, "").slice(0, 3);
    setRightAngleInches(input);
    const updatedRedBorderFields = redBorderFields.includes("length inches")
      ? input
        ? redBorderFields.filter((field) => field !== "length inches")
        : redBorderFields
      : input
        ? redBorderFields
        : [...redBorderFields, "length inches"];
    setFieldsWithRedBorder(updatedRedBorderFields);
  };

  const handleLeftAngleFeetChange = (event) => {
    let input = event.target.value;
    input = input.replace(/\D/g, "").slice(0, 3);
    setLeftAngleFeet(input);
    const updatedRedBorderFields = redBorderFields.includes("length feet")
      ? input
        ? redBorderFields.filter((field) => field !== "length feet")
        : redBorderFields
      : input
        ? redBorderFields
        : [...redBorderFields, "length feet"];
    setFieldsWithRedBorder(updatedRedBorderFields);
  };

  const handleLeftAngleInchesChange = (event) => {
    let input = event.target.value;
    input = input.replace(/\D/g, "").slice(0, 3);
    setLeftAngleInches(input);
    const updatedRedBorderFields = redBorderFields.includes("length inches")
      ? input
        ? redBorderFields.filter((field) => field !== "length inches")
        : redBorderFields
      : input
        ? redBorderFields
        : [...redBorderFields, "length inches"];
    setFieldsWithRedBorder(updatedRedBorderFields);
  };

  const angleImages = {
    1: angled1,
    2: angled2,
    3: angled3,
    4: angled4,
    5: angled5,
    6: angled6,
    7: angled7,
    8: angled8,
    9: angled9,
    10: angled10,
  };

  const wallTypes = [
    "Left Straight",
    "Right Straight",
    "Front",
    "Left Angle",
    "Right Angle",
  ];

  const baseNames = [
    "Door Option",
    "Door Width",
    "Wall Code",
    "Swing Door Orientation",
    "Patio Door Orientation",
    "Length Feet",
    "Length Inches",
  ];
  const extras = ["Door Hardware", "SS Hinges"];

  const componentNames = [];
  wallTypes.forEach((wallType) => {
    baseNames.forEach((baseName) => {
      for (let i = 0; i <= 4; i++) {
        const name = `${wallType} ${baseName}`.trim();
        componentNames.push(name);
      }
    });
  });

  componentNames.push(...extras);

  const shouldHaveRedBorder = componentNames.some((name) =>
    redBorderFields.includes(name),
  );

  const leftStraightBorder = componentNames
    .filter((name) => name.startsWith("Left Straight"))
    .some((name) => redBorderFields.includes(name));

  const rightStraightBorder = componentNames
    .filter((name) => name.startsWith("Right Straight"))
    .some((name) => redBorderFields.includes(name));

  const frontBorder = componentNames
    .filter((name) => name.startsWith("Front"))
    .some((name) => redBorderFields.includes(name));

  const leftAngleBorder = componentNames
    .filter((name) => name.startsWith("Left Angle"))
    .some((name) => redBorderFields.includes(name));

  const rightAngleBorder = componentNames
    .filter((name) => name.startsWith("Right Angle"))
    .some((name) => redBorderFields.includes(name));

  const handleSeeOptionsClick = () => {
    setIsOptionsModalVisible(true);
  };

  const handleCloseModal = () => {
    setIsOptionsModalVisible(false);
  };

  const notToday = true;

  const GridButton = (
    gridColumn,
    gridRow,
    onClick,
    redBorder,
    key,
    newindex,
  ) => {
    let doorWidth;
    if (doorStraightOption[newindex] === "") {
      doorWidth = doorStraightOption[newindex];
    } else if (doorStraightOption[newindex] === "No") {
      doorWidth = "No Door";
    } else {
      doorWidth = doorStraightWidth[newindex];
    }

    let swingpatiodoor;
    if (selectedStraightSwingDoorOrientation[newindex] !== "") {
      swingpatiodoor = selectedStraightSwingDoorOrientation[newindex];
    }
    if (selectedStraightPatioDoorOrientation[newindex] !== "") {
      swingpatiodoor = selectedStraightPatioDoorOrientation[newindex];
    }

    let walllength;
    if (wallStraightLengthsFeet[newindex]) {
      walllength = `${wallStraightLengthsFeet[newindex]}' ${wallStraightLengthsInches[newindex]}"`;
    } else if (!wallStraightLengthsFeet[newindex]) {
      walllength = "";
    }

    let values = [
      selectedStraightCodes[newindex],
      walllength,
      doorWidth,
      swingpatiodoor,
    ];

    return (
      <div
        key={key}
        style={{
          gridColumn: `${gridColumn} / span 4`,
          gridRow:
            values[1] !== ""
              ? newindex !== 1
                ? `${Number(gridRow) - 4} / span 8`
                : `${gridRow} / span 8`
              : `${gridRow} / span 4`,
          display: "flex",
          flexDirection: newindex !== 1 ? "column" : "column-reverse",
        }}
      >
        {values[1] !== "" && (
          <div
            className="popupwindow"
            style={{
              flex: 1,
              display: "grid",
              gridTemplateRows: "repeat(4, 1fr)",
              gridTemplateColumns: "1fr",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
              width: "100%",
              marginTop: newindex !== 1 ? "0" : "1vh",
              marginBottom: newindex !== 1 ? "1vh" : "0",
            }}
          >
            {values.map((value, index) => (
              <div
                key={index}
                style={{
                  display: "flex",
                  height: "100%",
                  width: "100%",
                  justifyContent: "center",
                  alignItems: "center",
                  fontSize: "1.4vh",
                  textAlign: "center",
                  backgroundColor:
                    (index === 3 &&
                      values[2] !== "" &&
                      values[2] !== "No Door" &&
                      !values[3]) ||
                    (index === 2 &&
                      !values[2] &&
                      ((wallStraightLengthsFeet[newindex] > 4 &&
                        selectedSunRoomType !== "Premier View") ||
                        (wallStraightLengthsFeet[newindex] >= 5 &&
                          selectedSunRoomType === "Premier View"))) ||
                    (index !== 3 && index !== 2 && value === "")
                      ? "red"
                      : "white",
                  borderTop: index !== 0 ? "1px solid black" : "none",
                }}
              >
                {!value && (
                  <span>
                    {index === 0 && <span>Code</span>}
                    {index === 1 && <span>Length</span>}
                    {index === 2 &&
                      Number(wallStraightLengthsFeet[newindex]) >= 4 && (
                        <span>Door</span>
                      )}
                    {index === 3 &&
                      values[2] !== "No Door" &&
                      values[2] !== "" && <span>Orientation</span>}
                  </span>
                )}
                {value && <span>{value}</span>}
              </div>
            ))}
          </div>
        )}
        <button
          className={`navbutton angledButton ${redBorder ? "redBackground" : "greenBackground"}`}
          style={{ flex: 1 }}
          onClick={onClick}
        >
          Pick&nbsp;Wall
        </button>
      </div>
    );
  };
  const handleLeftStraightClick = () => {
    setCurrentWallType("Left Straight");
    setIndex(0);
    setNewIndex(0);
    setIsFront(false);
    setIsAngled(false);
    setOpenSingleDrawing(true);
  };

  const handleRightStraightClick = () => {
    setCurrentWallType("Right Straight");
    setIndex(2);
    setNewIndex(2);
    setIsFront(false);
    setIsAngled(false);
    setOpenSingleDrawing(true);
  };

  const handleFrontClick = () => {
    setCurrentWallType("Front");
    setIndex(1);
    setNewIndex(1);
    setIsFront(true);
    setIsAngled(false);
    setOpenSingleDrawing(true);
  };

  const handleLeftAngleClick = () => {
    setCurrentWallType("Left Angle");
    setIndex(3);
    setNewIndex(0);
    setIsFront(false);
    setIsAngled(true);
    setOpenSingleDrawing(true);
  };

  const handleRightAngleClick = () => {
    setCurrentWallType("Right Angle");
    setIndex(4);
    setNewIndex(2);
    setIsFront(false);
    setIsAngled(true);
    setOpenSingleDrawing(true);
  };

  const renderGridButtons = (selectedAngleOption) => {
    const gridButtonMap = {
      1: [
        [
          "18",
          "28",
          handleLeftAngleClick,
          leftAngleBorder,
          3,
          leftAngleFeet !== "" && leftAngleInches !== "",
        ],
        [
          "30",
          "28",
          handleRightAngleClick,
          rightAngleBorder,
          4,
          rightAngleFeet !== "" && rightAngleInches !== "",
        ],
        ["24", "42", handleFrontClick, frontBorder, 1, true],
        ["10", "20", handleLeftStraightClick, leftStraightBorder, 0, true],
        ["38", "20", handleRightStraightClick, rightStraightBorder, 2, true],
      ],
      2: [
        [
          "18",
          "30",
          handleLeftAngleClick,
          leftAngleBorder,
          3,
          leftAngleFeet !== "" && leftAngleInches !== "",
        ],
        ["24", "42", handleFrontClick, frontBorder, 1, true],
        ["10", "20", handleLeftStraightClick, leftStraightBorder, 0, true],
        ["38", "20", handleRightStraightClick, rightStraightBorder, 2, true],
      ],
      3: [
        [
          "19",
          "30",
          handleLeftAngleClick,
          leftAngleBorder,
          3,
          leftAngleFeet !== "" && leftAngleInches !== "",
        ],
        [
          "30",
          "30",
          handleRightAngleClick,
          rightAngleBorder,
          4,
          rightAngleFeet !== "" && rightAngleInches !== "",
        ],
        ["24", "42", handleFrontClick, frontBorder, 2, true],
        ["10", "20", handleLeftStraightClick, leftStraightBorder, 0, true],
      ],
      4: [
        [
          "21",
          "30",
          handleLeftAngleClick,
          leftAngleBorder,
          3,
          leftAngleFeet !== "" && leftAngleInches !== "",
        ],
        ["28", "42", handleFrontClick, frontBorder, 1, true],
        ["12", "20", handleLeftStraightClick, leftStraightBorder, 0, true],
      ],
      5: [
        [
          "19",
          "26",
          handleLeftAngleClick,
          leftAngleBorder,
          3,
          leftAngleFeet !== "" && leftAngleInches !== "",
        ],
        ["25", "37", handleFrontClick, frontBorder, 1, true],
      ],
      6: [
        [
          "18",
          "26",
          handleLeftAngleClick,
          leftAngleBorder,
          3,
          leftAngleFeet !== "" && leftAngleInches !== "",
        ],
        [
          "30",
          "26",
          handleRightAngleClick,
          rightAngleBorder,
          4,
          rightAngleFeet !== "" && rightAngleInches !== "",
        ],
        ["24", "37", handleFrontClick, frontBorder, 1, true],
      ],
      7: [
        [
          "30",
          "30",
          handleRightAngleClick,
          rightAngleBorder,
          4,
          rightAngleFeet !== "" && rightAngleInches !== "",
        ],
        ["24", "42", handleFrontClick, frontBorder, 1, true],
        ["10", "20", handleLeftStraightClick, leftStraightBorder, 0, true],
        ["39", "20", handleRightStraightClick, rightStraightBorder, 2, true],
      ],
      8: [
        [
          "18",
          "30",
          handleLeftAngleClick,
          leftAngleBorder,
          3,
          leftAngleFeet !== "" && leftAngleInches !== "",
        ],
        [
          "29",
          "30",
          handleRightAngleClick,
          rightAngleBorder,
          4,
          rightAngleFeet !== "" && rightAngleInches !== "",
        ],
        ["24", "42", handleFrontClick, frontBorder, 1, true],
        ["38", "20", handleRightStraightClick, rightStraightBorder, 2, true],
      ],
      9: [
        [
          "27",
          "29",
          handleRightAngleClick,
          rightAngleBorder,
          4,
          rightAngleFeet !== "" && rightAngleInches !== "",
        ],
        ["22", "41", handleFrontClick, frontBorder, 1, true],
        ["36", "20", handleRightStraightClick, rightStraightBorder, 2, true],
      ],
      10: [
        [
          "29",
          "25",
          handleRightAngleClick,
          rightAngleBorder,
          4,
          rightAngleFeet !== "" && rightAngleInches !== "",
        ],
        ["23", "36", handleFrontClick, frontBorder, 1, true],
      ],
    };

    return (
      <>
        {gridButtonMap[selectedAngleOption.toString()]?.map(
          ([gridColumn, gridRow, onClick, wallType, newindex, shouldRender]) =>
            shouldRender &&
            GridButton(
              gridColumn,
              gridRow,
              onClick,
              wallType,
              gridColumn.toString() + gridRow.toString(),
              newindex,
            ),
        )}
      </>
    );
  };

  return (
    <div
      style={{
        gridColumn: "1",
        gridRow: "1",
        justifySelf: "end",
        marginTop: "10vh",
        paddingTop: "1%",
        width: "8vw",
        height: "fit-content",
        marginRight: "2.4%",
        marginLeft: "2.4%",
        display: "flex",
        alignSelf: "center",
      }}
    >
      <button
        className={`navbutton ${shouldHaveRedBorder ? "redBackground" : "greenBackground"}`}
        style={{
          fontSize: "2vh",
          width: "7vw",
          overflow: "hidden",
          marginTop: "1vh",
        }}
        onClick={handleSeeOptionsClick}
      >
        Wall Choices
      </button>

      {isOptionsModalVisible && (
        <>
          <div
            style={{
              position: "fixed",
              top: 0,
              left: 0,
              width: "100vw",
              height: "100vh",
              backgroundColor: "rgba(0, 0, 0, .8)",
              zIndex: 1000,
            }}
          />
          <div
            className="popupwindow"
            style={{
              position: "fixed",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              backgroundColor: "#fff",
              padding: "1em",
              zIndex: 1001,
              display: "flex",
              flexDirection: "row",
              height: "90vh",
              width: "90vw",
              justifyContent: "center",
              alignItems: "end",
            }}
          >
            {notToday && (
              <button
                className="navbutton"
                style={{
                  position: "absolute",
                  top: "1%",
                  right: "1%",
                  cursor: "pointer",
                }}
                onClick={handleCloseModal}
              >
                Close
              </button>
            )}
            <div style={{ height: "100%", width: "100%", overflow: "visible" }}>
              <div
                style={{
                  height: "100%",
                  width: "100%",
                  backgroundImage: `url(${angleImages[selectedAngleOption]})`,
                  backgroundSize:
                    selectedAngleOption.toString() === "1"
                      ? "59% 100%"
                      : selectedAngleOption.toString() === "3"
                        ? "60% 100%"
                        : selectedAngleOption.toString() === "4"
                          ? "63% 100%"
                          : "61% 100%",
                  backgroundPosition: "center",
                  backgroundRepeat: "no-repeat",
                  display: "grid",
                  gridTemplateColumns: "repeat(50, 1fr)",
                  gridTemplateRows: "repeat(50, 1fr)",
                }}
              >
                <>{renderGridButtons(selectedAngleOption)}</>

                <div
                  style={{ gridColumn: "21 / span 10", gridRow: "1 / span 3" }}
                >
                  <AngledDoorHardware
                    selectedDoorHardware={selectedDoorHardware}
                    setSelectedDoorHardware={setSelectedDoorHardware}
                    setShowDoorHardware={setShowDoorHardware}
                    setFieldsWithRedBorder={setFieldsWithRedBorder}
                    doorWidth={doorStraightWidth}
                    handleShowDoorHardwareClick={handleShowDoorHardwareClick}
                    SSHinges={SSHinges}
                    handleSSHingesOptionChange={handleSSHingesOptionChange}
                    redBorderFields={redBorderFields}
                    showDoorHardware={showDoorHardware}
                  />
                </div>

                {selectedAngleOption.toString() !== "7" &&
                  selectedAngleOption.toString() !== "9" &&
                  selectedAngleOption.toString() !== "10" && (
                    <>
                      <div
                        style={{
                          gridColumn:
                            selectedAngleOption.toString() === "4"
                              ? "8 / span 8"
                              : "6 / span 8",
                          gridRow:
                            selectedAngleOption.toString() === "2"
                              ? "35 / span 6"
                              : selectedAngleOption.toString() === "5"
                                ? "30 / span 6"
                                : selectedAngleOption.toString() === "6"
                                  ? "31 / span 6"
                                  : "36 / span 6",
                        }}
                      >
                        <LeftAngle
                          leftAngleFeet={leftAngleFeet}
                          leftAngleInches={leftAngleInches}
                          handleLeftAngleFeetChange={handleLeftAngleFeetChange}
                          handleLeftAngleInchesChange={
                            handleLeftAngleInchesChange
                          }
                          redBorderFields={redBorderFields}
                          leftSideMissingWallLength={leftSideMissingWallLength}
                          setLeftMissingWallLength={setLeftMissingWallLength}
                          setLeftSideMissingWallDiv={setLeftSideMissingWallDiv}
                          selectedAngleOption={selectedAngleOption}
                        />
                      </div>
                      {renderDiv(leftSideMissingWallDiv)}
                    </>
                  )}
                {selectedAngleOption.toString() !== "2" &&
                  selectedAngleOption.toString() !== "4" &&
                  selectedAngleOption.toString() !== "5" && (
                    <>
                      <div
                        style={{
                          gridColumn:
                            selectedAngleOption.toString() === "3"
                              ? "39 / span 8"
                              : selectedAngleOption.toString() === "7"
                                ? "39 / span 8"
                                : selectedAngleOption.toString() === "9"
                                  ? "36 / span 8"
                                  : "38 / span 8",
                          gridRow:
                            selectedAngleOption.toString() === "6"
                              ? "31 / span 6"
                              : selectedAngleOption.toString() === "9"
                                ? "34 / span 6"
                                : selectedAngleOption.toString() === "10"
                                  ? "30 / span 6"
                                  : "36 / span 6",
                        }}
                      >
                        <RightAngle
                          rightAngleFeet={rightAngleFeet}
                          handleRightAngleFeetChange={
                            handleRightAngleFeetChange
                          }
                          rightAngleInches={rightAngleInches}
                          handleRightAngleInchesChange={
                            handleRightAngleInchesChange
                          }
                          redBorderFields={redBorderFields}
                          rightSideMissingWallLength={
                            rightSideMissingWallLength
                          }
                          setRightMissingWallLength={setRightMissingWallLength}
                          setRightSideMissingWallDiv={
                            setRightSideMissingWallDiv
                          }
                          selectedAngleOption={selectedAngleOption}
                        />
                      </div>
                      {renderDiv(rightSideMissingWallDiv)}
                    </>
                  )}
              </div>
              {openSingleDrawing && (
                <SingleWallSelection
                  setOpenSingleDrawing={setOpenSingleDrawing}
                  dimension={dimension}
                  wallLengthsFeet={wallStraightLengthsFeet}
                  wallLengthsInches={wallStraightLengthsInches}
                  redBorderFields={redBorderFields}
                  index={Number(index)}
                  setWallLengthsFeet={setWallStraightLengthsFeet}
                  setWallLengthsInches={setWallStraightLengthsInches}
                  handleWallLengthFeetChange={handleWallLengthFeetChange}
                  wallLengthMessage={`${currentWallType} Length Feet`}
                  wallLengthInchesMessage={`${currentWallType} Length Inches`}
                  handleWallLengthInchesChange={handleWallLengthInchesChange}
                  doorWidth={doorStraightWidth}
                  setDoorWidth={setDoorStraightWidth}
                  handleDoorOptionChange={handleDoorOptionChange}
                  doorOption={doorStraightOption}
                  setDoorOption={setDoorStraightOption}
                  doorOptionMessage={`${currentWallType} Door Option`}
                  lastWallLengthUpdate={lastWallLengthUpdate}
                  setLastWallLengthUpdate={setLastWallLengthUpdate}
                  handleDoorWidthChange={handleDoorWidthChange}
                  setNewDoorWidth={setDoorStraightWidth}
                  redFieldMessage={`${currentWallType} Door Width`}
                  selectedSunRoomType={selectedSunRoomType}
                  setFieldsWithRedBorder={setFieldsWithRedBorder}
                  selectedPatioDoorOrientation={
                    selectedStraightPatioDoorOrientation
                  }
                  selectedSwingDoorOrientation={
                    selectedStraightSwingDoorOrientation
                  }
                  setSelectedSwingDoorOrientation={
                    setSelectedStraightSwingDoorOrientation
                  }
                  setSelectedPatioDoorOrientation={
                    setSelectedStraightPatioDoorOrientation
                  }
                  initialSelectedCodes={selectedStraightCodes}
                  setNewSelectedCodes={setSelectedStraightCodes}
                  selectedButtonIndex={selectedStraightButtonIndex}
                  setSelectedButtonIndex={setSelectedStraightButtonIndex}
                  selectedImages={selectedStraightImages}
                  setSelectedImages={setSelectedStraightImages}
                  newIndex={newIndex}
                  handleCodeSelect={handleCodeSelect}
                  redPatioFieldMessage={`${currentWallType} Patio Door Orientation`}
                  redSwingFieldMessage={`${currentWallType} Swing Door Orientation`}
                  redWallFieldMessage={`${currentWallType} Wall Code`}
                  isFront={isFront}
                  isAngled={isAngled}
                  isScreenRoom={isScreenRoom}
                />
              )}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

AngledWalls.propTypes = {
  redBorderFields: PropTypes.array,
  selectedAngleOption: PropTypes.number,
  setOpenSingleDrawing: PropTypes.func,
  dimension: PropTypes.string,
  handleWallLengthFeetChange: PropTypes.func,
  handleWallLengthInchesChange: PropTypes.func,
  handleDoorOptionChange: PropTypes.func,
  lastWallLengthUpdate: PropTypes.number,
  setLastWallLengthUpdate: PropTypes.func,
  handleDoorWidthChange: PropTypes.func,
  setNewDoorWidth: PropTypes.func,
  redFieldMessage: PropTypes.array,
  selectedSunRoomType: PropTypes.string,
  setFieldsWithRedBorder: PropTypes.func,
  redPatioFieldMessage: PropTypes.array,
  redSwingFieldMessage: PropTypes.array,
  handleCodeSelect: PropTypes.func,
  rightSideMissingWallLength: PropTypes.array,
  setRightMissingWallLength: PropTypes.func,
  setRightSideMissingWallDiv: PropTypes.func,
  leftSideMissingWallLength: PropTypes.array,
  setLeftMissingWallLength: PropTypes.func,
  setLeftSideMissingWallDiv: PropTypes.func,
  leftSideMissingWallDiv: PropTypes.array,
  rightSideMissingWallDiv: PropTypes.array,
  selectedStraightCodes: PropTypes.array,
  setSelectedStraightCodes: PropTypes.func,
  selectedStraightButtonIndex: PropTypes.array,
  setSelectedStraightButtonIndex: PropTypes.func,
  selectedStraightImages: PropTypes.array,
  setSelectedStraightImages: PropTypes.func,
  selectedStraightPatioDoorOrientation: PropTypes.array,
  selectedStraightSwingDoorOrientation: PropTypes.array,
  setSelectedStraightSwingDoorOrientation: PropTypes.func,
  setSelectedStraightPatioDoorOrientation: PropTypes.func,
  doorStraightWidth: PropTypes.array,
  doorStraightOption: PropTypes.array,
  setDoorStraightWidth: PropTypes.func,
  setDoorStraightOption: PropTypes.func,
  setWallStraightLengthsFeet: PropTypes.func,
  setWallStraightLengthsInches: PropTypes.func,
  wallStraightLengthsFeet: PropTypes.array,
  wallStraightLengthsInches: PropTypes.array,
  selectedDoorHardware: PropTypes.string,
  setSelectedDoorHardware: PropTypes.func,
  SSHinges: PropTypes.string,
  handleSSHingesOptionChange: PropTypes.func,
  rightAngleFeet: PropTypes.string,
  setRightAngleFeet: PropTypes.func,
  rightAngleInches: PropTypes.string,
  setRightAngleInches: PropTypes.func,
  leftAngleFeet: PropTypes.string,
  setLeftAngleFeet: PropTypes.func,
  leftAngleInches: PropTypes.string,
  setLeftAngleInches: PropTypes.func,
  isScreenRoom: PropTypes.bool,
};

export default AngledWalls;
