import React from "react";
import PropTypes from "prop-types";

const UnprocessedRow = ({ unprocess, reformatDateSent }) => {
  const { date, time } = reformatDateSent(unprocess.date);

  return (
    <tr style={{ width: "100%" }}>
      <td>{unprocess.folderName}</td>
      <td>{unprocess.lastName ? unprocess.lastName.toUpperCase() : ""}</td>
      <td>{unprocess.firstName ? unprocess.firstName.toUpperCase() : ""}</td>
      <td>{unprocess.sentBy ? unprocess.sentBy.toUpperCase() : ""}</td>
      <td>
        {date}
        <br />
        Time Stamp:
        <br />
        {time}
      </td>
      <td>{unprocess.fileName}</td>
    </tr>
  );
};

UnprocessedRow.propTypes = {
  unprocess: PropTypes.object,
  reformatDateSent: PropTypes.func,
};

export default UnprocessedRow;
