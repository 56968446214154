import React from "react";
import PropTypes from "prop-types";

function ExistDoorWidth({
  redBorderFields,
  index,
  doorWidth,
  handleDoorWidthChange,
  wallLengthsFeet,
  setNewDoorWidth,
  redFieldMessage,
  selectedSunRoomType,
}) {
  return (
    <label
      className="popupwindow"
      style={{
        width: "9vw",
        alignSelf: "center",
        height: "8vh",
        color: "black",
        fontSize: "1.4vh",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <h6
        className={
          "headers " +
          (redBorderFields.includes(`${redFieldMessage}`)
            ? "redBackground"
            : "greenBackground")
        }
      >
        <span className="header-grid-location">
          <span className="redasterisk">&#10033;</span>
          Door width
        </span>
      </h6>
      <select
        style={{
          cursor: "pointer",
          width: "fit-content",
          fontSize: "1.4vh",
          alignSelf: "center",
          border: "1px solid black",
        }}
        value={doorWidth[index]}
        onChange={(e) =>
          handleDoorWidthChange(
            e.target.value,
            index,
            doorWidth,
            setNewDoorWidth,
            redFieldMessage,
          )
        }
      >
        <option value="">Select...</option>
        <option value="3' Door">3&apos; Swing Door</option>
        {selectedSunRoomType === "Linear Screen" && (
          <option value="3.5' Door">3.5&apos; Swing Door</option>
        )}
        {selectedSunRoomType !== "Linear Screen" && (
          <>
            {wallLengthsFeet[index] >= 6 && (
              <option value="5' Patio Door">5&apos; Patio Door</option>
            )}
            {wallLengthsFeet[index] >= 7 && (
              <option value="6' Patio Door">6&apos; Patio Door</option>
            )}
            {wallLengthsFeet[index] >= 9 && (
              <option value="8' Patio Door">8&apos; Patio Door</option>
            )}
          </>
        )}
      </select>
    </label>
  );
}

ExistDoorWidth.propTypes = {
  redBorderFields: PropTypes.array,
  index: PropTypes.number,
  doorWidth: PropTypes.array,
  handleDoorWidthChange: PropTypes.func,
  selectedPavilionLayoutOption: PropTypes.string,
  selectedBreezeWay: PropTypes.string,
  wallLengthsFeet: PropTypes.array,
  selectedRoomStyle: PropTypes.string,
  selectedSunRoomType: PropTypes.string,
  isFront: PropTypes.bool,
  setNewDoorWidth: PropTypes.func,
  redFieldMessage: PropTypes.string,
};

export default ExistDoorWidth;
