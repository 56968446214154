import React, { useState, useEffect } from "react";
import ImageButton from "../../UniversalComponents/ImageButton";
import InfoIcon from "../../UniversalComponents/InfoIcon";
import Under_Soffit from "../UniversalViewingImages/UNDER-SOFFIT-MOUNT.jpg";
import Wall from "../UniversalViewingImages/WALL-MOUNT.jpg";
import Overhang_Removal from "../UniversalViewingImages/OVERHANG-REMOVAL.jpg";
import Fascia from "../UniversalViewingImages/fascia-mount.jpg";
import Saddle from "../UniversalViewingImages/SADDLE-DETAIL.jpg";
import PropTypes from "prop-types";
import NumericInputWithButtons from "../NumericInput";

const RoofMount = ({
  handleMountChange,
  handleStepDownChange,
  handleFasciaInchesOption,
  redBorderFields,
  setFieldsWithRedBorder,
  isInsidePavilion,
  selectedAttachedOption,
  selectedRoomStyle,
  isInsideSunroom,
}) => {
  const [selectedMountOption, setSelectedMountOption] = useState("");
  const [inchesRoofMountValue, setInchesRoofMountValue] = useState("");
  const [feetRoofMountValue, setFeetRoofMountValue] = useState("");
  const [soffitDepthValue, setSoffitDepthValue] = useState("");
  const images = [Fascia, Under_Soffit, Wall, Overhang_Removal];
  const images2 = [Fascia, Under_Soffit, Wall, Overhang_Removal, Saddle];
  const messages = ["Fascia", "Under Soffit", "Wall", "Overhang Removal"];
  const messages2 = [
    "Fascia",
    "Under Soffit",
    "Wall",
    "Overhang Removal",
    "Saddle",
  ];

  useEffect(() => {
    if (selectedAttachedOption !== "attached") {
      handleMountChange("");
      handleStepDownChange("");
      handleFasciaInchesOption("");
      setSelectedMountOption("");
      setInchesRoofMountValue("");
      setFeetRoofMountValue("");
      setSoffitDepthValue("");
    }
  }, [
    selectedAttachedOption,
    handleMountChange,
    handleStepDownChange,
    handleFasciaInchesOption,
  ]);

  const handleFasciaInches = (pickedOption) => {
    let input = pickedOption;
    input = input.replace(/[^0-9]/g, "");

    const isFasciaSelected = selectedMountOption === "A";

    if (isFasciaSelected) {
      const newValue = input.length > 0 ? input : "0";
      const finalValue = parseInt(newValue, 10) > 9999 ? "9999" : newValue;

      setSoffitDepthValue(finalValue);
      handleFasciaInchesOption(finalValue, selectedMountOption);
    } else {
      setSoffitDepthValue("");
      handleFasciaInchesOption("", selectedMountOption);
    }

    const updatedRedBorderFields = redBorderFields.includes("fascia inches")
      ? input
        ? redBorderFields.filter((field) => field !== "fascia inches")
        : redBorderFields
      : input
        ? [...redBorderFields]
        : [...redBorderFields, "fascia inches"];

    setFieldsWithRedBorder(updatedRedBorderFields);
  };

  const handleMountOptionChange = (pickedoption) => {
    setSelectedMountOption(pickedoption);

    if (pickedoption !== "A") {
      setSoffitDepthValue("");
    }

    const updatedRedBorderFields = redBorderFields.includes("pick a mount")
      ? pickedoption
        ? redBorderFields.filter((field) => field !== "pick a mount")
        : redBorderFields
      : pickedoption
        ? redBorderFields
        : [...redBorderFields, "pick a mount"];

    setFieldsWithRedBorder(updatedRedBorderFields);
    handleMountChange(pickedoption);
  };

  const handleInchesRoofMountChange = (event) => {
    let input = event.target.value;
    input = input.replace(/[^0-9]/g, ""); // Updated to remove periods

    const numericInput = parseInt(input, 10); // Parse input as integer
    const isValidNumber = !isNaN(numericInput) && numericInput < 12;

    if (input !== "" && !isValidNumber) {
      if (numericInput >= 12) {
        input = "11"; // Updated to set to the max integer value
      } else {
        input = numericInput.toString();
      }
    }

    setInchesRoofMountValue(input);

    const updatedRedBorderFields = redBorderFields.includes("stepdown inches")
      ? input
        ? redBorderFields.filter((field) => field !== "stepdown inches")
        : redBorderFields
      : input
        ? redBorderFields
        : [...redBorderFields, "stepdown inches"];

    setFieldsWithRedBorder(updatedRedBorderFields);
    handleStepDownChange(
      feetRoofMountValue === "" ? "0" : feetRoofMountValue,
      input === "" ? "0" : input,
    );
  };

  const handleFeetRoofMountChange = (event) => {
    let input = event.target.value.replace(/\D/g, "").slice(0, 3);
    input = input === "" ? "" : input;
    setFeetRoofMountValue(input);

    const updatedRedBorderFields = redBorderFields.includes("stepdown feet")
      ? input
        ? redBorderFields.filter((field) => field !== "stepdown feet")
        : redBorderFields
      : input
        ? redBorderFields
        : [...redBorderFields, "stepdown feet"];

    setFieldsWithRedBorder(updatedRedBorderFields);
    handleStepDownChange(
      input === "" ? "0" : input,
      inchesRoofMountValue === "" ? "0" : inchesRoofMountValue,
    );
  };

  useEffect(() => {
    if (feetRoofMountValue === "0" || inchesRoofMountValue === "0") {
      handleStepDownChange(feetRoofMountValue, inchesRoofMountValue);
    }
  }, [feetRoofMountValue, inchesRoofMountValue, handleStepDownChange]);

  return (
    <div className="mount-stepdown-soffit">
      {selectedRoomStyle !== "Existing Roof" && (
        <>
          <div className="popupwindow mount-stuff">
            <h6
              className={
                "headers " +
                (redBorderFields.includes("pick a mount")
                  ? "redBackground"
                  : "greenBackground")
              }
            >
              {!isInsidePavilion && !isInsideSunroom && (
                <ImageButton images={images} messages={messages} />
              )}
              {(isInsidePavilion || isInsideSunroom) && (
                <ImageButton images={images2} messages={messages2} />
              )}
              <span className="header-grid-location">
                <span className="redasterisk">&#10033;</span>
                Roof Mount
              </span>
            </h6>
            <div className="clickable-words">
              <div
                className={`clickable-word ${selectedMountOption === "A" ? "clicked" : ""}`}
                onClick={() => handleMountOptionChange("A")}
              >
                Fascia
              </div>
              <div
                className={`clickable-word ${selectedMountOption === "U" ? "clicked" : ""}`}
                onClick={() => handleMountOptionChange("U")}
              >
                Under&nbsp;Soffit
              </div>
              &nbsp;
              {(isInsidePavilion || isInsideSunroom) && (
                <div
                  className={`clickable-word ${selectedMountOption === "S" ? "clicked" : ""}`}
                  onClick={() => handleMountOptionChange("S")}
                >
                  Saddle
                </div>
              )}
            </div>
            <div className="clickable-words">
              <div
                className={`clickable-word ${selectedMountOption === "W" ? "clicked" : ""}`}
                onClick={() => handleMountOptionChange("W")}
              >
                Wall
              </div>
              <div
                className={`clickable-word ${selectedMountOption === "OR" ? "clicked" : ""}`}
                onClick={() => handleMountOptionChange("OR")}
              >
                Overhang&nbsp;Removal
              </div>
            </div>
          </div>
          <div>
            {selectedMountOption === "A" && (
              <div className="popupwindow soffitdepth-stuff" id="soffitdepth">
                <h6 className="greenBackground headers">
                  <span className="header-grid-location">
                    <span className="redasterisk">&#10033;</span>
                    Soffit Depth
                  </span>
                  <InfoIcon defaultMessage="Soffit Depth refers to depth of the soffit.<br/>Only for the Fascia mount." />
                </h6>
                <div className="input-container">
                  <div className="fontsizeforinputsrow">
                    <span className="fontsizeforinputsrow">
                      <span className="redasterisk">&#10033;</span>
                      Inch:
                    </span>

                    <NumericInputWithButtons
                      min={0}
                      max={24} // Set your desired max value
                      step={1}
                      value={soffitDepthValue}
                      onChange={(newValue) =>
                        handleFasciaInches(newValue.toString())
                      }
                      id="soffitdepth"
                      className={
                        "soffitinput " +
                        (redBorderFields.includes("fascia inches")
                          ? "redBackground"
                          : "whiteBackground")
                      }
                    />
                  </div>
                </div>
              </div>
            )}
          </div>
        </>
      )}
      <div className="popupwindow stepdown-stuff">
        <div>
          <h6 className="greenBackground headers">
            <span className="header-grid-location">
              <span className="redasterisk">&#10033;</span>
              Step Down Distance
            </span>
            <InfoIcon defaultMessage="Step Down distance from existing structure into Temo product." />
          </h6>
          <div className="fontsizeforinputsrow">
            <div className="fontsizeforinputsrow">
              <span className="fontsizeforinputsrow">
                <span className="redasterisk">&#10033;</span>
                Feet:
              </span>
              <input
                id="stepdownfeet"
                className={
                  "personalinputwidths " +
                  (redBorderFields.includes("stepdown feet")
                    ? "redBackground"
                    : "whiteBackground")
                }
                value={feetRoofMountValue}
                onChange={(event) => handleFeetRoofMountChange(event)}
                maxLength={3}
                pattern="\d*"
              />
            </div>
            <div className="fontsizeforinputsrow">
              <span className="fontsizeforinputsrow">
                <span className="redasterisk">&#10033;</span>
                Inch:
              </span>
              <input
                id="stepdowninches"
                className={
                  "personalinputwidths " +
                  (redBorderFields.includes("stepdown inches")
                    ? "redBackground"
                    : "whiteBackground")
                }
                value={inchesRoofMountValue}
                onChange={(event) => handleInchesRoofMountChange(event)}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

RoofMount.propTypes = {
  handleMountChange: PropTypes.func,
  handleStepDownChange: PropTypes.func,
  handleFasciaInchesOption: PropTypes.func,
  redBorderFields: PropTypes.array,
  setFieldsWithRedBorder: PropTypes.func,
  isInsidePavilion: PropTypes.bool,
  selectedAttachedOption: PropTypes.string,
  selectedRoomStyle: PropTypes.string,
  isInsideSunroom: PropTypes.bool,
};

export default RoofMount;
