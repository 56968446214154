import React from "react";
import PropTypes from "prop-types";

const InfoModal = ({ isOpen, onClose }) => {
  return (
    <div
      style={{
        gridColumn: "3",
        gridRow: "1",
        display: "flex",
        justifySelf: "start",
        alignSelf: "end",
        paddingTop: "1%",
        width: "7vw",
        height: "fit-content",
        marginLeft: "-3vw",
      }}
    >
      {isOpen && (
        <div className="modal">
          <div
            className="popupwindow"
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              position: "relative",
              paddingBottom: "1%",
              width: "80vw",
              height: "90vh",
            }}
          >
            <h2
              className="greenBackground"
              style={{
                marginBottom: "2vh",
                width: "100%",
                height: "fit-content",
                padding: "1%",
                color: "White",
              }}
            >
              Welcome to the TEMO Web Ordering Portal!<br></br> Here&apos;s a
              brief overview of how to navigate and utilize the features
              available to you.
            </h2>
            <div
              style={{
                width: "fit-content",
                height: "fit-content",
                border: "solid white 2px",
                position: "absolute",
                top: "1%",
                right: "0",
                padding: "2px",
              }}
            >
              <button
                type="button"
                className="showpdfbuttons"
                style={{ cursor: "pointer", width: "auto", height: "auto" }}
                onClick={onClose}
              >
                X
              </button>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-evenly",
                width: "100%",
                overflow: "auto",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "50%",
                  alignItems: "start",
                  textAlign: "left",
                }}
              >
                <h5>
                  <b>Welcome Page and Navigation</b>
                </h5>
                <ol className="dash-list">
                  <li>
                    Welcome Page
                    <p>
                      After logging in, you will see the welcome page. To return
                      here at any time, simply click the “TEMO” icon at the top
                      of the page.
                    </p>
                  </li>
                  <li>
                    Make an Order
                    <p>
                      Click the “Make an order” button to start placing a new
                      order.
                    </p>
                  </li>
                  <li>
                    Current Orders
                    <p>
                      Press the “Current Orders” button to view all your
                      recently placed orders until they are shipped.
                    </p>
                  </li>
                  <li>
                    Order History
                    <p>
                      Click the “Order history” button to see all orders that
                      have been shipped to you.
                    </p>
                  </li>
                  <li>
                    Log Out
                    <p>Click “Log Out” to exit the portal.</p>
                  </li>
                  <li>
                    How To
                    <p>
                      Click the “How To” button to view this information again.
                    </p>
                  </li>
                </ol>
                <h5>
                  <b>Placing an Order</b>
                </h5>
                <ol className="dash-list">
                  <li>
                    Make an Order
                    <p>
                      When you click “Make an order”, you will choose the type
                      of TEMO product you wish to order. Currently, you can
                      select from:
                    </p>
                    <ol className="dash-list">
                      <li>
                        3 5/8&quot; Sunrooms
                        <p>
                          Vista View, Premier View, Upper Deck, and Solarium.
                        </p>
                      </li>
                      <li>
                        2&quot; Sunrooms
                        <p>Value View, Vinyl View, Linear Screen</p>
                      </li>
                      <li>
                        Pergolas
                        <p>Operable, Fixed, and Motor City.</p>
                      </li>
                      <li>
                        Pavilions
                        <p>St Thomas, Santa Fe, and TEMO Panel.</p>
                      </li>
                    </ol>
                  </li>
                </ol>
                <h5>
                  <b>Viewing Current Orders</b>
                </h5>
                <ol className="dash-list">
                  <li>
                    Confirmed<p>Orders that you have confirmed.</p>
                  </li>
                  <li>
                    Processed
                    <p>
                      Orders that have been drawn up but are not yet confirmed.
                    </p>
                  </li>
                  <li>
                    Unprocessed
                    <p>Orders in the queue that have not been drawn.</p>
                  </li>
                  <li>
                    Initial Orders<p>Printouts of your unprocessed orders.</p>
                  </li>
                  <li>
                    Show PDFs
                    <p>
                      In the Confirmed and Processed tabs, click the “Show PDFs”
                      button to view all attached PDFs for an order, such as:
                      <ol className="dash-list">
                        <li>BOM</li>
                        <li>Parts List</li>
                        <li>Elevation</li>
                        <li>Cross-Section</li>
                        <li>Deck</li>
                      </ol>
                    </p>
                  </li>
                  <li>
                    Show File
                    <p>
                      In the Initial Orders tab, click the “Show File” button to
                      open your printout.
                    </p>
                  </li>
                </ol>
                <h5>Viewing Order History</h5>
                <ol className="dash-list">
                  <li>
                    Order History Page
                    <p>
                      Similar to the Current Orders page, this page shows your
                      shipped orders and includes:
                    </p>
                  </li>
                  <li>
                    Pagination
                    <p>
                      Navigate through pages using the previous and next page
                      buttons.
                    </p>
                  </li>
                  <li>
                    Page Size
                    <p>
                      Set how many orders you want to see at once, ranging from
                      200 to 2000, using the page size dropdown.
                    </p>
                  </li>
                  <li>
                    Search Bar
                    <p>
                      Search for orders based on available information by
                      entering details in the search bar and{" "}
                      <u>clicking the search button</u>. For example, entering
                      “B” in the Job Name search and “2024” in the Date Shipped
                      column will filter results accordingly. The results will
                      be every order that was in the year 2024 with a “B” in the
                      job name.
                    </p>
                  </li>
                  <li>
                    Show PDFs
                    <p>
                      Click this button to view attached files for each order.
                    </p>
                  </li>
                  <li className="star-item">
                    Note that not all orders may have saved files due to when
                    the file was put into the system.
                  </li>
                </ol>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

InfoModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
};

export default InfoModal;
