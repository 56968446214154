import React from "react";
import InfoIcon from "../UniversalComponents/InfoIcon";
import PropTypes from "prop-types";

const AngledYesNo = ({
  handleSelectedAngled,
  redBorderFields,
  setFieldsWithRedBorder,
  setSelectedAngled,
  selectedAngled,
}) => {
  const handleAngleOptionChange = (selected) => {
    setSelectedAngled(selected);
    const updatedRedBorderFields = redBorderFields.includes("Angled Option")
      ? selected
        ? redBorderFields.filter((field) => field !== "Angled Option")
        : redBorderFields
      : selected
        ? redBorderFields
        : [...redBorderFields, "Angled Option"];

    setFieldsWithRedBorder(updatedRedBorderFields);
    handleSelectedAngled(selected);
  };

  return (
    <div
      className="popupwindow"
      style={{ flex: 1, width: "49%", marginBottom: "1%", marginLeft: "1%" }}
    >
      <h6
        className={
          "headers " +
          (redBorderFields.includes("Angled Option")
            ? "redBackground"
            : "greenBackground")
        }
      >
        <InfoIcon defaultMessage="Only used for TEMO walls with 45&deg; angles" />
        <span className="header-grid-location">
          <span className="redasterisk">&#10033;</span>
          45&deg; Corners
        </span>
      </h6>
      <div className="clickable-words">
        <div
          className={`clickable-word ${selectedAngled === "Yes" ? "clicked" : ""}`}
          onClick={() => handleAngleOptionChange("Yes")}
        >
          Yes
        </div>
        <div
          className={`clickable-word ${selectedAngled === "No" ? "clicked" : ""}`}
          onClick={() => handleAngleOptionChange("No")}
        >
          No
        </div>
      </div>
    </div>
  );
};

AngledYesNo.propTypes = {
  handleSelectedAngled: PropTypes.func,
  redBorderFields: PropTypes.array,
  setFieldsWithRedBorder: PropTypes.func,
  setSelectedAngled: PropTypes.func,
  selectedAngled: PropTypes.string,
};

export default AngledYesNo;
