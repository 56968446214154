import React, { useState } from "react";
import PropTypes from "prop-types";

const DeckDimensions = ({
  handleDeckDimensionChange,
  redBorderFields,
  setFieldsWithRedBorder,
}) => {
  const [deckWidthInchesOption, setdeckWidthInchesOption] = useState("");
  const [deckLengthInchesOption, setdeckLengthInchesOption] = useState("");
  const [deckWidthFeetOption, setdeckWidthFeetOption] = useState("");
  const [deckLengthFeetOption, setdeckLengthFeetOption] = useState("");

  const handleDeckWidthInchesChange = (event) => {
    let input = event.target.value.replace(/[^\d.]/g, "").slice(0, 5);
    input =
      input === ""
        ? ""
        : Math.min(Math.max(parseFloat(input), 0), 11.99).toFixed(2);
    input = parseFloat(input).toString();

    setdeckWidthInchesOption(input);

    const updatedRedBorderFields = redBorderFields.includes("deck width inches")
      ? input
        ? redBorderFields.filter((field) => field !== "deck width inches")
        : redBorderFields
      : input
        ? redBorderFields
        : [...redBorderFields, "deck width inches"];

    setFieldsWithRedBorder(updatedRedBorderFields);
    handleDeckDimensionChange(deckWidthFeetOption, input, "deck width");
  };

  const handleDeckWidthFeetChange = (event) => {
    const input = event.target.value.replace(/\D/g, "").slice(0, 3);
    setdeckWidthFeetOption(input);
    const updatedRedBorderFields = redBorderFields.includes("deck width feet")
      ? input
        ? redBorderFields.filter((field) => field !== "deck width feet")
        : redBorderFields
      : input
        ? redBorderFields
        : [...redBorderFields, "deck width feet"];

    setFieldsWithRedBorder(updatedRedBorderFields);
    handleDeckDimensionChange(input, deckWidthInchesOption, "deck width");
  };

  const handleDeckLengthInchesChange = (event) => {
    let input = event.target.value.replace(/\D/g, "").slice(0, 2);
    input =
      input === "" ? "" : Math.min(Math.max(parseInt(input), 0), 11).toString();
    setdeckLengthInchesOption(input);
    const updatedRedBorderFields = redBorderFields.includes(
      "deck length inches",
    )
      ? input
        ? redBorderFields.filter((field) => field !== "deck length inches")
        : redBorderFields
      : input
        ? redBorderFields
        : [...redBorderFields, "deck length inches"];

    setFieldsWithRedBorder(updatedRedBorderFields);
    handleDeckDimensionChange(deckLengthFeetOption, input, "deck length");
  };

  const handleDeckLengthFeetChange = (event) => {
    const input = event.target.value.replace(/\D/g, "").slice(0, 3);
    setdeckLengthFeetOption(input);
    const updatedRedBorderFields = redBorderFields.includes("deck length feet")
      ? input
        ? redBorderFields.filter((field) => field !== "deck length feet")
        : redBorderFields
      : input
        ? redBorderFields
        : [...redBorderFields, "deck length feet"];

    setFieldsWithRedBorder(updatedRedBorderFields);
    handleDeckDimensionChange(input, deckLengthInchesOption, "deck length");
  };

  return (
    <div className="load-selection" style={{ marginBottom: "1%" }}>
      <div className="popupwindow lengthdim">
        <h6 className="greenBackground headers">
          <span className="header-grid-location">
            <span className="redasterisk">&#10033;</span>
            Deck Projection
          </span>
        </h6>

        <div className="fontsizeforinputsrow" id="rooflength">
          <span className="fontsizeforinputsrow">
            <span className="fontsizeforinputsrow">
              <span className="redasterisk">&#10033;</span>
              Feet:&nbsp;
            </span>
            <input
              id="decklengthfeet"
              className={
                redBorderFields.includes("deck length feet")
                  ? "redBackground"
                  : "whiteBackground"
              }
              value={deckLengthFeetOption}
              onChange={handleDeckLengthFeetChange}
              maxLength={3}
              pattern="\d*"
              style={{ width: "50%" }}
            />
          </span>
          <span className="fontsizeforinputsrow">
            <span className="fontsizeforinputsrow">
              <span className="redasterisk">&#10033;</span>
              Inch:&nbsp;
            </span>
            <input
              id="decklengthinches"
              className={
                redBorderFields.includes("deck length inches")
                  ? "redBackground"
                  : "whiteBackground"
              }
              value={deckLengthInchesOption}
              onChange={handleDeckLengthInchesChange}
              maxLength={3}
              pattern="\d*"
              style={{ width: "50%" }}
            />
          </span>
        </div>
      </div>
      <div className="popupwindow widthdim">
        <h6 className="greenBackground headers">
          <span className="header-grid-location">
            <span className="redasterisk">&#10033;</span>
            Deck Width
          </span>
        </h6>

        <div id="roofwidth" className="fontsizeforinputsrow">
          <span className="fontsizeforinputsrow">
            <span className="fontsizeforinputsrow">
              <span className="redasterisk">&#10033;</span>
              Feet:&nbsp;
            </span>
            <input
              id="deckwidthfeet"
              className={
                redBorderFields.includes("deck width feet")
                  ? "redBackground"
                  : "whiteBackground"
              }
              value={deckWidthFeetOption}
              onChange={handleDeckWidthFeetChange}
              maxLength={3}
              pattern="\d*"
              style={{ width: "50%" }}
            />
          </span>
          <span className="fontsizeforinputsrow">
            <span className="fontsizeforinputsrow">
              <span className="redasterisk">&#10033;</span>
              Inch:&nbsp;
            </span>
            <input
              id="deckwidthinches"
              className={
                redBorderFields.includes("deck width inches")
                  ? "redBackground"
                  : "whiteBackground"
              }
              value={deckWidthInchesOption}
              onChange={handleDeckWidthInchesChange}
              maxLength={3}
              pattern="\d*"
              style={{ width: "50%" }}
            />
          </span>
        </div>
      </div>
    </div>
  );
};

DeckDimensions.propTypes = {
  handleDeckDimensionChange: PropTypes.func,
  redBorderFields: PropTypes.array,
  setFieldsWithRedBorder: PropTypes.func,
};

export default DeckDimensions;
