import React from "react";
const SpareParts = () => {
  return (
    <div>
      <div>
        <h1>Under Construction</h1>
      </div>
    </div>
  );
};

export default SpareParts;
