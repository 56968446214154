import React, { useState } from "react";
import InfoIcon from "../UniversalComponents/InfoIcon";
import PropTypes from "prop-types";

const SunRoomType = ({
  handleSelectedSunRoomType,
  redBorderFields,
  setFieldsWithRedBorder,
  isScreenRoom,
}) => {
  const [selectedSunroomType, setSelectedSunroomTypeChange] = useState("");

  const handleSunroomTypeChange = (selected) => {
    setSelectedSunroomTypeChange(selected);
    const updatedRedBorderFields = redBorderFields.includes("Sunroom Type")
      ? selected
        ? redBorderFields.filter((field) => field !== "Sunroom Type")
        : redBorderFields
      : selected
        ? redBorderFields
        : [...redBorderFields, "Sunroom Type"];

    setFieldsWithRedBorder(updatedRedBorderFields);
    handleSelectedSunRoomType(selected);
  };

  return (
    <div className="popupwindow" style={{ width: "100%", marginBottom: "1%" }}>
      <h6
        className={
          "headers " +
          (redBorderFields.includes("Sunroom Type")
            ? "redBackground"
            : "greenBackground")
        }
      >
        <InfoIcon
          defaultMessage={
            !isScreenRoom
              ? "Vista View and Solarium are 18.5 - 81.5<br/>Premier View is 6.0 - 86.375<br/>Upper Deck is 30.5 - 93.5"
              : "2” Sunrooms are 14.0 - 86.0"
          }
        />
        <span className="header-grid-location">
          <span className="redasterisk">&#10033;</span>
          Sunroom Type
        </span>
      </h6>
      {!isScreenRoom && (
        <>
          <div className="clickable-words">
            <div
              className={`clickable-word ${selectedSunroomType === "Upper Deck" ? "clicked" : ""}`}
              onClick={() => handleSunroomTypeChange("Upper Deck")}
            >
              Upper Deck
            </div>
            <div
              className={`clickable-word ${selectedSunroomType === "Vista View" ? "clicked" : ""}`}
              onClick={() => handleSunroomTypeChange("Vista View")}
            >
              Vista View
            </div>
          </div>

          <div>
            <div
              className={`clickable-word ${selectedSunroomType === "Solarium" ? "clicked" : ""}`}
              onClick={() => handleSunroomTypeChange("Solarium")}
            >
              Solarium
            </div>
            <div
              className={`clickable-word ${selectedSunroomType === "Premier View" ? "clicked" : ""}`}
              style={{ marginLeft: "1%" }}
              onClick={() => handleSunroomTypeChange("Premier View")}
            >
              Premier View
            </div>
          </div>
        </>
      )}
      {isScreenRoom && (
        <>
          <div className="clickable-words">
            <div
              className={`clickable-word ${selectedSunroomType === "Value View" ? "clicked" : ""}`}
              onClick={() => handleSunroomTypeChange("Value View")}
            >
              Value View
            </div>
            <div
              className={`clickable-word ${selectedSunroomType === "Vinyl View" ? "clicked" : ""}`}
              onClick={() => handleSunroomTypeChange("Vinyl View")}
            >
              Vinyl View
            </div>
          </div>
          <div>
            <div
              className={`clickable-word ${selectedSunroomType === "Linear Screen" ? "clicked" : ""}`}
              onClick={() => handleSunroomTypeChange("Linear Screen")}
            >
              Linear Screen
            </div>
          </div>
        </>
      )}
    </div>
  );
};

SunRoomType.propTypes = {
  handleSelectedSunRoomType: PropTypes.func,
  redBorderFields: PropTypes.array,
  setFieldsWithRedBorder: PropTypes.func,
  isSunroom: PropTypes.bool,
  isScreenRoom: PropTypes.bool,
};

export default SunRoomType;
