import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

const NoSkyLights = ({ lengthFeet }) => {
  const [greaterorlessthan, setGreaterorlessthan] = useState("");

  useEffect(() => {
    if (lengthFeet < 7) {
      setGreaterorlessthan(">= 7");
    }
    if (lengthFeet > 15) {
      setGreaterorlessthan("<= 15");
    }
  }, [lengthFeet]);

  return (
    <div className="popupwindow skylightselection">
      <h6 className="headers greenBackground">
        <span
          style={{ marginBottom: ".5em", marginTop: ".5em" }}
          className="header-grid-location"
        >
          <span className="redasterisk">&#10033;</span>
          Skyview Option Not Allowed
        </span>
      </h6>
      <div className="clickable-words">
        <div className="clickable-word">
          Projection must be {greaterorlessthan}
        </div>
      </div>
    </div>
  );
};

NoSkyLights.propTypes = {
  lengthFeet: PropTypes.number,
};

export default NoSkyLights;
