import React, { useCallback, useEffect } from "react";
import PropTypes from "prop-types";
import VerticalLine from "./LincolnLogPhotos/Vertical-Line.jpg";
import BlackSquare from "./LincolnLogPhotos/Black_square.jpg";
import SingleWallSelection from "../SingleWallSelection";
import ClickMeRight from "./LincolnLogPhotos/CLICKLEFT.jpg";

const RightSide = ({
  redBorderFields,
  dimension,
  setRightSideDivs,
  setRightSidePickMeDivs,
  wallERRLengthsFeet,
  wallERRLengthsInches,
  setWallERRLengthsFeet,
  setWallERRLengthsInches,
  handleWallLengthFeetChange,
  setRightCavities,
  handleWallLengthInchesChange,
  doorERRWidth,
  setDoorERRWidth,
  handleDoorOptionChange,
  doorERROption,
  setDoorERROption,
  lastWallLengthUpdate,
  setLastWallLengthUpdate,
  handleDoorWidthChange,
  selectedSunRoomType,
  setFieldsWithRedBorder,
  selectedERRPatioDoorOrientation,
  selectedERRSwingDoorOrientation,
  setSelectedERRSwingDoorOrientation,
  setSelectedERRPatioDoorOrientation,
  setSelectedERRCodes,
  selectedERRCodes,
  selectedERRButtonIndex,
  setSelectedERRButtonIndex,
  selectedERRImages,
  setSelectedERRImages,
  handleCodeSelect,
  verticalRightLines,
  setVerticalRightLines,
  blackRightSquares,
  setBlackRightSquares,
  setRightPickMes,
  pickMeRightDivs,
  index,
  setIndex,
  openRightSingleDrawing,
  setOpenRightSingleDrawing,
  isScreenRoom,
  setSelectedERRLocations,
  selectedERRLocations,
  handleDoorLocationChange,
  selectedBreezeWay,
  selectedPavilionLayoutOption,
}) => {
  const openRightSidesModal = useCallback(
    (indexValue) => {
      setOpenRightSingleDrawing(true);
      setIndex(indexValue);
    },
    [setIndex, setOpenRightSingleDrawing],
  );

  const generateRightPickMeDivs = useCallback(
    (total) => {
      let newTotal = Number(total);
      return Array.from({ length: newTotal }, (_, index) => {
        let clickMeRightLength =
          selectedSunRoomType !== "Linear Screen"
            ? (wallERRLengthsFeet[index] || wallERRLengthsFeet[index] === 0) &&
              (wallERRLengthsInches[index] || wallERRLengthsInches[index] === 0)
              ? `${wallERRLengthsFeet[index]}' ${wallERRLengthsInches[index]}"`
              : ""
            : doorERROption[index] === "No"
              ? "No Door"
              : doorERROption[index] !== ""
                ? "Yes Door"
                : "";
        let clickMeRightImage = ClickMeRight;
        let clickMeRightDoorOption =
          selectedSunRoomType !== "Linear Screen"
            ? doorERROption[index] === "No"
              ? "No Door"
              : doorERRWidth[index]
            : doorERROption[index] === "No"
              ? ""
              : selectedERRLocations[index];
        let clickMeRightOrientation =
          doorERROption[index] === "No"
            ? ""
            : selectedERRPatioDoorOrientation[index] === ""
              ? selectedERRSwingDoorOrientation[index]
              : selectedERRPatioDoorOrientation[index];

        let clickMeRightCode =
          selectedSunRoomType !== "Linear Screen"
            ? selectedERRCodes[index]
            : (wallERRLengthsFeet[index] || wallERRLengthsFeet[index] === 0) &&
                (wallERRLengthsInches[index] ||
                  wallERRLengthsInches[index] === 0)
              ? `${wallERRLengthsFeet[index]}' ${wallERRLengthsInches[index]}"`
              : "";
        let values = [
          clickMeRightCode,
          clickMeRightLength,
          clickMeRightDoorOption,
          clickMeRightOrientation,
        ];
        let gridRow;
        switch (newTotal) {
          case 1:
            gridRow = "12 / span 6";
            break;
          case 2:
            gridRow = index === 0 ? "6 / span 4" : "18 / span 6";
            break;
          default:
            gridRow =
              index === 0
                ? "2 / span 6"
                : index === 1
                  ? "11 / span 6"
                  : "20 / span 6";
            break;
        }
        return {
          gridColumn: "30 / span 6",
          gridRow: `${gridRow}`,
          width: "20%",
          height: "100%",
          clickMeRightImage,
          values,
        };
      });
    },
    [
      wallERRLengthsFeet,
      wallERRLengthsInches,
      doorERROption,
      doorERRWidth,
      selectedERRPatioDoorOrientation,
      selectedERRSwingDoorOrientation,
      selectedSunRoomType,
      selectedERRCodes,
      selectedERRLocations,
    ],
  );

  const generateRightBlackSquares = (total) => {
    let newTotal = Number(total);
    return Array.from({ length: newTotal }, (_, index) => {
      let gridRow;
      switch (newTotal) {
        case 1:
          gridRow = "27 / span 2";
          break;
        case 2:
          gridRow = index === 0 ? "13 / span 2" : "27 / span 2";
          break;
        default:
          gridRow =
            index === 0
              ? "9 / span 2"
              : index === 1
                ? "18 / span 2"
                : "27 / span 2";
          break;
      }
      return {
        gridColumn: "28 / span 2",
        gridRow: gridRow,
        backgroundImage: BlackSquare,
        cursorPointer: false,
        width: "50%",
        height: "100%",
      };
    });
  };

  const handleRightCavities = useCallback(
    (value) => {
      setRightCavities(value);
      const generateRightVerticalLines = (total) => {
        let newTotal = Number(total);
        return Array.from({ length: newTotal }, (_, index) => {
          let gridRow;
          let height;
          switch (newTotal) {
            case 1:
              gridRow = "3 / span 23";
              height = "100%";
              break;
            case 2:
              gridRow = index === 0 ? "3 / span 9" : "16 / span 10";
              height = "100%";
              break;
            default:
              gridRow =
                index === 0
                  ? "3 / span 5"
                  : index === 1
                    ? "12 / span 5"
                    : "21 / span 5";
              height = "100%";
              break;
          }
          return {
            gridColumn: "28 / span 2",
            gridRow: gridRow,
            backgroundImage: VerticalLine,
            onClick: () => openRightSidesModal(index),
            cursorPointer: true,
            width: "20%",
            height: height,
            id: "right",
          };
        });
      };

      if (value >= 0 && value <= 3) {
        setVerticalRightLines(generateRightVerticalLines(value));
        setBlackRightSquares(generateRightBlackSquares(value));
        setRightPickMes(generateRightPickMeDivs(value));
      } else {
        setVerticalRightLines([]);
        setBlackRightSquares([]);
        setRightPickMes([]);
      }
    },
    [
      generateRightPickMeDivs,
      openRightSidesModal,
      setBlackRightSquares,
      setRightCavities,
      setRightPickMes,
      setVerticalRightLines,
    ],
  );

  useEffect(() => {
    if (Array.isArray(verticalRightLines) && verticalRightLines.length === 0) {
      handleRightCavities(1);
    }
  }, [verticalRightLines, handleRightCavities]);

  useEffect(() => {
    setRightSideDivs([...verticalRightLines, ...blackRightSquares]);
    setRightSidePickMeDivs(pickMeRightDivs);
  }, [
    verticalRightLines,
    blackRightSquares,
    setRightSideDivs,
    setRightSidePickMeDivs,
    pickMeRightDivs,
  ]);

  useEffect(() => {
    const newPickMeLeftDivs = generateRightPickMeDivs(
      verticalRightLines.length,
    );
    setRightPickMes(newPickMeLeftDivs);
  }, [
    doorERROption,
    doorERRWidth,
    selectedERRPatioDoorOrientation,
    selectedERRSwingDoorOrientation,
    selectedERRCodes,
    generateRightPickMeDivs,
    verticalRightLines.length,
    setRightPickMes,
  ]);

  return (
    <>
      <select onChange={(e) => handleRightCavities(e.target.value)}>
        <option value={1}>1 Cavity</option>
        <option value={2}>2 Cavities</option>
        <option value={3}>3 Cavities</option>
      </select>

      {openRightSingleDrawing && (
        <SingleWallSelection
          setOpenSingleDrawing={setOpenRightSingleDrawing}
          dimension={dimension}
          wallLengthsFeet={wallERRLengthsFeet}
          wallLengthsInches={wallERRLengthsInches}
          redBorderFields={redBorderFields}
          index={Number(index)}
          setWallLengthsFeet={setWallERRLengthsFeet}
          setWallLengthsInches={setWallERRLengthsInches}
          handleWallLengthFeetChange={handleWallLengthFeetChange}
          wallLengthMessage={`Right Wall Length Feet ${index + 1}`}
          wallLengthInchesMessage={`Right Wall Length Inches ${index + 1}`}
          handleWallLengthInchesChange={handleWallLengthInchesChange}
          doorWidth={doorERRWidth}
          setDoorWidth={setDoorERRWidth}
          handleDoorOptionChange={handleDoorOptionChange}
          doorOption={doorERROption}
          setDoorOption={setDoorERROption}
          doorOptionMessage={`Right Door Option ${index + 1}`}
          lastWallLengthUpdate={lastWallLengthUpdate}
          setLastWallLengthUpdate={setLastWallLengthUpdate}
          handleDoorWidthChange={handleDoorWidthChange}
          setNewDoorWidth={setDoorERRWidth}
          redFieldMessage={`Right Door Width ${index + 1}`}
          selectedSunRoomType={selectedSunRoomType}
          setFieldsWithRedBorder={setFieldsWithRedBorder}
          selectedPatioDoorOrientation={selectedERRPatioDoorOrientation}
          selectedSwingDoorOrientation={selectedERRSwingDoorOrientation}
          setSelectedSwingDoorOrientation={setSelectedERRSwingDoorOrientation}
          setSelectedPatioDoorOrientation={setSelectedERRPatioDoorOrientation}
          initialSelectedCodes={selectedERRCodes}
          setNewSelectedCodes={setSelectedERRCodes}
          selectedButtonIndex={selectedERRButtonIndex}
          setSelectedButtonIndex={setSelectedERRButtonIndex}
          selectedImages={selectedERRImages}
          setSelectedImages={setSelectedERRImages}
          newIndex={2}
          handleCodeSelect={handleCodeSelect}
          redPatioFieldMessage={`Right Patio Door Orientation ${index + 1}`}
          redSwingFieldMessage={`Right Swing Door Orientation ${index + 1}`}
          redWallFieldMessage={`Right Wall Code ${index + 1}`}
          isScreenRoom={isScreenRoom}
          setSelectedLocations={setSelectedERRLocations}
          selectedLocations={selectedERRLocations}
          handleDoorLocationChange={handleDoorLocationChange}
          selectedBreezeWay={selectedBreezeWay}
          selectedPavilionLayoutOption={selectedPavilionLayoutOption}
        />
      )}
    </>
  );
};

RightSide.propTypes = {
  redBorderFields: PropTypes.array,
  dimension: PropTypes.number,
  setRightSideDivs: PropTypes.func,
  wallERRLengthsFeet: PropTypes.array,
  wallERRLengthsInches: PropTypes.array,
  setWallERRLengthsFeet: PropTypes.func,
  setWallERRLengthsInches: PropTypes.func,
  handleWallLengthFeetChange: PropTypes.func,
  setRightCavities: PropTypes.func,
  handleWallLengthInchesChange: PropTypes.func,
  doorERRWidth: PropTypes.array,
  setDoorERRWidth: PropTypes.func,
  handleDoorOptionChange: PropTypes.func,
  doorERROption: PropTypes.array,
  setDoorERROption: PropTypes.func,
  lastWallLengthUpdate: PropTypes.number,
  setLastWallLengthUpdate: PropTypes.func,
  handleDoorWidthChange: PropTypes.func,
  selectedSunRoomType: PropTypes.string,
  setFieldsWithRedBorder: PropTypes.func,
  selectedERRPatioDoorOrientation: PropTypes.array,
  selectedERRSwingDoorOrientation: PropTypes.array,
  setSelectedERRSwingDoorOrientation: PropTypes.func,
  setSelectedERRPatioDoorOrientation: PropTypes.func,
  setSelectedERRCodes: PropTypes.func,
  selectedERRCodes: PropTypes.array,
  selectedERRButtonIndex: PropTypes.array,
  setSelectedERRButtonIndex: PropTypes.func,
  selectedERRImages: PropTypes.array,
  setSelectedERRImages: PropTypes.func,
  handleCodeSelect: PropTypes.func,
  setRightSidePickMeDivs: PropTypes.func,
  verticalRightLines: PropTypes.array,
  setVerticalRightLines: PropTypes.func,
  blackRightSquares: PropTypes.array,
  setBlackRightSquares: PropTypes.func,
  setRightPickMes: PropTypes.func,
  pickMeRightDivs: PropTypes.array,
  index: PropTypes.number || PropTypes.string,
  setIndex: PropTypes.func,
  openRightSingleDrawing: PropTypes.bool,
  setOpenRightSingleDrawing: PropTypes.func,
  isScreenRoom: PropTypes.bool,
  setSelectedERRLocations: PropTypes.func,
  selectedERRLocations: PropTypes.array,
  handleDoorLocationChange: PropTypes.func,
  selectedBreezeWay: PropTypes.string,
  selectedPavilionLayoutOption: PropTypes.string,
};

export default RightSide;
