import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import PropTypes from "prop-types";
import angled1 from "./AngledLayoutImages/angled1.jpg";
import angled2 from "./AngledLayoutImages/angled2.jpg";
import angled3 from "./AngledLayoutImages/angled3.jpg";
import angled4 from "./AngledLayoutImages/angled4.jpg";
import angled5 from "./AngledLayoutImages/angled5.jpg";
import angled6 from "./AngledLayoutImages/angled6.jpg";
import angled7 from "./AngledLayoutImages/angled7.jpg";
import angled8 from "./AngledLayoutImages/angled8.jpg";
import angled9 from "./AngledLayoutImages/angled9.jpg";
import angled10 from "./AngledLayoutImages/angled10.jpg";

const AngledLayout = ({
  redBorderFields,
  setSelectedAngleOption,
  setFieldsWithRedBorder,
}) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [selectedImageIndex, setSelectedImageIndex] = useState(null);

  const angledImages = [
    angled1,
    angled2,
    angled3,
    angled4,
    angled5,
    angled6,
    angled7,
    angled8,
    angled9,
    angled10,
  ];

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const handleButtonClick = () => {
    setIsModalVisible((prevIsModalVisible) => {
      return !prevIsModalVisible;
    });
  };

  const handleImageClick = (index) => {
    setSelectedImageIndex(selectedImageIndex === index ? null : index);
    setSelectedAngleOption(selectedImageIndex === index ? null : index + 1);
    console.log(redBorderFields);
    const updatedRedBorderFields = redBorderFields.includes("Angled Layout")
      ? index.toString()
        ? redBorderFields.filter((field) => field !== "Angled Layout")
        : redBorderFields
      : index.toString()
        ? redBorderFields
        : [...redBorderFields, "Angled Layout"];

    setFieldsWithRedBorder(updatedRedBorderFields);
  };

  const buttonStyle = {
    position: "absolute",
    top: "1%",
    right: "1%",
    cursor: "pointer",
    width: "auto",
    height: "auto",
  };
  const modalRoot = document.getElementById("modal-root");

  return (
    <div
      style={{
        gridColumn: "1",
        gridRow: "1",
        display: "flex",
        justifySelf: "end",
        marginTop: "11vh",
        paddingTop: "1%",
        width: "8vw",
        height: "fit-content",
        marginRight: "2.4%",
        marginLeft: "2.4%",
      }}
    >
      <div
        style={{
          cursor: "pointer",
          fontSize: "x-small",
          backgroundColor: "transparent",
          width: "7vw",
        }}
      >
        <button
          className={`navbutton ${redBorderFields.includes("Angled Layout") ? "redBackground" : "greenBackground"}`}
          style={{
            fontSize: windowWidth > 1700 ? "1.7vh" : "1.5vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "7vw",
            overflow: "hidden",
          }}
          onClick={handleButtonClick}
        >
          Structure
          <br />
          Layout
        </button>
      </div>
      {isModalVisible &&
        ReactDOM.createPortal(
          <div className="modal">
            <div
              className="modal-content"
              style={{ width: "90vw", height: "90vh", overflow: "visible" }}
            >
              {selectedImageIndex === null && (
                <button
                  type="button"
                  className="showpdfbuttons"
                  style={buttonStyle}
                  onClick={handleButtonClick}
                >
                  X
                </button>
              )}
              {selectedImageIndex !== null && (
                <button
                  type="button"
                  className="showpdfbuttons"
                  style={buttonStyle}
                  onClick={handleButtonClick}
                >
                  Done
                </button>
              )}
              <div
                style={{
                  height: "100%",
                  width: "100%",
                  display: "grid",
                  gridTemplateColumns: "repeat(5, 2fr)", // Creates five columns
                  gridGap: "1%",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {angledImages.map((imageSrc, index) => (
                  <div
                    key={index}
                    className="popupwindow"
                    style={{
                      position: "relative",
                      width: "100%",
                      height: "80%",
                    }}
                  >
                    <img
                      src={imageSrc}
                      alt={`Option ${index + 1}`}
                      onClick={() => handleImageClick(index)}
                      style={{ width: "100%", height: "100%" }}
                    />
                    {selectedImageIndex === index && (
                      <div
                        style={{
                          position: "absolute",
                          top: 0,
                          left: 0,
                          right: 0,
                          bottom: 0,
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          color: "white", // Text color
                          fontSize: "20px", // Adjust as needed
                        }}
                        className="selectedImageOverlay"
                      ></div>
                    )}
                  </div>
                ))}
              </div>
            </div>
          </div>,
          modalRoot,
        )}
    </div>
  );
};

AngledLayout.propTypes = {
  redBorderFields: PropTypes.array,
  setSelectedAngleOption: PropTypes.func,
  setFieldsWithRedBorder: PropTypes.func,
};

export default AngledLayout;
