import React, { useState } from "react";
import InfoIcon from "../UniversalComponents/InfoIcon";
import PropTypes from "prop-types";

const TEMOCorners = ({
  handleSelectedTEMOCornersChoice,
  redBorderFields,
  setFieldsWithRedBorder,
}) => {
  const [selectedTEMOCornersOption, setSelectedTEMOCornersOption] =
    useState("");

  const handleTEMOCornersOptionChange = (selected) => {
    setSelectedTEMOCornersOption(selected);
    const updatedRedBorderFields = redBorderFields.includes(
      "TEMO CORNERS YES or NO",
    )
      ? selected
        ? redBorderFields.filter((field) => field !== "TEMO CORNERS YES or NO")
        : redBorderFields
      : selected
        ? redBorderFields
        : [...redBorderFields, "TEMO CORNERS YES or NO"];

    setFieldsWithRedBorder(updatedRedBorderFields);
    handleSelectedTEMOCornersChoice(selected);
  };

  return (
    <div className="popupwindow" style={{ width: "100%", marginTop: "1%" }}>
      <h6
        className={
          "headers " +
          (redBorderFields.includes("TEMO CORNERS YES or NO")
            ? "redBackground"
            : "greenBackground")
        }
      >
        <InfoIcon defaultMessage="" />
        <span className="header-grid-location">
          <span className="redasterisk">&#10033;</span>
          TEMO Corners
        </span>
      </h6>
      <div className="clickable-words">
        <div
          className={`clickable-word ${selectedTEMOCornersOption === "YES" ? "clicked" : ""}`}
          onClick={() => handleTEMOCornersOptionChange("YES")}
        >
          YES
        </div>
        <div
          className={`clickable-word ${selectedTEMOCornersOption === "NO" ? "clicked" : ""}`}
          onClick={() => handleTEMOCornersOptionChange("NO")}
        >
          NO
        </div>
      </div>
    </div>
  );
};

TEMOCorners.propTypes = {
  handleSelectedTEMOCornersChoice: PropTypes.func,
  redBorderFields: PropTypes.array,
  setFieldsWithRedBorder: PropTypes.func,
};

export default TEMOCorners;
