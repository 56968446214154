import React, { useState } from "react";
import PropTypes from "prop-types";
import LeftSide from "./Sides/LeftSide";
import RightSide from "./Sides/RightSide";
import FrontSide from "./Sides/FrontSide";
import ExistRoofDoorHardware from "./ExistRoofDoorHardware";

function ExistingPostsWalls({
  redBorderFields,
  selectedPavilionLayoutOption,
  selectedBreezeWay,
  selectedRailSideOption,
  dimension,
  setWallERLLengthsFeet,
  setWallERLLengthsInches,
  setWallERRLengthsFeet,
  setWallERRLengthsInches,
  setWallERFLengthsFeet,
  setWallERFLengthsInches,
  wallERLLengthsFeet,
  wallERLLengthsInches,
  wallERRLengthsFeet,
  wallERRLengthsInches,
  wallERFLengthsFeet,
  wallERFLengthsInches,
  handleWallLengthFeetChange,
  handleWallLengthInchesChange,
  setLeftCavities,
  setRightCavities,
  setFrontCavities,
  setDoorERFOption,
  setDoorERROption,
  setDoorERLOption,
  doorERFOption,
  doorERROption,
  doorERLOption,
  handleDoorOptionChange,
  doorERFWidth,
  setDoorERFWidth,
  doorERRWidth,
  setDoorERRWidth,
  doorERLWidth,
  setDoorERLWidth,
  lastWallLengthUpdate,
  setLastWallLengthUpdate,
  handleDoorWidthChange,
  selectedSunRoomType,
  setFieldsWithRedBorder,
  selectedERLPatioDoorOrientation,
  selectedERLSwingDoorOrientation,
  selectedERRPatioDoorOrientation,
  selectedERRSwingDoorOrientation,
  selectedERFPatioDoorOrientation,
  selectedERFSwingDoorOrientation,
  setSelectedERLSwingDoorOrientation,
  setSelectedERLPatioDoorOrientation,
  setSelectedERRSwingDoorOrientation,
  setSelectedERRPatioDoorOrientation,
  setSelectedERFSwingDoorOrientation,
  setSelectedERFPatioDoorOrientation,
  setSelectedERLCodes,
  setSelectedERRCodes,
  setSelectedERFCodes,
  selectedERLCodes,
  selectedERRCodes,
  selectedERFCodes,
  selectedERLButtonIndex,
  selectedERRButtonIndex,
  selectedERFButtonIndex,
  setSelectedERLButtonIndex,
  setSelectedERRButtonIndex,
  setSelectedERFButtonIndex,
  selectedERLImages,
  selectedERRImages,
  selectedERFImages,
  setSelectedERLImages,
  setSelectedERRImages,
  setSelectedERFImages,
  handleCodeSelect,
  selectedDoorHardware,
  setSelectedDoorHardware,
  SSHinges,
  handleSSHingesOptionChange,
  setLeftSideDivs,
  setRightSideDivs,
  setFrontSideDivs,
  setLeftSidePickMeDivs,
  setRightSidePickMeDivs,
  setFrontSidePickMeDivs,
  leftSideDivs,
  rightSideDivs,
  frontSideDivs,
  leftSidePickMeDivs,
  rightSidePickMeDivs,
  frontSidePickMeDivs,
  verticalRightLines,
  setVerticalRightLines,
  blackRightSquares,
  setBlackRightSquares,
  setRightPickMes,
  pickMeRightDivs,
  horizontalLines,
  setHorizontalLines,
  pickMeFrontDivs,
  setBlackFrontSquares,
  blackFrontSquares,
  setFrontPickMes,
  blackLeftSquares,
  setBlackLeftSquares,
  setLeftPickMes,
  pickMeLeftDivs,
  setVerticalLeftLines,
  verticalLeftLines,
  setIndexFront,
  setIndexRight,
  setIndexLeft,
  indexFront,
  indexRight,
  indexLeft,
  renderDivs,
  setOpenFrontSingleDrawing,
  setOpenRightSingleDrawing,
  setOpenLeftSingleDrawing,
  openFrontSingleDrawing,
  openRightSingleDrawing,
  openLeftSingleDrawing,
  isScreenRoom,
  setSelectedERFLocations,
  setSelectedERRLocations,
  setSelectedERLLocations,
  selectedERFLocations,
  selectedERRLocations,
  selectedERLLocations,
  handleDoorLocationChange,
  handleSelectedScreenDoorWidthChoice,
}) {
  const [showDoorHardware, setShowDoorHardware] = useState(false);

  const handleShowDoorHardwareClick = () => {
    setShowDoorHardware(true);
  };

  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        backgroundColor: "transparent",
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "center",
        overflow: "visible",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignSelf: "center",
          justifyContent: "space-around",
          justifySelf: "flex-start",
          width: "90%",
          marginRight: "auto",
        }}
      >
        {(selectedPavilionLayoutOption === "1" ||
          (selectedPavilionLayoutOption === "2" &&
            selectedBreezeWay === "Yes") ||
          selectedRailSideOption === "LEFT") && (
          <LeftSide
            setLeftSideDivs={setLeftSideDivs}
            setLeftSidePickMeDivs={setLeftSidePickMeDivs}
            redBorderFields={redBorderFields}
            dimension={dimension}
            setWallERLLengthsFeet={setWallERLLengthsFeet}
            setWallERLLengthsInches={setWallERLLengthsInches}
            wallERLLengthsFeet={wallERLLengthsFeet}
            wallERLLengthsInches={wallERLLengthsInches}
            handleWallLengthFeetChange={handleWallLengthFeetChange}
            setLeftCavities={setLeftCavities}
            handleWallLengthInchesChange={handleWallLengthInchesChange}
            setDoorERLOption={setDoorERLOption}
            doorERLOption={doorERLOption}
            handleDoorOptionChange={handleDoorOptionChange}
            doorERLWidth={doorERLWidth}
            setDoorERLWidth={setDoorERLWidth}
            lastWallLengthUpdate={lastWallLengthUpdate}
            setLastWallLengthUpdate={setLastWallLengthUpdate}
            handleDoorWidthChange={handleDoorWidthChange}
            selectedSunRoomType={selectedSunRoomType}
            setFieldsWithRedBorder={setFieldsWithRedBorder}
            selectedERLPatioDoorOrientation={selectedERLPatioDoorOrientation}
            selectedERLSwingDoorOrientation={selectedERLSwingDoorOrientation}
            setSelectedERLSwingDoorOrientation={
              setSelectedERLSwingDoorOrientation
            }
            setSelectedERLPatioDoorOrientation={
              setSelectedERLPatioDoorOrientation
            }
            setSelectedERLCodes={setSelectedERLCodes}
            selectedERLCodes={selectedERLCodes}
            selectedERLButtonIndex={selectedERLButtonIndex}
            setSelectedERLButtonIndex={setSelectedERLButtonIndex}
            selectedERLImages={selectedERLImages}
            setSelectedERLImages={setSelectedERLImages}
            handleCodeSelect={handleCodeSelect}
            blackLeftSquares={blackLeftSquares}
            setBlackLeftSquares={setBlackLeftSquares}
            setLeftPickMes={setLeftPickMes}
            pickMeLeftDivs={pickMeLeftDivs}
            setVerticalLeftLines={setVerticalLeftLines}
            verticalLeftLines={verticalLeftLines}
            setIndex={setIndexLeft}
            index={indexLeft}
            setOpenLeftSingleDrawing={setOpenLeftSingleDrawing}
            openLeftSingleDrawing={openLeftSingleDrawing}
            isScreenRoom={isScreenRoom}
            setSelectedERLLocations={setSelectedERLLocations}
            selectedERLLocations={selectedERLLocations}
            handleDoorLocationChange={handleDoorLocationChange}
            selectedBreezeWay={selectedBreezeWay}
            selectedPavilionLayoutOption={selectedPavilionLayoutOption}
          />
        )}

        {selectedBreezeWay !== "Yes" && (
          <FrontSide
            setFrontSideDivs={setFrontSideDivs}
            setFrontSidePickMeDivs={setFrontSidePickMeDivs}
            redBorderFields={redBorderFields}
            dimension={dimension}
            setWallERFLengthsFeet={setWallERFLengthsFeet}
            setWallERFLengthsInches={setWallERFLengthsInches}
            wallERFLengthsFeet={wallERFLengthsFeet}
            wallERFLengthsInches={wallERFLengthsInches}
            handleWallLengthFeetChange={handleWallLengthFeetChange}
            setFrontCavities={setFrontCavities}
            handleWallLengthInchesChange={handleWallLengthInchesChange}
            setDoorERFOption={setDoorERFOption}
            doorERFOption={doorERFOption}
            handleDoorOptionChange={handleDoorOptionChange}
            doorERFWidth={doorERFWidth}
            setDoorERFWidth={setDoorERFWidth}
            lastWallLengthUpdate={lastWallLengthUpdate}
            setLastWallLengthUpdate={setLastWallLengthUpdate}
            handleDoorWidthChange={handleDoorWidthChange}
            selectedSunRoomType={selectedSunRoomType}
            setFieldsWithRedBorder={setFieldsWithRedBorder}
            selectedERFPatioDoorOrientation={selectedERFPatioDoorOrientation}
            selectedERFSwingDoorOrientation={selectedERFSwingDoorOrientation}
            setSelectedERFSwingDoorOrientation={
              setSelectedERFSwingDoorOrientation
            }
            setSelectedERFPatioDoorOrientation={
              setSelectedERFPatioDoorOrientation
            }
            setSelectedERFCodes={setSelectedERFCodes}
            selectedERFCodes={selectedERFCodes}
            selectedERFButtonIndex={selectedERFButtonIndex}
            setSelectedERFButtonIndex={setSelectedERFButtonIndex}
            selectedERFImages={selectedERFImages}
            setSelectedERFImages={setSelectedERFImages}
            handleCodeSelect={handleCodeSelect}
            blackFrontSquares={blackFrontSquares}
            setBlackFrontSquares={setBlackFrontSquares}
            setFrontPickMes={setFrontPickMes}
            pickMeFrontDivs={pickMeFrontDivs}
            horizontalLines={horizontalLines}
            setHorizontalLines={setHorizontalLines}
            setIndex={setIndexFront}
            index={indexFront}
            setOpenFrontSingleDrawing={setOpenFrontSingleDrawing}
            openFrontSingleDrawing={openFrontSingleDrawing}
            isScreenRoom={isScreenRoom}
            setSelectedERFLocations={setSelectedERFLocations}
            selectedERFLocations={selectedERFLocations}
            handleDoorLocationChange={handleDoorLocationChange}
            selectedBreezeWay={selectedBreezeWay}
            selectedPavilionLayoutOption={selectedPavilionLayoutOption}
          />
        )}
        {(selectedPavilionLayoutOption === "1" ||
          (selectedPavilionLayoutOption === "2" &&
            selectedBreezeWay === "Yes") ||
          selectedRailSideOption === "RIGHT") && (
          <RightSide
            setRightSideDivs={setRightSideDivs}
            setRightSidePickMeDivs={setRightSidePickMeDivs}
            redBorderFields={redBorderFields}
            dimension={dimension}
            setWallERRLengthsFeet={setWallERRLengthsFeet}
            setWallERRLengthsInches={setWallERRLengthsInches}
            wallERRLengthsFeet={wallERRLengthsFeet}
            wallERRLengthsInches={wallERRLengthsInches}
            handleWallLengthFeetChange={handleWallLengthFeetChange}
            setRightCavities={setRightCavities}
            handleWallLengthInchesChange={handleWallLengthInchesChange}
            setDoorERROption={setDoorERROption}
            doorERROption={doorERROption}
            handleDoorOptionChange={handleDoorOptionChange}
            doorERRWidth={doorERRWidth}
            setDoorERRWidth={setDoorERRWidth}
            lastWallLengthUpdate={lastWallLengthUpdate}
            setLastWallLengthUpdate={setLastWallLengthUpdate}
            handleDoorWidthChange={handleDoorWidthChange}
            selectedSunRoomType={selectedSunRoomType}
            setFieldsWithRedBorder={setFieldsWithRedBorder}
            selectedERRPatioDoorOrientation={selectedERRPatioDoorOrientation}
            selectedERRSwingDoorOrientation={selectedERRSwingDoorOrientation}
            setSelectedERRSwingDoorOrientation={
              setSelectedERRSwingDoorOrientation
            }
            setSelectedERRPatioDoorOrientation={
              setSelectedERRPatioDoorOrientation
            }
            setSelectedERRCodes={setSelectedERRCodes}
            selectedERRCodes={selectedERRCodes}
            selectedERRButtonIndex={selectedERRButtonIndex}
            setSelectedERRButtonIndex={setSelectedERRButtonIndex}
            selectedERRImages={selectedERRImages}
            setSelectedERRImages={setSelectedERRImages}
            handleCodeSelect={handleCodeSelect}
            verticalRightLines={verticalRightLines}
            setVerticalRightLines={setVerticalRightLines}
            blackRightSquares={blackRightSquares}
            setBlackRightSquares={setBlackRightSquares}
            setRightPickMes={setRightPickMes}
            pickMeRightDivs={pickMeRightDivs}
            setIndex={setIndexRight}
            index={indexRight}
            setOpenRightSingleDrawing={setOpenRightSingleDrawing}
            openRightSingleDrawing={openRightSingleDrawing}
            isScreenRoom={isScreenRoom}
            setSelectedERRLocations={setSelectedERRLocations}
            selectedERRLocations={selectedERRLocations}
            handleDoorLocationChange={handleDoorLocationChange}
            selectedBreezeWay={selectedBreezeWay}
            selectedPavilionLayoutOption={selectedPavilionLayoutOption}
          />
        )}
      </div>
      <div
        style={{
          height: "100%",
          width: "100%",
          display: "grid",
          gridTemplateRows: "repeat(32, 1fr)",
          gridTemplateColumns: "repeat(32, 1fr)",
        }}
      >
        <div style={{ gridRow: "2 / span 3", gridColumn: "12 / span 9" }}>
          <ExistRoofDoorHardware
            selectedDoorHardware={selectedDoorHardware}
            setSelectedDoorHardware={setSelectedDoorHardware}
            setShowDoorHardware={setShowDoorHardware}
            setFieldsWithRedBorder={setFieldsWithRedBorder}
            doorERLWidth={doorERLWidth}
            doorERRWidth={doorERRWidth}
            doorERFWidth={doorERFWidth}
            handleShowDoorHardwareClick={handleShowDoorHardwareClick}
            SSHinges={SSHinges}
            handleSSHingesOptionChange={handleSSHingesOptionChange}
            redBorderFields={redBorderFields}
            showDoorHardware={showDoorHardware}
            selectedSunRoomType={selectedSunRoomType}
            handleSelectedScreenDoorWidthChoice={
              handleSelectedScreenDoorWidthChoice
            }
            selectedPavilionLayoutOption={selectedPavilionLayoutOption}
            selectedBreezeWay={selectedBreezeWay}
          />
        </div>
        <>
          {(selectedPavilionLayoutOption === "1" ||
            (selectedPavilionLayoutOption === "2" &&
              selectedBreezeWay === "Yes") ||
            selectedRailSideOption === "RIGHT") &&
            renderDivs(rightSideDivs, rightSidePickMeDivs)}
          {(selectedPavilionLayoutOption === "1" ||
            (selectedPavilionLayoutOption === "2" &&
              selectedBreezeWay === "Yes") ||
            selectedRailSideOption === "LEFT") &&
            renderDivs(leftSideDivs, leftSidePickMeDivs)}
          {selectedBreezeWay !== "Yes" &&
            renderDivs(frontSideDivs, frontSidePickMeDivs)}
        </>
      </div>
    </div>
  );
}

ExistingPostsWalls.propTypes = {
  selectedSunRoomType: PropTypes.string,
  selectedRoomStyle: PropTypes.string,
  selectedPavilionLayoutOption: PropTypes.string,
  selectedBreezeWay: PropTypes.string,
  redBorderFields: PropTypes.array,
  selectedRailSideOption: PropTypes.string,
  dimension: PropTypes.number,
  setWallERLLengthsFeet: PropTypes.func,
  setWallERLLengthsInches: PropTypes.func,
  setWallERRLengthsFeet: PropTypes.func,
  setWallERRLengthsInches: PropTypes.func,
  setWallERFLengthsFeet: PropTypes.func,
  setWallERFLengthsInches: PropTypes.func,
  wallERLLengthsFeet: PropTypes.array,
  wallERLLengthsInches: PropTypes.array,
  wallERRLengthsFeet: PropTypes.array,
  wallERRLengthsInches: PropTypes.array,
  wallERFLengthsFeet: PropTypes.array,
  wallERFLengthsInches: PropTypes.array,
  handleWallLengthFeetChange: PropTypes.func,
  setLeftCavities: PropTypes.func,
  setRightCavities: PropTypes.func,
  setFrontCavities: PropTypes.func,
  handleWallLengthInchesChange: PropTypes.func,
  setDoorERFOption: PropTypes.func,
  setDoorERROption: PropTypes.func,
  setDoorERLOption: PropTypes.func,
  doorERFOption: PropTypes.array,
  doorERROption: PropTypes.array,
  doorERLOption: PropTypes.array,
  handleDoorOptionChange: PropTypes.func,
  doorERFWidth: PropTypes.array,
  setDoorERFWidth: PropTypes.func,
  doorERRWidth: PropTypes.array,
  setDoorERRWidth: PropTypes.func,
  doorERLWidth: PropTypes.array,
  setDoorERLWidth: PropTypes.func,
  lastWallLengthUpdate: PropTypes.number,
  setLastWallLengthUpdate: PropTypes.func,
  handleDoorWidthChange: PropTypes.func,
  setFieldsWithRedBorder: PropTypes.func,
  selectedERLPatioDoorOrientation: PropTypes.array,
  selectedERLSwingDoorOrientation: PropTypes.array,
  selectedERRPatioDoorOrientation: PropTypes.array,
  selectedERRSwingDoorOrientation: PropTypes.array,
  selectedERFPatioDoorOrientation: PropTypes.array,
  selectedERFSwingDoorOrientation: PropTypes.array,
  setSelectedERLSwingDoorOrientation: PropTypes.func,
  setSelectedERLPatioDoorOrientation: PropTypes.func,
  setSelectedERRSwingDoorOrientation: PropTypes.func,
  setSelectedERRPatioDoorOrientation: PropTypes.func,
  setSelectedERFSwingDoorOrientation: PropTypes.func,
  setSelectedERFPatioDoorOrientation: PropTypes.func,
  setSelectedERLCodes: PropTypes.func,
  setSelectedERRCodes: PropTypes.func,
  setSelectedERFCodes: PropTypes.func,
  selectedERLCodes: PropTypes.array,
  selectedERRCodes: PropTypes.array,
  selectedERFCodes: PropTypes.array,
  selectedERLButtonIndex: PropTypes.array,
  selectedERRButtonIndex: PropTypes.array,
  selectedERFButtonIndex: PropTypes.array,
  setSelectedERLButtonIndex: PropTypes.func,
  setSelectedERRButtonIndex: PropTypes.func,
  setSelectedERFButtonIndex: PropTypes.func,
  selectedERLImages: PropTypes.array,
  selectedERRImages: PropTypes.array,
  selectedERFImages: PropTypes.array,
  setSelectedERLImages: PropTypes.func,
  setSelectedERRImages: PropTypes.func,
  setSelectedERFImages: PropTypes.func,
  handleCodeSelect: PropTypes.func,
  selectedDoorHardware: PropTypes.string,
  setSelectedDoorHardware: PropTypes.func,
  SSHinges: PropTypes.string,
  handleSSHingesOptionChange: PropTypes.func,
  setLeftSideDivs: PropTypes.func,
  setRightSideDivs: PropTypes.func,
  setFrontSideDivs: PropTypes.func,
  setLeftSidePickMeDivs: PropTypes.func,
  setRightSidePickMeDivs: PropTypes.func,
  setFrontSidePickMeDivs: PropTypes.func,
  leftSideDivs: PropTypes.array,
  rightSideDivs: PropTypes.array,
  frontSideDivs: PropTypes.array,
  leftSidePickMeDivs: PropTypes.array,
  rightSidePickMeDivs: PropTypes.array,
  frontSidePickMeDivs: PropTypes.array,
  verticalRightLines: PropTypes.array,
  setVerticalRightLines: PropTypes.func,
  blackRightSquares: PropTypes.array,
  setBlackRightSquares: PropTypes.func,
  setRightPickMes: PropTypes.func,
  pickMeRightDivs: PropTypes.array,
  horizontalLines: PropTypes.array,
  setHorizontalLines: PropTypes.func,
  pickMeFrontDivs: PropTypes.array,
  setBlackFrontSquares: PropTypes.func,
  blackFrontSquares: PropTypes.array,
  setFrontPickMes: PropTypes.func,
  blackLeftSquares: PropTypes.array,
  setBlackLeftSquares: PropTypes.func,
  setLeftPickMes: PropTypes.func,
  pickMeLeftDivs: PropTypes.array,
  setVerticalLeftLines: PropTypes.func,
  verticalLeftLines: PropTypes.array,
  setIndexFront: PropTypes.func,
  setIndexRight: PropTypes.func,
  setIndexLeft: PropTypes.func,
  indexFront: PropTypes.number || PropTypes.string,
  indexRight: PropTypes.number || PropTypes.string,
  indexLeft: PropTypes.number || PropTypes.string,
  renderDivs: PropTypes.func,
  setOpenFrontSingleDrawing: PropTypes.func,
  setOpenRightSingleDrawing: PropTypes.func,
  setOpenLeftSingleDrawing: PropTypes.func,
  openFrontSingleDrawing: PropTypes.bool,
  openRightSingleDrawing: PropTypes.bool,
  openLeftSingleDrawing: PropTypes.bool,
  isScreenRoom: PropTypes.bool,
  setSelectedERFLocations: PropTypes.func,
  setSelectedERRLocations: PropTypes.func,
  setSelectedERLLocations: PropTypes.func,
  selectedERFLocations: PropTypes.array,
  selectedERRLocations: PropTypes.array,
  selectedERLLocations: PropTypes.array,
  handleDoorLocationChange: PropTypes.func,
  handleSelectedScreenDoorWidthChoice: PropTypes.func,
};

export default ExistingPostsWalls;
