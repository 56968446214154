import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import StructOne from "./UniversalViewingImages/structlayout1.jpg";
import StructTwo from "./UniversalViewingImages/structlayout2.jpg";
import StructThree from "./UniversalViewingImages/structlayout3.jpg";
import PropTypes from "prop-types";
import OneWall from "./StructureLayoutMessages/OneWall";
import TwoWall from "./StructureLayoutMessages/TwoWall";
import ThreeWall from "./StructureLayoutMessages/ThreeWall";
import RailAmountInput from "./StructureLayoutThings/RailAmountInput";
import RailSideSelector from "./StructureLayoutThings/RailSideSelector";
import RailBreezeway from "./StructureLayoutThings/RailBreezeway";

const StructureLayoutImages = ({
  redBorderFields,
  setFieldsWithRedBorder,
  handlePavilionLayoutOptionChange,
  handleRailSideOption,
  handleRailAmountOption,
  selectedBreezeWay,
  handleBreezeWayChange,
  isSunroom,
  selectedRoomStyle,
}) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedMessage, setSelectedMessage] = useState("");
  const [selectedSide, setSelectedSide] = useState("");
  const [railAmount, setRailAmount] = useState({ feet: "", inches: "" });
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [reallyIsSunroom, setReallyIsSunroom] = useState(false);

  if (isSunroom && !reallyIsSunroom) {
    setReallyIsSunroom(true);
  }

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const images = [StructOne, StructTwo, StructThree];
  const messages = [
    <OneWall key="oneWall" />,
    <TwoWall key="twoWall" />,
    <ThreeWall key="threeWall" />,
  ];

  const handleButtonClick = () => {
    setIsModalVisible((prevIsModalVisible) => {
      return !prevIsModalVisible;
    });
  };

  const handleRadioChange = (index) => {
    setSelectedMessage(index);
    handlePavilionLayoutOptionChange((index + 1).toString());

    if (redBorderFields.includes("StructureLayoutImages")) {
      setFieldsWithRedBorder((prevRedBorderFields) =>
        prevRedBorderFields.filter(
          (field) => field !== "StructureLayoutImages",
        ),
      );
    }
    handleBreezeWayChange("");
    handleRailSideChange("");
    handleRailAmountChange({ target: { name: "feet", value: "" } });
    handleRailAmountChange({ target: { name: "inches", value: "" } });
  };

  const handleRailSideChange = (selected) => {
    setSelectedSide(selected);
    const updatedRedBorderFields = redBorderFields.includes("rail side")
      ? selected
        ? redBorderFields.filter((field) => field !== "rail side")
        : [...redBorderFields, "rail side"]
      : selected
        ? redBorderFields
        : [...redBorderFields, "rail side"];

    setFieldsWithRedBorder(updatedRedBorderFields);
    handleRailSideOption(selected);
  };

  const handleRailAmountChange = (e) => {
    let input = e.target.value.replace(/[^\d.]/g, "");

    if (e.target.name !== "inches") {
      input = input.replace(/\D/g, "").slice(0, 3);
    }
    setTimeout(() => {
      if (e.target.name === "inches") {
        // Check if the input is a valid number or a number ending with a decimal point
        if (!isNaN(parseFloat(input)) && !input.endsWith(".")) {
          let numericInput = parseFloat(input);
          if (!isNaN(numericInput)) {
            numericInput = Math.min(Math.max(numericInput, 0), 11.999);
            input = numericInput.toString();
          }
        }
      } else {
        input =
          input === ""
            ? ""
            : Math.min(Math.max(parseInt(input, 10), 0), 999).toString();
      }

      setRailAmount((prevAmount) => ({
        ...prevAmount,
        [e.target.name]: input,
      }));
    }, 0);

    const updatedAmount = {
      ...railAmount,
      [e.target.name]: input,
    };
    handleRailAmountOption(updatedAmount);

    const field = `rail amount ${e.target.name.charAt(0).toUpperCase() + e.target.name.slice(1)}`;
    const updatedRedBorderFields = input
      ? redBorderFields.filter((currentField) => currentField !== field)
      : redBorderFields;
    setFieldsWithRedBorder(() => updatedRedBorderFields);
  };

  useEffect(() => {
    setFieldsWithRedBorder((prevRedBorderFields) => {
      if (!prevRedBorderFields.includes("StructureLayoutImages")) {
        return prevRedBorderFields;
      }

      if (selectedMessage === 0) {
        return prevRedBorderFields.filter(
          (field) => field !== "StructureLayoutImages",
        );
      } else if (
        selectedMessage === 1 &&
        selectedSide &&
        railAmount.feet &&
        railAmount.inches
      ) {
        return prevRedBorderFields.filter(
          (field) => field !== "StructureLayoutImages",
        );
      } else if (
        selectedMessage === 2 &&
        railAmount.feet &&
        railAmount.inches
      ) {
        return prevRedBorderFields.filter(
          (field) => field !== "StructureLayoutImages",
        );
      }

      // Return the unmodified list if none of the conditions are met
      return prevRedBorderFields;
    });
  }, [
    selectedMessage,
    selectedSide,
    railAmount.feet,
    railAmount.inches,
    redBorderFields,
    setFieldsWithRedBorder,
  ]);

  const buttonStyle = {
    position: "absolute",
    top: "1%",
    right: "1%",
    cursor: "pointer",
    width: "auto",
    height: "auto",
  };
  const modalRoot = document.getElementById("modal-root");

  return (
    <div
      style={{
        gridColumn: "1",
        gridRow: "1",
        display: "flex",
        justifySelf: "end",
        marginTop: "11vh",
        paddingTop: "1%",
        width: "8vw",
        height: "fit-content",
        marginRight: "2.4%",
        marginLeft: "2.4%",
      }}
    >
      <div
        style={{
          cursor: "pointer",
          fontSize: "x-small",
          backgroundColor: "transparent",
          width: "7vw",
        }}
      >
        <button
          className={`navbutton ${redBorderFields.includes("StructureLayoutImages") ? "redBackground" : "greenBackground"}`}
          style={{
            fontSize: windowWidth > 1700 ? "1.7vh" : "1.5vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "7vw",
            overflow: "hidden",
          }}
          onClick={handleButtonClick}
        >
          Structure
          <br />
          Layout
        </button>
      </div>

      {isModalVisible &&
        ReactDOM.createPortal(
          <div className="modal">
            <div className="modal-content">
              {!selectedMessage && (
                <button
                  type="button"
                  className="showpdfbuttons"
                  style={buttonStyle}
                  onClick={handleButtonClick}
                >
                  X
                </button>
              )}
              {selectedMessage === 0 && (
                <button
                  type="button"
                  className="showpdfbuttons"
                  style={buttonStyle}
                  onClick={handleButtonClick}
                >
                  Done
                </button>
              )}
              {selectedMessage === 1 && (
                <button
                  type="button"
                  className="showpdfbuttons"
                  style={buttonStyle}
                  onClick={handleButtonClick}
                >
                  {selectedSide !== "" &&
                  railAmount.feet !== "" &&
                  railAmount.inches !== ""
                    ? "Done"
                    : "X"}
                </button>
              )}
              {selectedMessage === 2 && (
                <button
                  type="button"
                  className="showpdfbuttons"
                  style={buttonStyle}
                  onClick={handleButtonClick}
                >
                  {railAmount.feet && railAmount.inches ? "Done" : "X"}
                </button>
              )}
              <div
                className="image-container3"
                style={{
                  display: "flex",
                  flexDirection: "row",
                  minWidth: "71.3vw",
                  maxWidth: "71.3vw",
                  height: "80vh",
                  overflow: "hidden",
                }}
              >
                {images.map((image, index) => (
                  <div
                    key={index}
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                      marginTop: "12%",
                      marginBottom: "12%",
                      width: "fit-content",
                      marginRight: "4%",
                      marginLeft: "4%",
                    }}
                  >
                    {index === 1 && selectedMessage === 1 && (
                      <>
                        {reallyIsSunroom && (
                          <RailBreezeway
                            redBorderFields={redBorderFields}
                            selectedBreezeWay={selectedBreezeWay}
                            handleBreezeWayChange={handleBreezeWayChange}
                          />
                        )}
                        {(selectedBreezeWay === "No" || !reallyIsSunroom) && (
                          <RailSideSelector
                            redBorderFields={redBorderFields}
                            selectedSide={selectedSide}
                            handleRailSideChange={handleRailSideChange}
                          />
                        )}
                        {selectedRoomStyle !== "Existing Roof" && (
                          <RailAmountInput
                            railAmount={railAmount}
                            handleRailAmountChange={handleRailAmountChange}
                            redBorderFields={redBorderFields}
                          />
                        )}
                      </>
                    )}
                    {index === 2 &&
                      selectedMessage === 2 &&
                      selectedRoomStyle !== "Existing Roof" && (
                        <RailAmountInput
                          railAmount={railAmount}
                          handleRailAmountChange={handleRailAmountChange}
                          redBorderFields={redBorderFields}
                        />
                      )}
                    <label style={{ cursor: "pointer", position: "relative" }}>
                      <div
                        className={
                          selectedMessage === index
                            ? "selectedImageOverlay"
                            : ""
                        }
                        style={{ position: "relative" }}
                      >
                        <img
                          src={image}
                          alt={`${index + 1}`}
                          style={{ width: "18vw", height: "auto" }}
                        />
                      </div>
                      <br />
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        {messages[index]}
                      </div>
                      <input
                        type="radio"
                        name="message"
                        value={index + 1}
                        checked={selectedMessage === index}
                        onChange={() => handleRadioChange(index)}
                        style={{ display: "none" }}
                      />
                    </label>
                  </div>
                ))}
              </div>
            </div>
          </div>,
          modalRoot,
        )}
    </div>
  );
};

StructureLayoutImages.propTypes = {
  redBorderFields: PropTypes.array,
  setFieldsWithRedBorder: PropTypes.func,
  handlePavilionLayoutOptionChange: PropTypes.func,
  handleRailSideOption: PropTypes.func,
  handleRailAmountOption: PropTypes.func,
  selectedBreezeWay: PropTypes.string,
  handleBreezeWayChange: PropTypes.func,
  isSunroom: PropTypes.bool,
  selectedRoomStyle: PropTypes.string,
};

export default StructureLayoutImages;
