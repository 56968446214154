import React from "react";
import ReactDOM from "react-dom";
import PropTypes from "prop-types";
import L_LeftInswing from "./DoorOrientationImages/L-LEFT-INSWING.jpg";
import L_RightInswing from "./DoorOrientationImages/L-RIGHT-INSWING.jpg";
import L_LeftOutswing from "./DoorOrientationImages/L-LEFT-OUTSWING.jpg";
import L_RightOutswing from "./DoorOrientationImages/L-RIGHT-OUTSWING.jpg";
import F_LeftInswing from "./DoorOrientationImages/F-LEFT-INSWING.jpg";
import F_RightInswing from "./DoorOrientationImages/F-RIGHT-INSWING.jpg";
import F_LeftOutswing from "./DoorOrientationImages/F-LEFT-OUTSWING.jpg";
import F_RightOutswing from "./DoorOrientationImages/F-RIGHT-OUTSWING.jpg";
import R_LeftInswing from "./DoorOrientationImages/R-LEFT-INSWING.jpg";
import R_RightInswing from "./DoorOrientationImages/R-RIGHT-INSWING.jpg";
import R_LeftOutswing from "./DoorOrientationImages/R-LEFT-OUTSWING.jpg";
import R_RightOutswing from "./DoorOrientationImages/R-RIGHT-OUTSWING.jpg";

function SwingDoorOrientation({
  setSelectedSwingDoorOrientation,
  index,
  newIndex,
  setshowSwingDoorOrientation,
  selectedSwingDoorOrientation,
  setFieldsWithRedBorder,
  redFieldMessage,
  setSelectedPatioDoorOrientation,
}) {
  const orderedImageSets = {
    0: [L_RightInswing, L_LeftInswing, L_LeftOutswing, L_RightOutswing],
    1: [F_RightInswing, F_LeftInswing, F_LeftOutswing, F_RightOutswing],
    2: [R_RightInswing, R_LeftInswing, R_LeftOutswing, R_RightOutswing],
  };

  const orderedImageCaptions = [
    "Right-Inswing",
    "Left-Inswing",
    "Left-Outswing",
    "Right-Outswing",
  ];

  const images = orderedImageSets[newIndex];

  const handleButtonClick = (orientation) => {
    setSelectedSwingDoorOrientation((prevOrientations) => {
      const newOrientations = [...prevOrientations];
      newOrientations[index] = orientation;
      return newOrientations;
    });
    setSelectedPatioDoorOrientation((prevSwingDoorOrientation) => {
      const newSwingDoorOrientation = [...prevSwingDoorOrientation];
      newSwingDoorOrientation[index] = "";
      return newSwingDoorOrientation;
    });

    setFieldsWithRedBorder((prevRedBorderFields) => {
      if (orientation) {
        return prevRedBorderFields.filter(
          (field) => field !== `${redFieldMessage}`,
        );
      } else {
        return [...prevRedBorderFields, `${redFieldMessage}`];
      }
    });
  };

  const handleCloseModal = () => {
    setshowSwingDoorOrientation(false);
  };

  return ReactDOM.createPortal(
    <div
      style={{
        zIndex: 2005,
        position: "fixed",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "rgba(0, 0, 0, 0.5)",
      }}
    >
      <div className="modal">
        <div
          className="modal-content"
          style={{ width: "80%", height: "80%", overflowY: "auto" }}
        >
          {selectedSwingDoorOrientation[index] === null && (
            <button
              className="navbutton"
              style={{
                position: "absolute",
                top: "1%",
                right: "1%",
                cursor: "pointer",
                zIndex: 200,
              }}
              onClick={handleCloseModal}
            >
              X
            </button>
          )}
          {selectedSwingDoorOrientation[index] !== null && (
            <button
              className="navbutton"
              style={{
                position: "absolute",
                top: "1%",
                right: "1%",
                cursor: "pointer",
                zIndex: 200,
              }}
              onClick={handleCloseModal}
            >
              Done
            </button>
          )}
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
              height: "100%",
            }}
          >
            {images.map((image, imageIndex) => (
              <div
                key={imageIndex}
                style={{
                  flex: "0 0 50%",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "49%",
                  cursor: "pointer",
                  position: "relative",
                }}
                className="popupwindow"
                onClick={() =>
                  handleButtonClick(orderedImageCaptions[imageIndex])
                }
              >
                <img
                  src={image}
                  alt={`Orientation ${imageIndex + 1}`}
                  style={{ width: "50%", height: "90%" }}
                />
                {selectedSwingDoorOrientation[index] ===
                  orderedImageCaptions[imageIndex] && (
                  <div
                    style={{
                      position: "absolute",
                      top: 0,
                      left: 0,
                      width: "100%",
                      height: "100%",
                      backgroundColor: "rgba(173, 216, 230, 0.5)",
                      pointerEvents: "none",
                    }}
                  />
                )}
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>,
    document.getElementById("modal-root"),
  );
}

SwingDoorOrientation.propTypes = {
  setSelectedSwingDoorOrientation: PropTypes.func,
  index: PropTypes.number,
  setshowSwingDoorOrientation: PropTypes.func,
  selectedSwingDoorOrientation: PropTypes.array,
  setFieldsWithRedBorder: PropTypes.func,
  redFieldMessage: PropTypes.string,
};

export default SwingDoorOrientation;
