import React from "react";
import PropTypes from "prop-types";

function WallLength({
  wallLengthsFeet,
  wallLengthsInches,
  redBorderFields,
  index,
  handleWallLengthFeetChange,
  handleWallLengthInchesChange,
  setWallLengthsFeet,
  setWallLengthsInches,
  wallLengthFeetMessage,
  wallLengthInchesMessage,
  setLastWallLengthUpdate,
}) {
  return (
    <div style={{ width: "18.3vw" }}>
      <label
        className="popupwindow"
        style={{
          width: "100%",
          height: "8vh",
          color: "black",
          fontSize: "1.4vh",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <h6 className="headers greenBackground">
          <span className="header-grid-location">
            <span className="redasterisk">&#10033;</span>
            <span>Wall Length</span>
          </span>
        </h6>
        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "row",
          }}
        >
          <span style={{ flex: 1, maxWidth: "20%" }}>Feet:</span>
          <input
            className={`${redBorderFields.includes(`${wallLengthFeetMessage}`) ? "redBackground" : ""}`}
            style={{ flex: 1, maxWidth: "25%" }}
            onChange={(e) => {
              handleWallLengthFeetChange(
                setWallLengthsFeet,
                wallLengthsFeet,
                wallLengthFeetMessage,
                index,
                e.target.value,
              );
              setLastWallLengthUpdate(Date.now());
            }}
            value={wallLengthsFeet[index]}
            maxLength={3}
            pattern="\d*"
          ></input>
          <span style={{ flex: 1, maxWidth: "20%" }}>Inches:</span>
          <input
            className={`${redBorderFields.includes(`${wallLengthInchesMessage}`) ? "redBackground" : ""}`}
            style={{ flex: 1, maxWidth: "25%" }}
            onChange={(e) =>
              handleWallLengthInchesChange(
                setWallLengthsInches,
                wallLengthsInches,
                wallLengthInchesMessage,
                index,
                e.target.value,
              )
            }
            value={wallLengthsInches[index]}
          ></input>
        </div>
      </label>
    </div>
  );
}

WallLength.propTypes = {
  wallLengthsFeet: PropTypes.array,
  wallLengthsInches: PropTypes.array,
  redBorderFields: PropTypes.array,
  index: PropTypes.number,
  handleWallLengthFeetChange: PropTypes.func,
  handleWallLengthInchesChange: PropTypes.func,
  setWallLengthsFeet: PropTypes.func,
  wallLengthMessage: PropTypes.string,
  wallLengthInchesMessage: PropTypes.string,
  setWallLengthsInches: PropTypes.func,
  wallLengthFeetMessage: PropTypes.string,
  setLastWallLengthUpdate: PropTypes.func,
};

export default WallLength;
