import React from "react";
import PropTypes from "prop-types";
import CustomDropdown from "./CustomDropdown";
import DoorHardware from "../UniversalWalls/DoorHardware";
const FrontBays = ({
  redBorderFields,
  setFieldsWithRedBorder,
  handleBays,
  selectedDoorHardware,
  setSelectedDoorHardware,
  setShowDoorHardware,
  index,
  selectedPavilionLayoutOption,
  handleShowDoorHardwareClick,
  SSHinges,
  handleSSHingesOptionChange,
  showDoorHardware,
  selectedSunRoomType,
  handleSelectedScreenDoorWidthChoice,
  doorWidth,
  setSelectedOption,
  selectedOption,
}) => {
  const options = Array.from({ length: 15 }, (_, i) => {
    const bays = i + 2;
    const multiple = bays * 44.5 + 2;
    const totalFeet = multiple / 12;
    const feet = Math.floor(totalFeet);
    const inches = ((totalFeet - feet) * 12).toFixed(1);
    return {
      bays: `${bays} BAY`,
      dimensions: `(${feet}' ${inches}")`,
    };
  });

  const handleDropdownChange = (option) => {
    const selected = option;
    const updatedRedBorderFields = redBorderFields.includes("Bays")
      ? selected
        ? redBorderFields.filter((field) => field !== "Bays")
        : redBorderFields
      : selected
        ? redBorderFields
        : [...redBorderFields, "Bays"];

    setFieldsWithRedBorder(updatedRedBorderFields);
    handleBays(selected);
  };

  return (
    <div
      style={{
        flex: 0.5,
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-around",
        height: "100%",
      }}
    >
      <DoorHardware
        selectedDoorHardware={selectedDoorHardware}
        setSelectedDoorHardware={setSelectedDoorHardware}
        setShowDoorHardware={setShowDoorHardware}
        setFieldsWithRedBorder={setFieldsWithRedBorder}
        index={index}
        selectedPavilionLayoutOption={selectedPavilionLayoutOption}
        doorWidth={doorWidth}
        handleShowDoorHardwareClick={handleShowDoorHardwareClick}
        SSHinges={SSHinges}
        handleSSHingesOptionChange={handleSSHingesOptionChange}
        redBorderFields={redBorderFields}
        showDoorHardware={showDoorHardware}
        selectedSunRoomType={selectedSunRoomType}
        handleSelectedScreenDoorWidthChoice={
          handleSelectedScreenDoorWidthChoice
        }
      />
      <div
        className="popupwindow"
        style={{
          display: "grid",
          justifySelf: "center",
          alignSelf: "center",
          marginTop: "20%",
          width: "100%",
        }}
      >
        <h6
          className={
            "headers " +
            (redBorderFields.includes("Bays")
              ? "redBackground"
              : "greenBackground")
          }
        >
          <span className="header-grid-location">
            <span className="redasterisk">&#10033;</span>
            Front Bays
          </span>
        </h6>
        <CustomDropdown
          options={options}
          onChange={handleDropdownChange}
          setSelectedOption={setSelectedOption}
          selectedOption={selectedOption}
        />
      </div>
    </div>
  );
};

FrontBays.propTypes = {
  redBorderFields: PropTypes.array,
  setFieldsWithRedBorder: PropTypes.func,
  handleBays: PropTypes.func,
  lengthFeet: PropTypes.string,
  lengthInches: PropTypes.string,
  selectedDoorHardware: PropTypes.string,
  setSelectedDoorHardware: PropTypes.func,
  setShowDoorHardware: PropTypes.func,
  index: PropTypes.number,
  selectedPavilionLayoutOption: PropTypes.string,
  doorWidth: PropTypes.array,
  handleShowDoorHardwareClick: PropTypes.func,
  SSHinges: PropTypes.string,
  handleSSHingesOptionChange: PropTypes.func,
  showDoorHardware: PropTypes.bool,
  selectedSunRoomType: PropTypes.string,
  handleSelectedScreenDoorWidthChoice: PropTypes.func,
  setSelectedOption: PropTypes.func,
  selectedOption: PropTypes.string,
};

export default FrontBays;
