import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import projectionCodes from "./GlassProjectionCodes.json";
import cathedralCodes from "./GlassFrontWallCathedral.json";
import studioCodes from "./GlassFrontWallStudio.json";

function ValueViewDrawingModal({
  setIsModalOpen,
  index,
  doorOption,
  doorWidth,
  selectedRoomStyle,
  onCodeSelect,
  setSelectedImages,
  selectedButtonIndex,
  setSelectedButtonIndex,
  wallLengthsFeet,
  selectedPavilionLayoutOption,
  selectedBreezeWay,
  initialSelectedCodes,
  setNewSelectedCodes,
  redBorderField,
}) {
  const [selectedMessage, setSelectedMessage] = useState(null);
  const [autoSelectedCodes, setAutoSelectedCodes] = useState([]);
  const [fetchedImages, setFetchedImages] = useState({});

  const handleButtonClick = () => {
    setIsModalOpen(false);
  };

  const handleRadioChange = (radioIndex) => {
    setSelectedMessage(radioIndex);
    const selectedCode = autoSelectedCodes.flat()[radioIndex];
    onCodeSelect(
      selectedCode,
      initialSelectedCodes,
      index,
      redBorderField,
      setNewSelectedCodes,
    );
    const selectedImage = fetchedImages[selectedCode];
    setSelectedImages((prevImages) => {
      const newImages = [...prevImages];
      newImages[index] = selectedImage;
      return newImages;
    });
    setSelectedButtonIndex((previousButtons) => {
      const newIndices = [...previousButtons];
      newIndices[index] = radioIndex;
      return newIndices;
    });
  };

  useEffect(() => {
    if (autoSelectedCodes.length === 0) {
      let newLength = "";
      let codes = {};
      let adjustedLength = wallLengthsFeet[index];

      if ((index === 0 || index === 2) && wallLengthsFeet[index] >= 20) {
        adjustedLength = 20;
      } else if (index === 1 && wallLengthsFeet[index] >= 30) {
        adjustedLength = 30;
      }

      if (selectedPavilionLayoutOption !== "3") {
        if ((selectedBreezeWay === "Yes" && index === 1) || index !== 1) {
          newLength = "Projection: " + adjustedLength + "'";
          codes = projectionCodes;
        } else if (selectedBreezeWay !== "Yes" && index === 1) {
          if (selectedRoomStyle === "CATHEDRAL") {
            newLength = "Front Wall Cathedral: " + adjustedLength + "'";
            codes = cathedralCodes;
          } else if (selectedRoomStyle !== "CATHEDRAL") {
            newLength = "Front Wall Studio: " + adjustedLength + "'";
            codes = studioCodes;
          }
        }
      } else if (selectedPavilionLayoutOption === "3") {
        if (selectedRoomStyle === "CATHEDRAL") {
          newLength = "Front Wall Cathedral: " + adjustedLength + "'";
          codes = cathedralCodes;
        } else if (selectedRoomStyle !== "CATHEDRAL") {
          newLength = "Front Wall Studio: " + adjustedLength + "'";
          codes = studioCodes;
        }
      }

      let autoSelectedCodes = [];
      if (doorOption[index] === "No" || adjustedLength <= 4) {
        if (codes[newLength] && codes[newLength]["Solid Fill Panel"]) {
          autoSelectedCodes.push(codes[newLength]["Solid Fill Panel"]);
        }
        if (codes[newLength] && codes[newLength]["All Glass"]) {
          autoSelectedCodes.push(codes[newLength]["All Glass"]);
        }
      } else if (doorOption[index] === "Yes") {
        if (
          codes[newLength] &&
          codes[newLength][doorWidth[index]] &&
          codes[newLength][doorWidth[index]]["At Left Projection"]
        ) {
          autoSelectedCodes.push(
            codes[newLength][doorWidth[index]]["At Left Projection"],
          );
        }
        if (
          codes[newLength] &&
          codes[newLength][doorWidth[index]] &&
          codes[newLength][doorWidth[index]]["Existing Home"]
        ) {
          autoSelectedCodes.push(
            codes[newLength][doorWidth[index]]["Existing Home"],
          );
        }
        if (
          codes[newLength] &&
          codes[newLength][doorWidth[index]] &&
          codes[newLength][doorWidth[index]]["Centered"]
        ) {
          autoSelectedCodes.push(
            codes[newLength][doorWidth[index]]["Centered"],
          );
        }
        if (
          codes[newLength] &&
          codes[newLength][doorWidth[index]] &&
          codes[newLength][doorWidth[index]]["At Right Projection"]
        ) {
          autoSelectedCodes.push(
            codes[newLength][doorWidth[index]]["At Right Projection"],
          );
        }
        if (
          codes[newLength] &&
          codes[newLength][doorWidth[index]] &&
          codes[newLength][doorWidth[index]]["Away From House"]
        ) {
          autoSelectedCodes.push(
            codes[newLength][doorWidth[index]]["Away From House"],
          );
        }
      }

      setAutoSelectedCodes(autoSelectedCodes);
    }
  }, [
    index,
    selectedRoomStyle,
    doorOption,
    doorWidth,
    autoSelectedCodes.length,
    autoSelectedCodes,
    wallLengthsFeet,
    selectedPavilionLayoutOption,
    selectedBreezeWay,
    setAutoSelectedCodes,
  ]);

  const fetchedImagesCache = useRef({});

  useEffect(() => {
    const createImagePaths = async () => {
      const newFetchedImages = { ...fetchedImagesCache.current };
      const flatSelectedCodes = autoSelectedCodes.flat();
      const codes = flatSelectedCodes.filter(
        (code) => code && !fetchedImagesCache.current[code],
      );
      if (codes.length > 0) {
        for (const code of codes) {
          const module = await import(`./GlassImages/${code}.jpg`);
          newFetchedImages[code] = module.default;
        }
      }
      fetchedImagesCache.current = newFetchedImages;
      setFetchedImages(newFetchedImages);
    };
    createImagePaths();
  }, [autoSelectedCodes]);

  let radioButtonIndex = 0;

  return (
    <div
      style={{
        zIndex: 2000,
        position: "fixed",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "rgba(0, 0, 0, 0.5)",
      }}
    >
      <div className="modal">
        <div
          className="modal-content"
          style={{ width: "101%", height: "101%", overflowY: "auto" }}
        >
          {selectedMessage === null && (
            <button
              className="navbutton"
              style={{
                position: "absolute",
                top: "1%",
                right: "1%",
                cursor: "pointer",
                zIndex: 200,
              }}
              onClick={handleButtonClick}
            >
              X
            </button>
          )}
          {selectedMessage !== null && (
            <button
              className="navbutton"
              style={{
                position: "absolute",
                top: "1%",
                right: "1%",
                cursor: "pointer",
              }}
              onClick={handleButtonClick}
            >
              Done
            </button>
          )}
          <div
            className="image-container2"
            style={{
              display: "grid",
              gridTemplateColumns: "repeat(3, 1fr)",
              gap: "1rem",
              justifyContent: "center",
              alignItems: "center",
              marginTop: autoSelectedCodes.length > 3 ? "1vh" : "10vh",
              width: "100%",
              height: "100%",
            }}
          >
            {autoSelectedCodes.flatMap((subArray, subArrayIndex) =>
              subArray.map((code, radioIndex) => {
                const currentRadioButtonIndex = radioButtonIndex;
                radioButtonIndex++;
                return (
                  <button
                    key={`${subArrayIndex}-${radioIndex}`}
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                      width: "fit-content",
                      height: "fit-content",
                      border: "none",
                      background: "none",
                      backgroundColor: "transparent",
                    }}
                    onClick={() => handleRadioChange(currentRadioButtonIndex)}
                  >
                    <div style={{ position: "relative" }}>
                      {currentRadioButtonIndex ===
                        (selectedButtonIndex && selectedButtonIndex[index]) && (
                        <div
                          style={{
                            position: "absolute",
                            top: 0,
                            left: 0,
                            width: "100%",
                            height: "100%",
                            backgroundColor: "rgba(173, 216, 230, 0.5)",
                            pointerEvents: "none",
                          }}
                        />
                      )}
                      <img
                        style={{
                          height: "45vh",
                          width: "45vh",
                        }}
                        src={fetchedImages[code]}
                        alt={code}
                      />
                    </div>
                    <br></br>
                    <p>{code}</p>
                  </button>
                );
              }),
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

ValueViewDrawingModal.propTypes = {
  setIsModalOpen: PropTypes.func,
  index: PropTypes.number,
  doorOption: PropTypes.array,
  doorWidth: PropTypes.array,
  selectedRoomStyle: PropTypes.string,
  setSelectedWallCodes: PropTypes.func,
  onCodeSelect: PropTypes.func,
  setSelectedImages: PropTypes.func,
  selectedImages: PropTypes.array,
  selectedButtonIndex: PropTypes.number,
  setSelectedButtonIndex: PropTypes.func,
  wallLengthsFeet: PropTypes.array,
  selectedPavilionLayoutOption: PropTypes.string,
  selectedBreezeWay: PropTypes.string,
  autoSelectedCodes: PropTypes.array,
  setAutoSelectedCodes: PropTypes.func,
  redBorderField: PropTypes.string,
  initialSelectedCodes: PropTypes.array,
  setNewSelectedCodes: PropTypes.func,
};

export default ValueViewDrawingModal;
