import React, { useState } from "react";
import InfoIcon from "../UniversalComponents/InfoIcon";
import PropTypes from "prop-types";

const SkinType = ({
  handleSkinTypeOptionChange,
  redBorderFields,
  setFieldsWithRedBorder,
}) => {
  const [SkinTypeSelectedOption, setSkinTypeSelectedOption] = useState("");

  const handleSkinTypeChange = (pickedoption) => {
    setSkinTypeSelectedOption(pickedoption);
    const updatedRedBorderFields = redBorderFields.includes("Skin Type")
      ? pickedoption
        ? redBorderFields.filter((field) => field !== "Skin Type")
        : redBorderFields
      : pickedoption
        ? redBorderFields
        : [...redBorderFields, "Skin Type"];

    setFieldsWithRedBorder(updatedRedBorderFields);
    handleSkinTypeOptionChange(pickedoption);
  };

  return (
    <div className="popupwindow bihex-stuff" style={{ marginTop: "1%" }}>
      <h6
        className={
          "headers " +
          (redBorderFields.includes("Skin Type")
            ? "redBackground"
            : "greenBackground")
        }
      >
        <span className="header-grid-location">
          <span className="redasterisk">&#10033;</span>
          Wall Skin Type
        </span>
        <InfoIcon defaultMessage="TempKor or Aluminum fill for corners and for areas above and below wall and door frames." />
      </h6>
      <div className="clickable-words">
        <div
          className={`clickable-word ${SkinTypeSelectedOption === "ALUMINUM" ? "clicked" : ""}`}
          onClick={() => handleSkinTypeChange("ALUMINUM")}
        >
          Alum.
        </div>
        &nbsp;
        <div
          className={`clickable-word ${SkinTypeSelectedOption === "TEMKOR" ? "clicked" : ""}`}
          onClick={() => handleSkinTypeChange("TEMKOR")}
        >
          TemKor
        </div>
      </div>
    </div>
  );
};

SkinType.propTypes = {
  handleSkinTypeOptionChange: PropTypes.func,
  redBorderFields: PropTypes.array,
  setFieldsWithRedBorder: PropTypes.func,
};

export default SkinType;
