import React, { useState } from "react";
import ImageButton from "../../UniversalComponents/ImageButton";
import KneeWall from "../UniversalViewingImages/KneeWall.jpg";
import NoKneeWall from "../UniversalViewingImages/NoKneeWall.jpg";
import PropTypes from "prop-types";

const KneeWallSelection = ({
  handleKneeWallChange,
  handleKneeWallFeetChange,
  handleKneeWallInchesChange,
  redBorderFields,
  setFieldsWithRedBorder,
  setInitialKneeWallOption,
}) => {
  const [inchesKneeWallOption, setInchesKneeWallOption] = useState("");
  const [feetKneeWallValue, setFeetKneeWallValue] = useState("");
  const [selectedKneeWallOption, setSelectedKneeWallOption] = useState("");
  const images = [KneeWall, NoKneeWall];
  const messages = ["Knee Wall", "No Knee Wall"];

  const handleKneeWallOptionChange = (pickedoption) => {
    if (pickedoption === "no") {
      // If 'no' is selected, reset the feet and inches data
      setFeetKneeWallValue("");
      setInchesKneeWallOption("");
      // Also update the state or props where these values are being used
      handleKneeWallFeetChange("");
      handleKneeWallInchesChange("");
    }
    setSelectedKneeWallOption(pickedoption);
    const updatedRedBorderFields = redBorderFields.includes("knee wall option")
      ? pickedoption
        ? redBorderFields.filter((field) => field !== "knee wall option")
        : redBorderFields
      : pickedoption
        ? redBorderFields
        : [...redBorderFields, "knee wall option"];

    setFieldsWithRedBorder(updatedRedBorderFields);
    handleKneeWallChange(pickedoption);
    setInitialKneeWallOption(pickedoption);
  };

  const handleFeetKneeWallChange = (event) => {
    const input = event.target.value.replace(/\D/g, "").slice(0, 3);
    setFeetKneeWallValue(input);
    const updatedRedBorderFields = redBorderFields.includes(
      "Select Knee Wall Feet",
    )
      ? input
        ? redBorderFields.filter((field) => field !== "Select Knee Wall Feet")
        : redBorderFields
      : input
        ? redBorderFields
        : [...redBorderFields, "Select Knee Wall Feet"];

    setFieldsWithRedBorder(updatedRedBorderFields);
    handleKneeWallFeetChange(input);
  };

  const handleInchesKneeWallChange = (event) => {
    let input = event.target.value;
    input = input.replace(/[^0-9]/g, ""); // Updated to remove periods

    const numericInput = parseInt(input, 10); // Parse input as integer
    const isValidNumber = !isNaN(numericInput) && numericInput < 12;

    if (input !== "" && !isValidNumber) {
      if (numericInput >= 12) {
        input = "11"; // Updated to set to the max integer value
      } else {
        input = numericInput.toString();
      }
    }

    setInchesKneeWallOption(input);

    const updatedRedBorderFields = redBorderFields.includes(
      "Select Knee Wall Inches",
    )
      ? isValidNumber
        ? redBorderFields.filter((field) => field !== "Select Knee Wall Inches")
        : redBorderFields
      : isValidNumber
        ? [...redBorderFields]
        : [...redBorderFields, "Select Knee Wall Inches"];

    setFieldsWithRedBorder(updatedRedBorderFields);
    handleKneeWallInchesChange(input);
  };
  return (
    <div className="popupwindow kneewall-stuff">
      <h6
        className={
          "headers " +
          (redBorderFields.includes("knee wall option")
            ? "redBackground"
            : "greenBackground")
        }
      >
        <span className="header-grid-location">
          <span className="redasterisk">&#10033;</span>
          Existing Knee Wall
        </span>
        <ImageButton images={images} messages={messages} />
      </h6>
      <div className="clickable-words">
        <div
          className={`clickable-word ${selectedKneeWallOption === "yes" ? "clicked" : ""}`}
          onClick={() => handleKneeWallOptionChange("yes")}
        >
          Yes
        </div>
        <div
          className={`clickable-word ${selectedKneeWallOption === "no" ? "clicked" : ""}`}
          onClick={() => handleKneeWallOptionChange("no")}
        >
          No
        </div>
        {selectedKneeWallOption === "yes" && (
          <form
            id="kneewalldimensions"
            className="fontsizeforinputsrow"
            onSubmit={(e) => e.preventDefault()} // Add this line
          >
            <div className="fontsizeforinputsrow">
              <span className="fontsizeforinputsrow">
                <span className="redasterisk">&#10033;</span>
                Feet:
              </span>
              <input
                id="kneewallfeet"
                className={
                  "personalinputwidths " +
                  (redBorderFields.includes("Select Knee Wall Feet")
                    ? "redBackground"
                    : "whiteBackground")
                }
                value={feetKneeWallValue}
                onChange={handleFeetKneeWallChange}
                maxLength={3}
                pattern="\d*"
              />
            </div>
            <div className="fontsizeforinputsrow">
              <span className="fontsizeforinputsrow">
                <span className="redasterisk">&#10033;</span>
                Inch:
              </span>
              <input
                id="kneewallinches"
                className={
                  "personalinputwidths " +
                  (redBorderFields.includes("Select Knee Wall Inches")
                    ? "redBackground"
                    : "whiteBackground")
                }
                value={inchesKneeWallOption}
                onChange={handleInchesKneeWallChange}
              />
            </div>
          </form>
        )}
      </div>
    </div>
  );
};

KneeWallSelection.propTypes = {
  handleKneeWallChange: PropTypes.func,
  handleKneeWallFeetChange: PropTypes.func,
  handleKneeWallInchesChange: PropTypes.func,
  redBorderFields: PropTypes.array,
  setFieldsWithRedBorder: PropTypes.func,
  setInitialKneeWallOption: PropTypes.func,
};

export default KneeWallSelection;
