import React, { useState } from "react";
import InfoIcon from "../UniversalComponents/InfoIcon";
import PropTypes from "prop-types";

const VertChannel = ({
  redBorderFields,
  setFieldsWithRedBorder,
  handleVertChannelOption,
}) => {
  const [vertChannelOption, setVertChannelOption] = useState("");

  const handleDropdownChange = (event) => {
    const selected = event.target.value;
    setVertChannelOption(selected);
    const updatedRedBorderFields = redBorderFields.includes("vert channel")
      ? selected
        ? redBorderFields.filter((field) => field !== "vert channel")
        : redBorderFields
      : selected
        ? redBorderFields
        : [...redBorderFields, "vert channel"];
    if (vertChannelOption === "Other") {
      alert(
        "Type your Vertical Channel request into the Special Instructions boxes AND please EMAIL A SKETCH.",
      );
    }

    setFieldsWithRedBorder(updatedRedBorderFields);
    handleVertChannelOption(selected);
  };

  return (
    <div className="popupwindow" style={{ width: "50%", marginLeft: "1%" }}>
      <form>
        <h6
          className={
            "headers " +
            (redBorderFields.includes("vert channel")
              ? "redBackground"
              : "greenBackground")
          }
        >
          <InfoIcon defaultMessage='5 1/4" wide mullion w/ cap.<br/>Normally used for catch side of door.<br/>Goes form floor to ceiling.' />
          <span className="header-grid-location">
            <span className="redasterisk">&#10033;</span>
            Vert Channel
          </span>
        </h6>
        <span className="fontsizeforinputs">
          <select
            id="vertchannel"
            className="input-box "
            style={{ width: "90%" }}
            onChange={handleDropdownChange}
          >
            <option value={""}>Select</option>
            {[
              "Not Needed",
              "1 Per Door",
              "Both Sides Of Door",
              "Between All Panels",
              "Front Wall Only",
              "Projections Only",
              "Other",
            ].map((option, index) => (
              <option key={index} value={option}>
                {option}
              </option>
            ))}
          </select>
        </span>
      </form>
    </div>
  );
};

VertChannel.propTypes = {
  redBorderFields: PropTypes.array,
  setFieldsWithRedBorder: PropTypes.func,
  handleVertChannelOption: PropTypes.func,
};

export default VertChannel;
