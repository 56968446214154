import React, { useState } from "react";
import PropTypes from "prop-types";
import ImageButton from "../UniversalComponents/ImageButton";
import Cathedral from "./SunRoomImages/Cathedral.jpg";
import Studio from "./SunRoomImages/Studio.jpg";
import Existing_Roof from "./SunRoomImages/Existing_Roof.jpg";

const RoomStyle = ({
  handleSelectedRoomStyle,
  redBorderFields,
  setFieldsWithRedBorder,
  selectedSunRoomType,
}) => {
  const [selectedRoomStyle, setSelectedRoomStyle] = useState("");
  const images = [Studio, Cathedral, Existing_Roof];

  const messages = ["Studio", "Cathedral", "Existing Roof"];

  const handleRoomStyleChange = (selected) => {
    setSelectedRoomStyle(selected);
    const updatedRedBorderFields = redBorderFields.includes("ROOM STYLE")
      ? selected
        ? redBorderFields.filter((field) => field !== "ROOM STYLE")
        : redBorderFields
      : selected
        ? redBorderFields
        : [...redBorderFields, "ROOM STYLE"];

    setFieldsWithRedBorder(updatedRedBorderFields);
    handleSelectedRoomStyle(selected);
  };

  return (
    <div className="popupwindow" style={{ width: "100%", marginBottom: "1%" }}>
      <h6
        className={
          "headers " +
          (redBorderFields.includes("ROOM STYLE")
            ? "redBackground"
            : "greenBackground")
        }
      >
        <ImageButton images={images} messages={messages} />
        <span className="header-grid-location">
          <span className="redasterisk">&#10033;</span>
          Room Style
        </span>
      </h6>
      <div className="clickable-words">
        <div
          className={`clickable-word ${selectedRoomStyle === "STUDIO" ? "clicked" : ""}`}
          onClick={() => handleRoomStyleChange("STUDIO")}
        >
          Studio
        </div>
        <div
          className={`clickable-word ${selectedRoomStyle === "CATHEDRAL" ? "clicked" : ""}`}
          onClick={() => handleRoomStyleChange("CATHEDRAL")}
          style={{ marginRight: "1%" }}
        >
          Cathedral
        </div>
        {selectedSunRoomType !== "Solarium" && (
          <div
            className={`clickable-word ${selectedRoomStyle === "Existing Roof" ? "clicked" : ""}`}
            onClick={() => handleRoomStyleChange("Existing Roof")}
            style={{ marginRight: "1%" }}
          >
            Existing Roof
          </div>
        )}
      </div>
    </div>
  );
};

RoomStyle.propTypes = {
  handleSelectedRoomStyle: PropTypes.func,
  redBorderFields: PropTypes.array,
  setFieldsWithRedBorder: PropTypes.func,
  selectedSunRoomType: PropTypes.string,
};

export default RoomStyle;
