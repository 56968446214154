import React, { useState } from "react";
import ImageButton from "../../UniversalComponents/ImageButton";
import TEMO_Panel from "../PavilionViewingImages/TEMO_Panel.JPG";
import ST_Thomas from "../PavilionViewingImages/STTHOMAS.jpg";
import Santa_FE from "../PavilionViewingImages/SANTAFE.jpg";
import PropTypes from "prop-types";

const PavilionStyle = ({
  handlePavilionStyleOptionChange,
  redBorderFields,
  setFieldsWithRedBorder,
}) => {
  const [selectedPavilionOption, setSelectedPavilionOption] = useState("");
  const images = [TEMO_Panel, ST_Thomas, Santa_FE];
  const messages = ["TEMO Panel", "ST Thomas", "Santa FE"];

  const handlePavilionOptionChange = (selected) => {
    const optionChange = selected;
    const updatedRedBorderFields = redBorderFields.includes("Pavilion Style")
      ? selected
        ? redBorderFields.filter((field) => field !== "Pavilion Style")
        : redBorderFields
      : selected
        ? redBorderFields
        : [...redBorderFields, "Pavilion Style"];

    setFieldsWithRedBorder(updatedRedBorderFields);
    setSelectedPavilionOption(optionChange);
    handlePavilionStyleOptionChange(optionChange);
  };

  return (
    <div className="popupwindow pavilionstyle-choices">
      <h6
        className={
          "headers " +
          (redBorderFields.includes("Pavilion Style")
            ? "redBackground"
            : "greenBackground")
        }
      >
        <ImageButton images={images} messages={messages} />
        <span className="header-grid-location">
          <span className="redasterisk">&#10033;</span>
          Pavilion Style Choices
        </span>
      </h6>
      <div className="clickable-words">
        <div
          className={`clickable-word ${selectedPavilionOption === "TEMO Panel" ? "clicked" : ""}`}
          onClick={() => handlePavilionOptionChange("TEMO Panel")}
        >
          TEMO Panel
        </div>
        <div
          className={`clickable-word ${selectedPavilionOption === "ST Thomas" ? "clicked" : ""}`}
          onClick={() => handlePavilionOptionChange("ST Thomas")}
        >
          ST Thomas
        </div>
        &nbsp;
        <div
          className={`clickable-word ${selectedPavilionOption === "Santa FE" ? "clicked" : ""}`}
          onClick={() => handlePavilionOptionChange("Santa FE")}
        >
          Santa FE
        </div>
      </div>
    </div>
  );
};

PavilionStyle.propTypes = {
  handlePavilionStyleOptionChange: PropTypes.func,
  redBorderFields: PropTypes.array,
  setFieldsWithRedBorder: PropTypes.func,
};

export default PavilionStyle;
