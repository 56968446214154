import React from "react";
import { Link } from "react-router-dom";
import sunroomimg from "../../components/OrderingSystemComponents/Sunroom.jpg";
import pergolaimg from "../../components/OrderingSystemComponents/Pergola.jpg";
import screenroomimg from "../../components/OrderingSystemComponents/ScreenRoom.jpg";
import pavilionimg from "../../components/OrderingSystemComponents/Pavilion.jpg";
import sparepartimg from "../../components/OrderingSystemComponents/SparePart.jpg";

const Ordering = () => {
  return (
    <div className="image-container">
      <Link className="orderinglink" to="/SunRooms">
        <div className="image-with-overlay">
          <img src={sunroomimg} className="orderingimage" alt={"sunroomimg"} />
          <div className="overlay"></div>
          <h1 className="popupwindow h1">3 5/8&quot; Sunrooms</h1>
        </div>
      </Link>
      <Link className="orderinglink" to="/Pavilions">
        <div className="image-with-overlay">
          <img
            src={pavilionimg}
            className="orderingimage"
            alt={"pavilionimg"}
          />
          <div className="overlay"></div>
          <h1 className="popupwindow h1">Pavilions</h1>
        </div>
      </Link>
      <Link className="orderinglink" to="/ScreenRooms">
        <div className="image-with-overlay">
          <img
            src={screenroomimg}
            className="orderingimage"
            alt={"screenroomimg"}
          />
          <div className="overlay"></div>
          <h1 className="popupwindow h1">2&quot; Sunrooms</h1>
        </div>
      </Link>
      <Link className="orderinglink" to="/Pergolas">
        <div className="image-with-overlay">
          <img src={pergolaimg} className="orderingimage" alt={"pergolaimg"} />
          <div className="overlay"></div>
          <h1 className="popupwindow h1">Pergolas</h1>
        </div>
      </Link>
      <div className="orderinglink">
        <div className="image-with-overlay">
          <img
            src={sparepartimg}
            className="orderingimage"
            alt={"sparepartimg"}
          />
          <div className="overlay"></div>
          <div className="stamp">Under Construction</div>
          <h1 className="popupwindow h1">Spare Parts</h1>
        </div>
      </div>
    </div>
  );
};

export default Ordering;
