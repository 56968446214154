import React, { useState } from "react";
import ImageButton from "../../UniversalComponents/ImageButton";
import Free_Standing from "../PavilionViewingImages/FreeStandingPavilion.JPG";
import Attached from "../PavilionViewingImages/AttachedPavilion.jpg";
import PropTypes from "prop-types";

const FreesStandingorAttached = ({
  onAttachedOptionChange,
  redBorderFields,
  setFieldsWithRedBorder,
}) => {
  const [selectedAttachedOption, setSelectedAttachedOption] = useState("");
  const images = [Free_Standing, Attached];
  const messages = ["Free-Standing", "Attached"];

  const handleAttachedOptionChange = (selected) => {
    setSelectedAttachedOption(selected);
    const updatedRedBorderFields = redBorderFields.includes(
      "Free-Standing or Attached",
    )
      ? selected
        ? redBorderFields.filter(
            (field) => field !== "Free-Standing or Attached",
          )
        : redBorderFields
      : selected
        ? redBorderFields
        : [...redBorderFields, "Free-Standing or Attached"];

    setFieldsWithRedBorder(updatedRedBorderFields);
    onAttachedOptionChange(selected);
  };

  return (
    <div className="popupwindow freestandingorattached">
      <h6
        className={
          "headers " +
          (redBorderFields.includes("Free-Standing or Attached")
            ? "redBackground"
            : "greenBackground")
        }
      >
        <ImageButton images={images} messages={messages} />
        <span className="header-grid-location">
          <span className="redasterisk">&#10033;</span>
          Free&#45;Standing&nbsp;or&nbsp;Attached
        </span>
      </h6>
      <div className="clickable-words">
        <div
          className={`clickable-word ${selectedAttachedOption === "FREE-STANDING" ? "clicked" : ""}`}
          onClick={() => handleAttachedOptionChange("FREE-STANDING")}
        >
          Free-Standing
        </div>
        <div
          className={`clickable-word ${selectedAttachedOption === "attached" ? "clicked" : ""}`}
          onClick={() => handleAttachedOptionChange("attached")}
        >
          Attached
        </div>
      </div>
    </div>
  );
};

FreesStandingorAttached.propTypes = {
  onAttachedOptionChange: PropTypes.func,
  redBorderFields: PropTypes.array,
  setFieldsWithRedBorder: PropTypes.func,
};

export default FreesStandingorAttached;
