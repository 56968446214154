import React from "react";
import PropTypes from "prop-types";
import NoHardware from "./DoorOrientationImages/NoHardware.jpg";
import Satin from "./DoorOrientationImages/SatinHardware.jpg";
import OilRubbedBronze from "./DoorOrientationImages/OilRubbedBronze.jpg";
import StainlessSteelHinges from "./StainlessSteelHinges";
import ScreenDoorWidth from "../../../ScreenRoomsPageComponents/ScreenDoorWidth";

function DoorHardware({
  selectedDoorHardware,
  setSelectedDoorHardware,
  setShowDoorHardware,
  setFieldsWithRedBorder,
  index,
  selectedPavilionLayoutOption,
  doorWidth,
  handleShowDoorHardwareClick,
  SSHinges,
  handleSSHingesOptionChange,
  redBorderFields,
  showDoorHardware,
  selectedSunRoomType,
  handleSelectedScreenDoorWidthChoice,
  selectedBreezeWay,
}) {
  const handleSelectedHardwareOption = (selectedHardwareOption) => {
    setSelectedDoorHardware(selectedHardwareOption);
    setFieldsWithRedBorder((prevRedBorderFields) => {
      if (selectedHardwareOption) {
        return prevRedBorderFields.filter((field) => field !== "Door Hardware");
      } else {
        return [...prevRedBorderFields, "Door Hardware"];
      }
    });
  };

  const handleCloseModal = () => {
    setShowDoorHardware(false);
  };

  return (
    <>
      {((selectedPavilionLayoutOption !== "3" && index === 1) ||
        (selectedPavilionLayoutOption === "3" && index === 0)) &&
      (doorWidth[0] === "3' Door" ||
        doorWidth[0] === "3.5' Door" ||
        doorWidth[1] === "3' Door" ||
        doorWidth[1] === "3.5' Door" ||
        doorWidth[2] === "3' Door" ||
        doorWidth[2] === "3.5' Door") ? (
        <div
          style={{
            width: "100%",
            display: "flex",
            height: "7vh",
            alignSelf: "start",
            justifyContent: "space-between",
            alignItems: "center",
            flexDirection: "column",
            gap: "1vh",
          }}
        >
          <div
            style={{
              display: "flex",
              flex: 1,
              flexDirection: "row",
            }}
          >
            <button
              className={`navbutton ${redBorderFields.includes("Door Hardware") ? "redBackground" : "greenBackground"}`}
              style={{
                flex: 1,
                fontSize: "1.4vh",
                height: "100%",
                marginRight: "1%",
              }}
              onClick={handleShowDoorHardwareClick}
            >
              {selectedDoorHardware === "" && (
                <span>Swing&nbsp;Door Hardware</span>
              )}
              {selectedDoorHardware !== "" && (
                <span style={{ display: "flex", flexDirection: "column" }}>
                  You Selected
                  <br />
                  {selectedDoorHardware}
                </span>
              )}
            </button>
            <StainlessSteelHinges
              SSHinges={SSHinges}
              handleSSHingesOptionChange={handleSSHingesOptionChange}
              redBorderFields={redBorderFields}
            />
          </div>
          {selectedSunRoomType === "Linear Screen" && (
            <ScreenDoorWidth
              handleSelectedScreenDoorWidthChoice={
                handleSelectedScreenDoorWidthChoice
              }
              redBorderFields={redBorderFields}
              setFieldsWithRedBorder={setFieldsWithRedBorder}
              selectedBreezeWay={selectedBreezeWay}
              selectedPavilionLayoutOption={selectedPavilionLayoutOption}
            />
          )}
        </div>
      ) : (
        <div
          style={{
            width: "100%",
            display: "flex",
            height: "7vh",
            alignSelf: "start",
            justifyContent: "space-between",
            alignItems: "center",
            flexDirection: "column",
            gap: "1vh",
          }}
        ></div>
      )}
      {showDoorHardware && (
        <div
          style={{
            zIndex: 2000,
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
          }}
        >
          <div className="modal">
            <div
              className="modal-content"
              style={{
                width: "101%",
                height: "101%",
                overflowY: "auto",
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-around",
                alignItems: "center",
              }}
            >
              {selectedDoorHardware === null && (
                <button
                  className="navbutton"
                  style={{
                    position: "absolute",
                    top: "1%",
                    right: "1%",
                    cursor: "pointer",
                    zIndex: 200,
                  }}
                  onClick={handleCloseModal}
                >
                  X
                </button>
              )}
              {selectedDoorHardware !== null && (
                <button
                  className="navbutton"
                  style={{
                    position: "absolute",
                    top: "1%",
                    right: "1%",
                    cursor: "pointer",
                    zIndex: 200,
                  }}
                  onClick={handleCloseModal}
                >
                  Done
                </button>
              )}
              <div style={{ height: "90%" }}>
                <button
                  style={{
                    height: "100%",
                    width: "100%",
                    padding: "0",
                    position: "relative",
                  }}
                  onClick={() => handleSelectedHardwareOption("No Hardware")}
                >
                  <img
                    src={NoHardware}
                    alt="NoHardware"
                    style={{ height: "100%", width: "100%" }}
                  />
                  {selectedDoorHardware === "No Hardware" && (
                    <div
                      style={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        width: "100%",
                        height: "100%",
                        backgroundColor: "rgba(173, 216, 230, 0.5)",
                        pointerEvents: "none",
                      }}
                    />
                  )}
                </button>
                <p>No Hardware</p>
              </div>
              <div style={{ height: "90%" }}>
                <button
                  style={{
                    height: "100%",
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    padding: "0",
                    position: "relative",
                  }}
                  onClick={() => handleSelectedHardwareOption("Satin")}
                >
                  <img
                    src={Satin}
                    alt="Satin"
                    style={{ height: "100%", width: "100%" }}
                  />
                  {selectedDoorHardware === "Satin" && (
                    <div
                      style={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        width: "100%",
                        height: "100%",
                        backgroundColor: "rgba(173, 216, 230, 0.5)",
                        pointerEvents: "none",
                      }}
                    />
                  )}
                </button>
                <p>Satin</p>
              </div>
              <div style={{ height: "90%" }}>
                <button
                  style={{
                    height: "100%",
                    width: "100%",
                    padding: "0",
                    position: "relative",
                  }}
                  onClick={() =>
                    handleSelectedHardwareOption("Oil Rubbed Bronze")
                  }
                >
                  <img
                    src={OilRubbedBronze}
                    alt="OilRubbedBronze"
                    style={{ height: "100%" }}
                  />
                  {selectedDoorHardware === "Oil Rubbed Bronze" && (
                    <div
                      style={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        width: "100%",
                        height: "100%",
                        backgroundColor: "rgba(173, 216, 230, 0.5)",
                        pointerEvents: "none",
                      }}
                    />
                  )}
                </button>
                <p>Oil Rubbed Bronze</p>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

DoorHardware.propTypes = {
  index: PropTypes.number,
  selectedDoorHardware: PropTypes.string,
  setSelectedDoorHardware: PropTypes.func,
  setShowDoorHardware: PropTypes.func,
  setFieldsWithRedBorder: PropTypes.func,
  selectedPavilionLayoutOption: PropTypes.string,
  doorWidth: PropTypes.array,
  handleShowDoorHardwareClick: PropTypes.func,
  SSHinges: PropTypes.string,
  handleSSHingesOptionChange: PropTypes.func,
  redBorderFields: PropTypes.array,
  showDoorHardware: PropTypes.bool,
  selectedSunRoomType: PropTypes.string,
  handleSelectedScreenDoorWidthChoice: PropTypes.func,
  selectedBreezeWay: PropTypes.string,
};

export default DoorHardware;
